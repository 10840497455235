import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import Specializations from '../../../list/doctorsPageList/common/Doctor/specializations';

export default function VisitInfo({ user, date, division, doctor, diagnoses }) {
  
  return (
    <div className="personal-visits-list__item-about">
      <div className="personal-visits-list__item-data">
        <i className="fas fa-calendar-alt" /> {moment(date).format('DD.MM.YYYY')}
      </div>
      <NavLink to={`/city/${user.city?.id}/hospitals/${division.institution_id}?division_id=${division.id}`} className="personal-visits-list__item-link">
        {division.full_name}
      </NavLink>
      <div className="personal-visits-list__item-medic">
        <div className="personal-visits-list__item-medic-title">{doctor.full_name}</div>
        <Specializations
          specializations={doctor.specializations}
          className="personal-visits-list__item-medic-description"
        />
      </div>
      <div className="personal-visits-list__item-diagnosis">
        <div className="personal-visits-list__item-diagnosis-title">Дiагноз</div>
        {diagnoses.length ? (
          diagnoses.map(({ code, name }) => (
            <div className="personal-visits-list__item-diagnosis-description" key={code}>
              {name}
            </div>
          ))
        ) : (
          <div className="personal-visits-list__item-diagnosis-description">Немає даних</div>
        )}
      </div>
    </div>
  );
}
