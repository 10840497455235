import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import styled from 'styled-components';

import { hideAlertMessage } from '../../../actions';
import RefreshSvg from '../../../assets/img/refresh.svg';

const AlertMessage = ({ variant = 'danger', hideAlertMessage, error }) => {
  if (!error.showMessage) return null;

  return ReactDOM.createPortal(
    <AlertWrapper>
      <Alert show variant={variant}>
        <div>
          {error.statusCode === 504 && (
            <span onClick={() => window.location.reload()}>
              <img src={RefreshSvg} alt="Перезавантажити сторінку" />
            </span>
          )}
          {error.message}
        </div>
        <button onClick={hideAlertMessage} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </Alert>
    </AlertWrapper>,
    document.body,
  );
};

const mapStateToProps = ({ error }) => ({ error });

const mapDispatchToProps = { hideAlertMessage };

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);

const AlertWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5000;

  img {
    margin-right: 15px;
    width: 22px;
    cursor: pointer;
  }
`;
