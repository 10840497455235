import React from 'react';
import Footer from '../components/global/footer';
import Header from '../components/global/header';
import { useUrlParser } from '../../utils/useUrlParser';
import Schedule from '../../assets/img/vaccination-schedule.png';

const Covid19Info = ({ history }) => {
  const goHome = () => history.push('/');
  const {hideNavigation} = useUrlParser().urlData.params;

  return (
    <div>
      {!hideNavigation && <Header />}
      <main className="about_main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="about_page">
                <h1 className="title">Офіційна інформація щодо вакцинації від Covid-19 в Україні</h1>
                <p>
                  Щеплення від Civid-19 проводиться відповідно до Календаря щеплення, затвердженого постановою Кабінету
                  Міністрів України (далі – Календар).
                  <br />
                  <img src={Schedule} alt="Schedule" style={{ width: '100%' }} />
                </p>

                <p>
                  Ознайомитись з оперативною інформацією щодо стану захворюваності на Covid-19 в Україні ви можете на
                  офіційному інформаційному порталі МОЗ України за адресою&nbsp;
                  <a href="https://covid19.gov.ua/" target="_blank" rel="noreferrer noopener">
                    https://covid19.gov.ua/
                  </a>
                  &nbsp;
                </p>

                <p>
                  Якщо Ви входите в групу населення, щодо яких настав термін добровільної вакцинації, відповідно до
                  Календаря, записатись на вакцинацію Ви можете зручним для Вас способом:
                  <ol>
                    <li>Звернувшись безпосередньо до Вашого сімейного лікаря</li>
                    <li>
                      Зателефонувавши до колл-центру МОЗ України за телефоном -&nbsp;
                      <a href="tel:0800602019">0800 60 20 19</a>
                    </li>
                    <li>
                      Записатись до листа очікування мобільного додатку «ДІЯ» за адресою
                      <a href="https://vaccination.covid19.gov.ua/" target="_blank" rel="noreferrer noopener">
                        &nbsp;https://vaccination.covid19.gov.ua/
                      </a>
                      &nbsp;
                    </li>
                  </ol>
                </p>

                {!hideNavigation && (
                  <div className="link_to_home" onClick={goHome}>
                    На головну
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      {!hideNavigation && <Footer />}
    </div>
  );
};

export default Covid19Info;
