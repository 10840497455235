import React from 'react';
import { Col, Dropdown } from 'react-bootstrap';

import PatientName from './name';
import PatientsSelect from './select';

export default function PatientInfo({ wrapperStyle, dropDownStyle, patient, patients, setPatient, user }) {
  return (
    <Col {...wrapperStyle}>
      <Dropdown className={`user-cabinet-navigation__item${dropDownStyle ? ` ${dropDownStyle}` : ''}`}>
        <PatientName patient={patient} patients={patients} user={user} />
        <PatientsSelect patients={patients} setPatient={setPatient} />
      </Dropdown>
    </Col>
  );
}
