import Button from 'react-bootstrap/Button';
import React from 'react';
import RenderPatient from '../../common/renderPatient';

export default function PatientStep({
  operatorPatientsLoaded,
  operatorPatients,
  register,
  error,
  show,
  updateAppointmentState,
}) {
  return (
    <div style={{ display: show ? 'block' : 'none', border: 0 }}>
      <p className="appointment-auth-text">Оберіть пацієнта:</p>
      <div className={operatorPatientsLoaded ? null : 'confirmation_of_entry__patients-loading'}>
        {operatorPatientsLoaded
          ? operatorPatients.map((patient) => (
              <RenderPatient
                key={patient.id}
                currentPatientId={
                  localStorage.getItem('patientId') ? +localStorage.getItem('patientId') : operatorPatients[0].id
                }
                register={register}
                patient={patient}
              />
            ))
          : 'Завантаження ...'}

        {operatorPatientsLoaded && Boolean(operatorPatients.length) && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label className="radio">
            <input type="radio" name="patient" value="other" ref={register} />
            <div className="radio__text">Інший</div>
          </label>
        )}
      </div>
      {typeof error === 'string' && (
        <div className="error_text" style={{ opacity: error ? 1 : 0, float: 'inherit', border: 0 }}>
          {error}
        </div>
      )}
      <div className="appointment-form-btn-wrapper">
        <Button type="submit" variant="primary" className="submit-button" disabled={!operatorPatientsLoaded}>
          Підтверджую
        </Button>
        <Button type="button" variant="secondary" className="submit-button" onClick={updateAppointmentState}>
          Почати заново
        </Button>
      </div>
    </div>
  );
}
