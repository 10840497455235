import { createAction } from 'redux-actions';

import * as TYPES from '../constants/types';
import * as api from '../api';

export const logout = createAction(TYPES.LOGOUT);
export const hideAlertMessage = createAction(TYPES.HIDE_ALERT_MESSAGE);
export const showAlertMessage = createAction(TYPES.SHOW_ALERT_MESSAGE);
export const updateCity = createAction(TYPES.CHANGE_CITY);
export const clearAuthState = createAction(TYPES.CLEAR_AUTH_STATE);
export const updateDistrict = createAction(TYPES.UPDATE_DISTRICT);

export const sendSmsRequest = createAction(TYPES.SEND_SMS_REQUEST);
export const sendSmsSuccess = createAction(TYPES.SEND_SMS_SUCCESS);
export const sendSmsFailure = createAction(TYPES.SEND_SMS_FAILURE);

export const sendSms = (phone) => (dispatch) => {
  dispatch(sendSmsRequest(phone));

  return api
    .authSms({ phone })
    .then((response) => {
      response.json().then(json => dispatch(sendSmsSuccess(json)));      
      return response;
    })
    .catch((error) => dispatch(sendSmsFailure(error)));
};

export const loginRequest = createAction(TYPES.LOGIN_REQUEST);
export const loginSuccess = createAction(TYPES.LOGIN_SUCCESS);
export const loginFailure = createAction(TYPES.LOGIN_FAILURE);

export const login = ({ phone, code }) => (dispatch) => {
  dispatch(loginRequest());

  return api
    .login({ phone, code })
    .then((response) => {
      dispatch(loginSuccess(response));
      return response;
    })
    .catch((error) => dispatch(loginFailure(error)));
};

export const refreshTokenRequest = createAction(TYPES.REFRESH_TOKEN_REQUEST);
export const refreshTokenSuccess = createAction(TYPES.REFRESH_TOKEN_SUCCESS);
export const refreshTokenFailure = createAction(TYPES.REFRESH_TOKEN_FAILURE);

export const refreshToken = () => (dispatch) => {
  dispatch(refreshTokenRequest());

  return api
    .refreshToken()
    .then((response) => dispatch(refreshTokenSuccess(response)))
    .catch((error) => dispatch(refreshTokenFailure(error)));
};

export const getCitiesRequest = createAction(TYPES.GET_CITIES_REQUEST);
export const getCitiesSuccess = createAction(TYPES.GET_CITIES_SUCCESS);
export const getCitiesFailure = createAction(TYPES.GET_CITIES_FAILURE);

export const getCities = () => (dispatch) => {
  dispatch(getCitiesRequest());

  return api
    .getCities()
    .then((response) => dispatch(getCitiesSuccess(response)))
    .catch((error) => dispatch(getCitiesFailure(error)));
};

export const searchRequest = createAction(TYPES.SEARCH_REQUEST);
export const searchSuccess = createAction(TYPES.SEARCH_SUCCESS);
export const searchFailure = createAction(TYPES.SEARCH_FAILURE);
export const searchFocusedToggle = createAction(TYPES.SEARCH_FOCUSED_TOGGLE);

export const search = ({ cityId, value = '' }) => (dispatch) => {
  dispatch(searchRequest());

  if (value === '')
    return dispatch(
      searchSuccess({
        data: {
          doctors: [],
          divisions: [],
          specializations: [],
        },
        term: value,
      }),
    );

  const query = `?city_id=${cityId}&name=${value}`;

  return Promise.all([api.searchDoctors(query), api.searchDivisions(query), api.getSpecializations(query)])
    .then((response) => {
      dispatch(
        searchSuccess({
          data: {
            doctors: response[0].data,
            divisions: response[1].data,
            specializations: response[2].data,
          },
          term: value,
        }),
      );

      return response;
    })
    .catch((error) => {
      dispatch(searchFailure(error));

      return Promise.reject(error);
    });
};

export const getSettingsRequest = createAction(TYPES.GET_SETTINGS_REQUEST);
export const getSettingsSuccess = createAction(TYPES.GET_SETTINGS_SUCCESS);
export const getSettingsFailure = createAction(TYPES.GET_SETTINGS_FAILURE);

export const getSettings = () => (dispatch) => {
  dispatch(getSettingsRequest());

  api
    .getSettings()
    .then((response) => dispatch(getSettingsSuccess(response)))
    .catch((error) => dispatch(getSettingsFailure(error)));
};

export const syncBotRequest = createAction(TYPES.SYNC_BOT_REQUEST);
export const syncBotSuccess = createAction(TYPES.SYNC_BOT_SUCCESS);
export const syncBotFailure = createAction(TYPES.SYNC_BOT_FAILURE);

export const syncBot = () => (dispatch) => {
  dispatch(syncBotRequest());

  api
    .getSettings()
    .then((response) => dispatch(syncBotSuccess(response)))
    .catch((error) => dispatch(syncBotFailure(error)));
};

export const getInitialCityRequest = createAction(TYPES.GET_INITIAL_CITY_REQUEST);
export const getInitialCitySuccess = createAction(TYPES.GET_INITIAL_CITY_SUCCESS);
export const getInitialCityFailure = createAction(TYPES.GET_INITIAL_CITY_FAILURE);

export const getInitialCity = () => (dispatch) => {
  dispatch(getInitialCityRequest());

  api
    .getInitialCity()
    .then((response) => dispatch(getInitialCitySuccess(response)))
    .catch((error) => dispatch(getInitialCityFailure(error)));
};

export const getDistrictsRequest = createAction(TYPES.GET_DISTRICTS_REQUEST);
export const getDistrictsSuccess = createAction(TYPES.GET_DISTRICTS_SUCCESS);
export const getDistrictsFailure = createAction(TYPES.GET_DISTRICTS_FAILURE);

export const getDistricts = (cityId = 1) => (dispatch) => {
  dispatch(getDistrictsRequest());

  return api
    .getDistricts(`?city_id=${cityId}`)
    .then((response) => dispatch(getDistrictsSuccess(response)))
    .catch((error) => dispatch(getDistrictsFailure(error)));
};
