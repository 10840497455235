const urlParserData = {};

class UrlParser {
  urlData = {};

  constructor() {
    this.parseUrl();
    urlParserData.removeParam = this.removeParam.bind(this);
    urlParserData.addParam = this.addParam.bind(this);
    urlParserData.calcNewPaginationTo = this.calcNewPaginationTo.bind(this);
  }

  parseUrl() {
    const url = new URL(window.location.href);

    const newUrlData = {};

    newUrlData.path = url.pathname;
    newUrlData.hash = url.hash;
    newUrlData.to = `${url.pathname}${url.search}${url.hash}`;
    newUrlData.params = {};

    let newSearch = '';

    url.searchParams.forEach((value, name) => {
      newUrlData.params[name] = value;
      newSearch += `${name}=${value}&`;
    });

    newSearch = newSearch.slice(0, -1);

    newUrlData.search = newSearch.length ? `?${newSearch}` : '';

    if (
      this.urlData.path === newUrlData.path &&
      this.urlData.hash === newUrlData.hash &&
      this.urlData.search === newUrlData.search
    )
      return;

    this.urlData = newUrlData;
    urlParserData.urlData = Object.freeze({ ...newUrlData });
  }

  removeParam(param) {
    this.parseUrl();

    if (typeof param === 'string') {
      delete this.urlData.params[param];
    }

    if (Array.isArray(param)) {
      param.forEach((name) => {
        delete this.urlData.params[name];
      });
    }

    this.createNewUrlData();

    return urlParserData;
  }

  addParam(param, value) {
    this.parseUrl();

    this.urlData.params[param] = value;

    this.createNewUrlData();

    return urlParserData;
  }

  calcNewPaginationTo(paramName, paramValue) {
    let newSearch = '';
    let existsParamName = false;

    Object.keys(this.urlData.params).forEach((name) => {
      if (name === paramName) {
        newSearch += `${name}=${paramValue}&`;
        existsParamName = true;
      } else {
        newSearch += `${name}=${this.urlData.params[name]}&`;
      }
    });

    if (!existsParamName) newSearch += `${paramName}=${paramValue}&`;

    newSearch = newSearch.slice(0, -1);

    const search = newSearch.length ? `?${newSearch}` : '';

    return `${this.urlData.path}${search}${this.urlData.hash}`;
  }

  createNewUrlData() {
    let newSearch = '';

    Object.keys(this.urlData.params).forEach((name) => {
      newSearch += `${name}=${this.urlData.params[name]}&`;
    });

    newSearch = newSearch.slice(0, -1);

    this.urlData.search = newSearch.length ? `?${newSearch}` : '';
    this.urlData.to = `${this.urlData.path}${this.urlData.search}${this.urlData.hash}`;

    urlParserData.urlData = Object.freeze({ ...this.urlData });
  }
}

const parser = new UrlParser();

export const useUrlParser = () => {
  parser.parseUrl();
  return urlParserData;
};
