import React from 'react';
import { NavLink } from 'react-router-dom';

import RatingBar from '../../../List/RatingBar';

export function DoctorItem({ doctor: { id, full_name, short_name, rating }, user }) {
  return (
    <div className="search-field-dropdown-result-content-item" key={id}>
      <NavLink to={`/city/${user.city?.id}/doctors/${id}`} className="search-field-dropdown-result-content-link">
        {full_name || short_name}
      </NavLink>
      <div className="search-field-dropdown-result-content-rating">
        <RatingBar rating={rating} />
      </div>
    </div>
  );
}
