import React from 'react';

export function Title() {
  return (
    <div className="main-title-wrapper">
      <h1 className="title">
        Пошук лікаря та медичного закладу
        <br /> для запису на прийом
      </h1>
      <div className="sub-title">В поле пошуку почніть вводити назву медичного закладу чи прізвище лікаря чи спеціальність лікаря</div>
    </div>
  );
}
