import React from 'react';

export function RemainedMedicines({ remainedMedicinesUrl }) {
  if (!remainedMedicinesUrl) return null;

  return (
    <a
      href={remainedMedicinesUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="institution-about__information-link"
    >
      <i className="fas fa-pills" />
      <span>Залишки медикаментів</span>
    </a>
  );
}
