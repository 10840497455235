import React from 'react';

import { DoctorItem } from './doctorItem';
import { ShowAllDoctorsLink } from './showAll';

export function Doctors({ doctors, term, user }) {
  if (!doctors.length) return null;

  return (
    <div className="search-field-dropdown-result-item">
      <div className="search-field-dropdown-result-content-wrapper">
        <div className="search-field-dropdown-result-item-content-title">Лікарі</div>
        <div className="search-field-dropdown-result-content">
          {doctors.slice(0, 3).map((doctor) => (
            <DoctorItem doctor={doctor} key={doctor.id} user={user} />
          ))}

          {doctors.length > 3 && <ShowAllDoctorsLink term={term} user={user} />}
        </div>
      </div>
    </div>
  );
}
