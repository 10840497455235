import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';

import UserReducer from './User';
import EmployeesReducer from './Employees';

import ScheduleReducer from './Schedule';
import AuthReducer from './Auth';
import AppointmentsReducer from './Appointments';
import AppointmentReducer from './Appointment';
import SearchReducer from './Search';

import PatientsReducer from './PatientsReducers/Patients';
import PatientInfoReducer from './PatientsReducers/Patient';
import RecipesReducer from './PatientsReducers/Recipes';
import PatientVisitsReducer from './PatientsReducers/Visits';

import DivisionsReducer from './Hospitals/divisions';
import HospitalReducer from './Hospitals/hospital';
import DivisionMedicsReducer from './Hospitals/divisionMedics';
import DivisionsMapDataReducer from './Hospitals/divisionsMap';

import DoctorsReducer from './Doctors/doctors';
import DoctorReducer from './Doctors/doctor';
import DoctorInfoReducer from './Doctors/doctorInfo';
import DoctorScheduleReducer from './Doctors/doctorSchedule';
import DoctorsByDaysScheduleReducer from './Doctors/byDaysSchedule';
import DoctorsMapDataReducer from './Doctors/map';
import SpecializationsReducer from './Doctors/specializations';

import ErrorReducer from './Error';

const rootReducer = combineReducers({
  form: reduxFormReducer,
  user: UserReducer,
  employees: EmployeesReducer,
  hospital: HospitalReducer,
  divisionMedics: DivisionMedicsReducer,
  divisions: DivisionsReducer,
  divisionsMap: DivisionsMapDataReducer,
  doctors: DoctorsReducer,
  doctorsMap: DoctorsMapDataReducer,
  doctor: DoctorReducer,
  doctorInfo: DoctorInfoReducer,
  doctorSchedule: DoctorScheduleReducer,
  doctorsByDaysSchedule: DoctorsByDaysScheduleReducer,
  specializations: SpecializationsReducer,
  schedule: ScheduleReducer,
  patients: PatientsReducer,
  appointments: AppointmentsReducer,
  patient: PatientInfoReducer,
  recipes: RecipesReducer,
  patientVisits: PatientVisitsReducer,
  auth: AuthReducer,
  appointment: AppointmentReducer,
  search: SearchReducer,
  error: ErrorReducer,
  routing: routerReducer,
});

export default rootReducer;
