import React from 'react';

import { DivisionItem } from './divisionItem';
import { ShowAllDivisionsLink } from './showAll';

export function Divisions({ divisions, term, user }) {
  if (!divisions.length) return null;

  return (
    <div className="search-field-dropdown-result-item">
      <div className="search-field-dropdown-result-content-wrapper">
        <div className="search-field-dropdown-result-item-content-title">Відділення</div>
        <div className="search-field-dropdown-result-content">
          {divisions.slice(0, 3).map((division) => (
            <DivisionItem division={division} key={division.id} user={user} />
          ))}

          {divisions.length > 3 && <ShowAllDivisionsLink term={term} user={user} />}
        </div>
      </div>
    </div>
  );
}
