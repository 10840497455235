import React from 'react';

import Code from '../auth/code';
import Phone from '../auth/phone';

const Auth = ({ auth: { phone }, timeSlot, referralNumber, setReferralNumber, denyRedirect = false }) => {
  const RenderComponent = phone ? Code : Phone;

  return (
    <RenderComponent
      appointment
      denyRedirect={denyRedirect}
      timeSlot={timeSlot}
      referralNumber={referralNumber}
      setReferralNumber={setReferralNumber}
      notice="* якщо Ви маєте декларацію з сімейним лікарем — це має бути номер, який Ви вказали при її укладанні"
    >
      <p className="appointment-auth-text">Вкажіть Ваш номер телефону*:</p>
    </RenderComponent>
  );
};

export default Auth;
