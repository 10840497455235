import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';

import { declarationSignedFalse } from '../helpers/declarationFields';
import { choosePatient } from '../../../actions/appointmentsActions';
import OperatorForm from './operator';
import RenderPatient from '../common/renderPatient';
import ReferralInput from '../../components/refferal';

const ChoosePatient = ({
  handleSubmit,
  onSubmit,
  patients,
  register,
  error,
  user,
  isOperator,
  operatorPatients,
  operatorPatientsLoaded,
  isOperatorChooseOtherPatient,
  updateAppointmentState,
  watch,
  control,
  timeSlot,
  referralNumber,
  setReferralNumber,
  formErrors,
  trigger,
}) => {
  if (isOperator)
    return (
      <OperatorForm
        watch={watch}
        control={control}
        register={register}
        handleSubmit={handleSubmit}
        error={error}
        onSubmit={onSubmit}
        operatorPatients={operatorPatients}
        operatorPatientsLoaded={operatorPatientsLoaded}
        isOperatorChooseOtherPatient={isOperatorChooseOtherPatient}
        updateAppointmentState={updateAppointmentState}
      />
    );

  if (!user.declarationSigned) {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="confirmation_of_entry confirmed">
        <ReferralInput
          register={register}
          style={{ marginTop: '15px' }}
          timeSlot={timeSlot}
          referralNumber={referralNumber}
          setReferralNumber={setReferralNumber}
          formErrors={formErrors}
          trigger={trigger}
        />

        <div className="info_box declaration_false">
          <div className="user_info_box">
            {error && typeof error === 'string' && <span className="error">{error}</span>}
            {declarationSignedFalse.map(({ text, input, Component }) => (
              <Component key={text} text={text} error={error} input={input} register={register} control={control} />
            ))}
          </div>
        </div>

        <Button type="submit" variant="primary" className="submit-button">
          Підтверджую
        </Button>
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="confirmation_of_entry confirmed">
      <ReferralInput
        register={register}
        style={{ marginTop: '15px' }}
        timeSlot={timeSlot}
        referralNumber={referralNumber}
        setReferralNumber={setReferralNumber}
        formErrors={formErrors}
        trigger={trigger}
      />

      <p className="appointment-auth-text">Виберіть кого ви хочете записати на прийом:</p>
      <div className={patients.loaded ? null : 'confirmation_of_entry__patients-loading'}>
        {patients.loaded
          ? patients.data.map((patient) => (
              <RenderPatient
                key={patient.id}
                currentPatientId={
                  localStorage.getItem('patientId') ? +localStorage.getItem('patientId') : patients.data[0].id
                }
                register={register}
                patient={patient}
              />
            ))
          : 'Завантаження ...'}
      </div>
      {error && (
        <div className="error_text" style={{ opacity: error ? 1 : 0, float: 'inherit', border: 0 }}>
          {error}
        </div>
      )}

      <Button type="submit" variant="primary" className="submit-button" disabled={!patients.loaded}>
        Підтверджую
      </Button>
    </form>
  );
};

const mapDispatchToProps = { choosePatient };

export default connect(null, mapDispatchToProps)(ChoosePatient);
