import { useEffect } from 'react';

import { SCHEDULE_DAYS_COUNT } from '../constants/config';

export default function useDoctorScheduleDateRangeResize({ datesRange, doctorScheduleDateRangeResize }) {
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 600 && datesRange.length !== SCHEDULE_DAYS_COUNT) {
        doctorScheduleDateRangeResize({ dateRangeSize: SCHEDULE_DAYS_COUNT });
        return;
      }

      if (window.innerWidth >= 510 && window.innerWidth < 600 && datesRange.length !== 5) {
        doctorScheduleDateRangeResize({ dateRangeSize: 5 });
        return;
      }

      if (window.innerWidth >= 375 && window.innerWidth < 510 && datesRange.length !== 4) {
        doctorScheduleDateRangeResize({ dateRangeSize: 4 });
        return;
      }

      if (window.innerWidth < 374 && datesRange.length !== 3) {
        doctorScheduleDateRangeResize({ dateRangeSize: 3 });
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [datesRange.length, doctorScheduleDateRangeResize]);
}
