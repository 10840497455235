import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import Job from './job';

export default function RenderJobs({ doctor, jobs, city }) {
  const [key, setKey] = useState(0);

  if (jobs.length > 1)
    return (
      <Tab.Container id="left-tabs-example" activeKey={key}>
        <Tab.Content>
          {jobs.map(({ id, division, institution, position }, index) => (
            <Tab.Pane eventKey={index} key={id} className="medic-feature-profile-information__jobs-wrapper">
              <Job
                doctor={doctor}
                id={id}
                jobs={jobs}
                activeKey={key}
                division={division}
                institution={institution}
                position={position}
                setKey={setKey}
                isPagesAvailable
                city={city}
              />
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    );

  return jobs.map(({ id, division, institution, position }) => (
    <Job
      doctor={doctor}
      key={id}
      id={id}
      division={division}
      institution={institution}
      position={position}
      city={city}
    />
  ));
}
