import React from 'react';
import { Dropdown } from 'react-bootstrap';

export default function DistrictDropdownItem({ onClick, district, name }) {
  const handleClick = () => {
    onClick(district);
  };

  return (
    <Dropdown.Item className="user-cabinet-navigation__item-description" onClick={handleClick}>
      {name}
      <div className="user-cabinet-navigation__item-description-arrow">
        <i className="fas fa-chevron-right" />
      </div>
    </Dropdown.Item>
  );
}
