import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const StyledFilter = styled.div`
  top: 50px;
  left: 0;
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 152px;
  z-index: 2;
  @media (min-width: 768px) {
    left: auto;
    right: 0;
    width: 290px;
  }
  ${({ open }) =>
    open &&
    `
    display: block;
  `}
`;

const StyledButton = styled.button`
  &::before {
    transition: all 0.15s ease-in-out;
    content: '${(props) => (props.showMobileText ? 'Сортувати за:' : '')}';
    @media (min-width: 768px) {
      content: '${(props) => (props.showDesktopText ? 'Сортувати за:' : '')}';
      padding-right: 5px;
      color: ${(props) => (props.changeColor ? '#fff' : '#094fa3')};
    }
  }
`;

export default function Sort({ showMobileText, showDesktopText, orderByList, currentOrderBy, setCurrentOrderBy }) {
  const [open, setOpen] = useState(false);
  const filterRef = useRef();

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <StyledButton
      type="button"
      className="filter"
      onClick={() => setOpen((prevState) => !prevState)}
      showMobileText={showMobileText}
      showDesktopText={showDesktopText}
      style={{ backgroundColor: open ? '#1A7EF7' : '#e3f6ff' }}
      ref={filterRef}
      changeColor={open}
    >
      <i className="fas fa-sort-amount-up" style={{ color: open ? '#ffffff' : '#094fa3' }} />
      <StyledFilter open={open}>
        <div className="filter-wrapper">
          <div className="filter-title">Сортувати за:</div>
          <ul className="filter-list">
            {orderByList.map((orderBy) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={orderBy.key}
                className={`filter-list__item${currentOrderBy?.key === orderBy.key ? ' active' : ''}`}
                onClick={() => setCurrentOrderBy(orderBy)}
              >
                {orderBy.name}
              </li>
            ))}
          </ul>
        </div>
      </StyledFilter>
    </StyledButton>
  );
}
