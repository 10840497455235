import React from 'react';
import { Link } from 'react-router-dom';

export default function MapLink({ mapUrl, showMapUrl, externalMapUrl, text }) {
  if (!text) return null;

  const ExternalComponent = !showMapUrl ? 'span' : 'a';

  if (externalMapUrl) {
    return (
      <ExternalComponent
        href={ExternalComponent === 'a' ? mapUrl : null}
        target={ExternalComponent === 'a' ? '_blank' : null}
        rel={ExternalComponent === 'a' ? 'noopener noreferrer' : null}
      >
        <i className="fas fa-map-marker-alt" />
        {text}
      </ExternalComponent>
    );
  }

  if (!showMapUrl || !mapUrl) return null;

  return (
    <Link to={mapUrl} title="Показати на мапі">
      <i className="fas fa-map-marker-alt" />
    </Link>
  );
}
