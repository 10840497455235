import { useMemo } from 'react';

export function useNavigationApi({
  tabEventKeys,
  eventKey,
  doctorsOrderByList,
  divisionsOrderByList,
  currentDoctorsOrderBy,
  currentDivisionsOrderBy,
  setCurrentDoctorsOrderBy,
  setCurrentDivisionsOrderBy,
}) {
  const orderByList = useMemo(() => {
    if (eventKey === tabEventKeys.DIVISIONS || eventKey === tabEventKeys.DIVISIONS_MAP) {
      return divisionsOrderByList;
    }

    if (eventKey === tabEventKeys.DOCTORS || eventKey === tabEventKeys.DOCTORS_MAP) {
      return doctorsOrderByList;
    }

    return [];
  }, [
    divisionsOrderByList,
    doctorsOrderByList,
    eventKey,
    tabEventKeys.DIVISIONS,
    tabEventKeys.DIVISIONS_MAP,
    tabEventKeys.DOCTORS,
    tabEventKeys.DOCTORS_MAP,
  ]);

  const setCurrentOrderBy = useMemo(() => {
    if (eventKey === tabEventKeys.DIVISIONS || eventKey === tabEventKeys.DIVISIONS_MAP) {
      return setCurrentDivisionsOrderBy;
    }

    if (eventKey === tabEventKeys.DOCTORS || eventKey === tabEventKeys.DOCTORS_MAP) {
      return setCurrentDoctorsOrderBy;
    }

    return null;
  }, [
    eventKey,
    setCurrentDivisionsOrderBy,
    setCurrentDoctorsOrderBy,
    tabEventKeys.DIVISIONS,
    tabEventKeys.DIVISIONS_MAP,
    tabEventKeys.DOCTORS,
    tabEventKeys.DOCTORS_MAP,
  ]);

  const currentOrderBy = useMemo(() => {
    if (eventKey === tabEventKeys.DIVISIONS || eventKey === tabEventKeys.DIVISIONS_MAP) {
      return currentDivisionsOrderBy;
    }

    if (eventKey === tabEventKeys.DOCTORS || eventKey === tabEventKeys.DOCTORS_MAP) {
      return currentDoctorsOrderBy;
    }

    return null;
  }, [
    currentDivisionsOrderBy,
    currentDoctorsOrderBy,
    eventKey,
    tabEventKeys.DIVISIONS,
    tabEventKeys.DIVISIONS_MAP,
    tabEventKeys.DOCTORS,
    tabEventKeys.DOCTORS_MAP,
  ]);

  return {
    orderByList,
    setCurrentOrderBy,
    currentOrderBy,
  };
}
