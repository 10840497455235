import React from 'react';

import TimeSlot from './timeSlot';

export default function AdditionalTimeSlots({ schedule, handleClickTimeSlot, horizontalLinesToShow }) {
  if (schedule.length <= horizontalLinesToShow) return <li className="schedule-time-list__item empty" />;

  return (
    <div className="schedule-time-list__item__more-btn-wrapper">
      <div className="schedule-time-list__item-background" />
      <li className="schedule-time-list__item more">
        <i className="fas fa-chevron-down" />
        <ul>
          {schedule.slice(horizontalLinesToShow).map((timeSlot) => (
            <TimeSlot
              key={`${timeSlot.job_id}-${timeSlot.timestamp}`}
              timeSlot={timeSlot}
              handleClickTimeSlot={handleClickTimeSlot}
            />
          ))}
        </ul>
      </li>
    </div>
  );
}
