import * as TYPES from '../../constants/types';

const initialState = {
  loading: false,
  loaded: false,
  data: [],
  error: null,
  status: 200,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_PATIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case TYPES.GET_PATIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };

    case TYPES.LOGOUT:
      return initialState;

    default:
      return state;
  }
}
