import React from 'react';
import { Modal } from 'react-bootstrap';

import RatingBar from '../../../../../components/global/List/RatingBar';
import { useDivisionListModalApi } from './api';

export default function DivisionListModal({ divisions, currentDivision, setCurrentDivision, isVisible, onClose }) {
  const { setDivision } = useDivisionListModalApi({ onClose, setCurrentDivision });

  return (
    <Modal show={isVisible} onHide={onClose} className="modal-hospital-list" centered>
      <Modal.Header>
        <Modal.Title>{currentDivision?.full_name ?? 'Немає даних'}</Modal.Title>
        <RatingBar rating={currentDivision?.rating} />
        <div className="close" onClick={onClose}>
          <i className="fas fa-chevron-up" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <ul className="hospitals">
          {divisions
            .filter(division => division.id !== currentDivision?.id)
            .map(division => (
              <li className="hospital-item" key={division.id}>
                <div className="hospital-item__name" onClick={setDivision(division)}>
                  {division.full_name}
                </div>
                <RatingBar rating={division.rating} />
              </li>
            ))}
        </ul>
      </Modal.Body>
    </Modal>
  );
}
