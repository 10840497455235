import React from 'react';

export default function BuyRecipes({ buUrl }) {
  if (!buUrl) {
    return null;
  }

  return (
    <a href={buUrl} target="_blank" rel="noopener noreferrer" className="btn btn-primary buy">
      <i className="fas fa-shopping-cart" />
      Купити непогашені рецепти
    </a>
  );
}
