import React from 'react';

import Photo from './photo';
import RatingBar from '../../../../components/global/List/RatingBar';
import Description from './description';
import defaultHospitalSvg from '../../../../../assets/img/next/svg/hospital.svg';
import defaultVaccinationPlaceImage from '../../../../../assets/img/vaccination_place.png';

export default function Address({
  divisionId,
  institutionId,
  full_name,
  institution_full_name,
  address,
  rating,
  photo,
  indicators,
  mapUrl,
  description,
  externalMapUrl,
  city,
  disableOwnLink,
  currentDivision,
  isVaccination,
  isSmallVaccinationCenter,
  myAmbulatoryNote,
  closestAvailableTime,
}) {
  return (
    <div className="institution-about__address">
      <div className="institution-about__address-wrapper">
        <Photo
          photo={photo}
          defaultPhoto={isSmallVaccinationCenter ? defaultVaccinationPlaceImage : defaultHospitalSvg}
          rating={rating}
          indicators={indicators}
          full_name={full_name}
        />
        <RatingBar rating={rating} indicators={indicators} full_name={full_name} />
        <Description
          isVaccination={isVaccination}
          isSmallVaccinationCenter={isSmallVaccinationCenter}
          currentDivision={currentDivision}
          divisionId={divisionId}
          full_name={full_name}
          institution_full_name={institution_full_name}
          address={address}
          mapUrl={mapUrl}
          externalMapUrl={externalMapUrl}
          institutionId={institutionId}
          description={description}
          city={city}
          disableOwnLink={disableOwnLink}
          myAmbulatoryNote={myAmbulatoryNote}
          closestAvailableTime={closestAvailableTime}
        />
      </div>
    </div>
  );
}
