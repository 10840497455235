import { connect } from 'react-redux';

import { DivisionMedicsComponent } from './components';
import { getDivisionMedics } from '../../../../actions/hospitals';

const mapDispatchToProps = { getDivisionMedics };

const mapStateToProps = ({ divisionMedics }) => ({ divisionMedics });

export default connect(mapStateToProps, mapDispatchToProps)(DivisionMedicsComponent);
