import React from 'react';
import { Col, Row } from 'react-bootstrap';

import defaultMedicSvg from '../../../../assets/img/next/svg/medic.svg';

import RatingBar from '../../../components/global/List/RatingBar';
import Photo from '../../../list/doctorsPageList/common/Doctor/photo';
import Appointment from '../../../../containers/Appointment';
import Specializations from '../../../list/doctorsPageList/common/Doctor/specializations';
import DesktopSchedule from '../../../list/doctorsPageList/common/Doctor/schedule/DesktopSchedule';
import MobileSchedule from '../../../list/doctorsPageList/common/Doctor/schedule/MobileSchedule';
import AppointmentsCalendarModal from '../../../list/doctorsPageList/common/Doctor/appointmentsCalendarModal';

import CalendarButton from './calendarButton';
import Description from './description';
import Indicators from './indicators';
import Education from './education';
import useDoctorApi from './api';
import Jobs from './jobs';

export default function Doctor({
  description,
  doctor,
  full_name,
  indicators,
  jobs,
  photo,
  rating,
  doctorSchedule,
  currentDoctorSchedule,
  specializations,
  byDaysSchedule,
  user,
  city,
  setCalendarDate,
}) {
  const {
    timeSlot,
    setTimeSlot,
    isVisibleAppointmentsCalendar,
    setIsVisibleAppointmentsCalendar,
    isDirect,
  } = useDoctorApi({ jobs });

  const currentDoctorScheduleExists = !!currentDoctorSchedule?.schedule?.length;

  return (
    <div className="medic-feature">
      <Appointment doctor={doctor} timeSlot={timeSlot} isOpen={Boolean(timeSlot)} onClose={() => setTimeSlot(null)} />
      <Row>
        <Col xs={{ span: 12 }} xl={{ span: user.city?.settings?.allow_timeslots ? 6 : 12 }}>
          <div className="medic-feature-profile-wrapper">
            <div className="medic-feature-profile">
              <div className="medic-feature-profile-status">
                <Photo photo={photo} defaultPhoto={defaultMedicSvg} />
                <RatingBar rating={rating} indicators={indicators} full_name={full_name} />
              </div>
              <div className="medic-feature-profile-information">
                <div className="medic-feature-profile-information__title">{full_name}</div>
                <Specializations specializations={specializations} />
                <Jobs jobs={jobs} doctor={doctor} city={city} />
                {!!city?.settings?.allow_timeslots && (
                  <>
                    {currentDoctorScheduleExists && (
                      <CalendarButton
                        isMobile
                        showAppointmentsCalendar={() => setIsVisibleAppointmentsCalendar(true)}
                      />
                    )}

                    <MobileSchedule
                      isDoctorPage
                      loaded={doctorSchedule.loaded}
                      onOpenAppointment={setTimeSlot}
                      doctorSchedule={doctorSchedule}
                      currentDoctorSchedule={currentDoctorSchedule}
                      setCalendarDate={setCalendarDate}
                      isDirect={isDirect}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="medic-feature-profile-detail">
              <ul className="medic-feature-profile-detail-list">
                <Indicators indicators={indicators} />
                <Description description={description} />
                <Education show={false} />
              </ul>
            </div>
          </div>
        </Col>
        {!!city?.settings?.allow_timeslots && (
          <Col xs={{ span: 12 }} xl={{ span: 6 }}>
            <div className="medic-feature-schedule-wrapper">
              <div className="schedule-wrapper">
                <div className="schedule-title-wrapper">
                  <div className="schedule-title">Розклад</div>
                  {currentDoctorScheduleExists && (
                    <CalendarButton showAppointmentsCalendar={() => setIsVisibleAppointmentsCalendar(true)} />
                  )}
                </div>

                <DesktopSchedule
                  onOpenAppointment={setTimeSlot}
                  datesRange={doctorSchedule.datesRange}
                  loaded={doctorSchedule.loaded}
                  loading={doctorSchedule.loading}
                  currentDoctorSchedule={currentDoctorSchedule}
                  showScheduleHeader
                  isDirect={isDirect}
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
      <AppointmentsCalendarModal
        doctor={doctor}
        city={city}
        byDaysSchedule={byDaysSchedule}
        show={isVisibleAppointmentsCalendar}
        onClose={() => setIsVisibleAppointmentsCalendar(false)}
      />
    </div>
  );
}
