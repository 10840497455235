import React from 'react';
import { NavLink } from 'react-router-dom';

export function ShowAllDivisionsLink({ term, user }) {
  return (
    <div className="search-field-dropdown-result-content-item">
      <div className="search-field-dropdown-result-content-item-all">
        <div className="search-field-dropdown-result-content-item-all-icon">
          <i className="fas fa-hospital" />
        </div>
        <NavLink
          to={`/city/${user.city?.id}/list?name=${term}#divisions`}
          className="search-field-dropdown-result-content-item-link"
        >
          Показати всі відділення за запитом <i className="fas fa-chevron-right" />
        </NavLink>
      </div>
    </div>
  );
}
