import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/global/header/index';
import Footer from '../components/global/footer/index';

export default function NotFoundPage() {
  return (
    <div>
      <Header />

      <main>
        <div className="not-found-page-wrapper">
          <div className="not-found-page-title">404</div>
          <div className="not-found-page-content">
            Ви потрапили на сторінку, якої не існує, або перейшли по невірній адресі. Спробуйте почати з&nbsp;
            <Link to="/">головної сторінки</Link>.
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
