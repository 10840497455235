import React from 'react';
import { NavLink } from 'react-router-dom';

export function Job({ job: { division, institution, position, id: jobId }, user }) {
  const addressExists = division.address.lat && division.address.lon;
  const Component = addressExists ? 'a' : 'div';

  return (
    <div className="personal-medic-list__item-feature" key={jobId}>
      <div className="personal-medic-list__item-feature__institution">{institution.full_name}</div>
      <NavLink to={`/city/${user.city?.id}/hospitals/${institution.id}?division_id=${division.id}`}>
        <i className="fas fa-hospital" />
        {division.full_name}
      </NavLink>

      {division.address?.display_text && (
        <Component
          href={
            addressExists ? `https://maps.google.com/maps/place/${division.address.lat},${division.address.lon}` : null
          }
          target={addressExists ? '_blank' : null}
          rel={addressExists ? 'noopener noreferrer' : null}
          className={`personal-medic-list__item-feature__address${addressExists ? ' vaccinationLink' : ''}`}
        >
          <i className="fas fa-map-marker-alt" />
          {division.address.display_text}
        </Component>
      )}

      <strong>{position.name}</strong>
    </div>
  );
}
