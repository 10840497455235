import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import OfficeStatusSvg from '../../../assets/img/next/office_status.svg';
import HeaderSearch from '../../components/global/header/headerSearch';
import Footer from '../../components/global/footer';
import PatientInfo from '../common/PatientInfo';

import { fetchPatients, getPatientInfo } from '../../../actions/patientsActions';
import { logout } from '../../../actions';
import { useOfficeApi } from '../api';

const OfficePages = ({ patient, patients, user, getPatientInfo, fetchPatients, logout }) => {
  const { currentPatient, setPatient, storagePatientId, currentPage } = useOfficeApi({
    patient,
    patients,
    fetchPatients,
    getPatientInfo,
    logout,
  });

  if (!storagePatientId || !currentPage) return <Redirect to="/not-found-page" />;

  const { path, linkLabel, Component } = currentPage;

  return (
    <div>
      <HeaderSearch
        city={user?.city}
        breadCrumbs={{
          className: 'office-pages col-xl-8 col-lg-10 offset-xl-2 offset-lg-1',
        }}
      />

      <main>
        <div className="user-cabinet-wrapper">
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
                <Switch>
                  <>
                    <div className="user-cabinet-navigation">
                      <Row>
                        <Col md={{ span: 6, order: 1 }} className="order-2">
                          <NavLink to="/office" className="user-cabinet-navigation__link">
                            <div className="user-cabinet-navigation__item">
                              <div className="user-cabinet-navigation__item-description">
                                <div className="user-cabinet-navigation__item-description-icon">
                                  <img src={currentPage.icon} alt={linkLabel} />
                                </div>
                                {linkLabel}
                              </div>
                              <div className="user-cabinet-navigation__item-status">
                                <img src={OfficeStatusSvg} alt="office" />
                              </div>
                            </div>
                          </NavLink>
                        </Col>

                        <PatientInfo
                          wrapperStyle={{ md: { span: 6, order: 2 }, className: 'order-1' }}
                          dropDownStyle="office-pages"
                          patient={currentPatient}
                          patients={patients}
                          setPatient={setPatient}
                          user={user}
                        />
                      </Row>
                    </div>

                    <Route
                      path={`/office${path}`}
                      render={(routeProps) => <Component user={user} patient={patient} {...routeProps} />}
                    />
                  </>
                </Switch>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ user, patients, patient }) => ({ user, patients, patient });

const mapDispatchToProps = { fetchPatients, getPatientInfo, logout };

export default connect(mapStateToProps, mapDispatchToProps)(OfficePages);
