import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useUrlParser } from '../../../../utils/useUrlParser';

export function useAppointmentsApi({ appointments, patient, getCurrentAppointments, getPastAppointments }) {
  const [currentAppointmentsVisible, setCurrentAppointmentsVisible] = useState(true);
  const [pastAppointmentsVisible, setPastAppointmentsVisible] = useState(false);
  const pastAppointmentsRef = useRef();
  const currentAppointmentsRef = useRef();
  const { urlData, removeParam, addParam } = useUrlParser();
  const history = useHistory();

  const goToPastAppointments = useCallback(
    () => {
      history.push(addParam('past', true).urlData.to);
      setCurrentAppointmentsVisible(false);
      setPastAppointmentsVisible(true);
    },
    [addParam, history],
  );

  useEffect(() => {
    if(currentAppointmentsVisible && urlData.params.past) {
      goToPastAppointments();
    }
  }, [urlData.params.past, currentAppointmentsVisible, goToPastAppointments]);


  useEffect(() => {
    if (
      !appointments.currentLoading &&
      patient.data &&
      currentAppointmentsVisible &&
      (!appointments.currentLoaded || patient.data.id !== appointments.current.patientId)
    ) {
      getCurrentAppointments({ patientId: patient.data.id });
    }
  }, [appointments, currentAppointmentsVisible, getCurrentAppointments, patient.data]);

  useEffect(() => {
    if (
      !appointments.pastLoading &&
      patient.data &&
      pastAppointmentsVisible &&
      (!appointments.pastLoaded || patient.data.id !== appointments.past.patientId)
    ) {
      const page =
        +urlData.params.page > 0 && Number.isInteger(+urlData.params.page) ? +urlData.params.page : 1;
      getPastAppointments({ patientId: patient.data.id, query: `page=${page}` });
    }
  }, [urlData.params.page, appointments, getPastAppointments, pastAppointmentsVisible, patient.data]);

  const setCurrentPage = page => {
    getPastAppointments({ patientId: patient.data.id, query: `page=${page}` });
  };

  const goToCurrentAppointments = () => {
    history.push(removeParam(['past', 'page']).urlData.to);
    setPastAppointmentsVisible(false);
    setCurrentAppointmentsVisible(true);
  };

  return {
    pastAppointmentsRef,
    currentAppointmentsRef,
    currentAppointmentsVisible,
    pastAppointmentsVisible,
    setCurrentPage,
    goToPastAppointments,
    goToCurrentAppointments,
  };
}
