import React from 'react';

import moment from 'moment';
import TextLoader from '../../../components/global/Loader/text';

const patientIndicator = ({ name, text }) => (
  <li className="personal-indicators-list__item" key={name}>
    <div className="personal-indicators-list__item-feature">{name}</div>
    <div className="personal-indicators-list__item-description">{text ?? 'Немає даних'}</div>
  </li>
);

const illnessIndicator = ({ name, degree, stage, hasIllness, date }) => (
  <li className="personal-indicators-list__item" key={name}>
    <div className="personal-indicators-list__item-feature">{name}</div>
    <div className="personal-indicators-list__item-description">
      {hasIllness ? (
        <>
          {degree ? `Ступінь: ${degree};${stage ? ` Стадія: ${stage};` : ''}` : 'Так;'}
          <span className="personal-indicators-list__item-description-date">
            <i className="fas fa-calendar-alt" />
            {moment(date).format('DD.MM.YYYY')}
          </span>
        </>
      ) : (
        'Ні'
      )}
    </div>
  </li>
);

const PersonalIndicators = ({ patient }) => {
  if (!patient.data) return <TextLoader />;

  const { redParam, blood_group_name } = patient.data;

  const {
    allergy_text,
    bronchial_asthma_date,
    clinical_contraindication_text,
    diabetes_date,
    diabetes_degree,
    drug_intolerance_text,
    has_bronchial_asthma,
    has_diabetes,
    has_hypertension,
    hypertension_date,
    hypertension_degree,
    hypertension_stage,
  } = redParam ?? {};

  return (
    <div className="personal-indicators-wrapper">
      <div className="personal-indicators">
        <div className="personal-indicators-title">Мої важливі показники</div>
        <ul className="personal-indicators-list">
          {[
            patientIndicator({ name: 'Група кровi', text: blood_group_name }),
            patientIndicator({ name: 'Алергія', text: allergy_text }),
            patientIndicator({ name: 'Протипоказання', text: clinical_contraindication_text }),
            patientIndicator({ name: 'Непереносимiсть лiкiв', text: drug_intolerance_text }),
          ]}
        </ul>
      </div>
      <div className="personal-indicators">
        <div className="personal-indicators-title">Програма &ldquo;Доступнi Лiки&ldquo;</div>
        <div className="personal-indicators-subtitle">
          Дізнайтесь більш детальну інформацію про програму
          {/* TODO: Add real vaccinationLink */}
          <a href="https://moz.gov.ua/dostupni-liki">Доступні Ліки</a>
          <i className="fas fa-link" />
        </div>
        <ul className="personal-indicators-list">
          {[
            illnessIndicator({
              name: 'Гіпертонія',
              degree: hypertension_degree,
              stage: hypertension_stage,
              date: hypertension_date,
              hasIllness: has_hypertension,
            }),
            illnessIndicator({
              name: 'Дiабет',
              degree: diabetes_degree,
              date: diabetes_date,
              hasIllness: has_diabetes,
            }),
            illnessIndicator({
              name: 'Бронхiальна астма',
              date: bronchial_asthma_date,
              hasIllness: has_bronchial_asthma,
            }),
          ]}
        </ul>
      </div>
    </div>
  );
};

export default PersonalIndicators;
