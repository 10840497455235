import * as TYPES from '../constants/types';

export const serverErrorInterceptor = ({ dispatch }) => (next) => (action) => {
  if (action.type === TYPES.LOGIN_FAILURE) {
    return next(action);
  }

  if (action.payload && (action.payload.status >= 400 || action.payload.error?.status >= 400)) {
    const error = typeof action.payload.error === 'object' ? action.payload.error : action.payload;

    dispatch({
      type: TYPES.SHOW_ALERT_MESSAGE,
      payload: { message: error.error, statusCode: error.status },
    });
  }

  return next(action);
};
