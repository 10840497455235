import React from 'react';
import ReactModal from 'react-modal';

import { isMobile } from '../../../utils/mobile';

const customStyles = (overrides = {}) => {
  const mobileOverrides = isMobile()
    ? {
        content: {
          position: 'fixed',
          transform: 0,
          padding: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        },
      }
    : {};
  return {
    content: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0,
      backgroundColor: 'transparent',
    },
    overlay: {
      zIndex: 1222,
      backgroundColor: 'rgba(75, 76, 89, 0.5)',
    },
    ...mobileOverrides,
    ...overrides,
  };
};

export const Modal = ({ children, styles, ...props }) => {
  const style = customStyles(styles);
  return (
    <ReactModal style={style} ariaHideApp={false} {...props}>
      {children}
    </ReactModal>
  );
};
