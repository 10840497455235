import React from 'react';

import TimeSlot from '../DesktopSchedule/timeSlot';

export function ScheduleBody({ transformedSchedule, onOpenAppointment }) {
  if (!transformedSchedule)
    return (
      <div className="schedule-body__placeholder">
        <div className="schedule-body-no-result">
          <div className="schedule-body-no-result-content">
            <i className="fas fa-calendar-times" />
            На обрану дату прийому немає або все зайнято
          </div>
        </div>
      </div>
    );

  return (
    <ul className="schedule-list">
      <li className="schedule-list__item">
        <ul className="schedule-time-list">
          {transformedSchedule.map((timeSlot) => (
            <TimeSlot
              key={`${timeSlot.job_id}=${timeSlot.datetime}`}
              timeSlot={timeSlot}
              handleClickTimeSlot={onOpenAppointment}
            />
          ))}
        </ul>
      </li>
    </ul>
  );
}
