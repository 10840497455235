import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export function useSetCity({ user, updateCity }) {
  const { cityId } = useParams();

  const city = useMemo(() => {
    const currentCity = user.cities.find((city) => city.id === +cityId);

    return currentCity ? { ...currentCity, position: { lat: +currentCity.lat, lng: +currentCity.lon } } : null;
  }, [cityId, user.cities]);

  useEffect(() => {
    if (city && (!user.city || city.id !== user.city.id)) updateCity(city);
  }, [city, updateCity, user.city]);

  return { city };
}
