import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import BotsImage from '../../assets/img/next/bot-images/bot.png';

import * as api from '../../api';

export default function BotPanel({ showBotPanel, syncBot }) {
  const [isVisibleBotPanel, setVisibleBotPanel] = useState(true);

  const handleClose = (e) => {
    e.preventDefault();
    setVisibleBotPanel(false);

    api.botClosed('panel').then(() => {
      syncBot();
    });
  };

  if (!showBotPanel || !isVisibleBotPanel) return null;

  return (
    <Container className="panelBot">
      <Row className="alert alert-dismissible fade show">
        <Col xs="6" md="5" className="alert-heading">
          <span>Відстежуйте скасування або зміну часу і місця прийому в Viber чи Telegram</span>
        </Col>

        <Col xs="6" md="4" className="alert-img order-md-2">
          <img src={BotsImage} alt="" />
        </Col>

        <Col xs="12" md="3" className="alert-btn order-md-1">
          <button className="btn btn-primary" type="button">
            <Link to="/bot">Дізнатися як</Link>
          </button>
        </Col>

        <button className="close" type="button" data-dismiss="alert" aria-label="Close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Row>
    </Container>
  );
}
