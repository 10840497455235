import React from 'react';
import ReactDOM from 'react-dom';
import { usePromiseTracker } from 'react-promise-tracker';

import LoaderGif from '../../../../assets/img/next/gif/loader.gif';

export default function GlobalLoader() {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress &&
    ReactDOM.createPortal(
      <div className="global-loader-wrapper">
        <img src={LoaderGif} alt="loader" />
      </div>,
      document.body,
    )
  );
}
