import React from 'react';
import { Col, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import HeaderSearch from '../components/global/header/headerSearch';
import Footer from '../components/global/footer/index';
import PersonalCodeModal from './common/PersonalCodeModal';
import PersonalCodeCard from './common/PersonalCodeCard';
import HeadRow from './common/HeadRow';
import BotPanel from '../botPage/panel';

import { routes } from './routes';
import { logout, syncBot } from '../../actions';
import { fetchPatients, getPatientInfo } from '../../actions/patientsActions';
import { useOfficeApi } from './api';

const Office = ({ fetchPatients, getPatientInfo, patients, patient, user, syncBot, logout }) => {
  const { currentPatient, setPatient, personalCodeModalIsVisible, setPersonalCodeModalIsVisible } = useOfficeApi({
    patient,
    patients,
    fetchPatients,
    getPatientInfo,
    logout,
  });

  const handleClick = (e) => {
    if (!patient.data) e.preventDefault();
  };

  return (
    <div>
      <HeaderSearch city={user?.city} />

      <main>
        <div className="user-cabinet-navigation user-cabinet-navigation--home">
          {!user.declarationSigned && (
            <Container>
              <div className="user-cabinet-error-message">
                <div className="user-cabinet-error-message-icon">
                  <i className="fas fa-info-circle" />
                </div>
                <div className="user-cabinet-error-message-description">
                  Нажаль, Ваша декларація укладена з сімейним лікарем, який не користується нашою системою або Ви не маєте активної декларації.
                </div>
              </div>
            </Container>
          )}

          <HeadRow
            patient={currentPatient}
            patients={patients}
            setPatient={setPatient}
            user={user}
            headerLabel="Медичний кабінет пацієнта"
          />

          <Container className="user-cabinet-wrapper">
            <PersonalCodeCard patient={currentPatient} setPersonalCodeModalIsVisible={setPersonalCodeModalIsVisible} />

            {routes.reduce((acc, { path, linkLabel, icon, isMenuItem }) => {
              const menuItem = (
                <Col md={4} key={path}>
                  <NavLink
                    className={`user-cabinet-navigation__item ${!currentPatient ? 'disabled' : ''}`}
                    onClick={handleClick}
                    to={`/office${path}`}
                  >
                    <div className="user-cabinet-navigation__item-description">
                      <div className="user-cabinet-navigation__item-description-icon">
                        <img src={icon} alt={linkLabel} />
                      </div>
                      {linkLabel}
                    </div>
                    <div className="user-cabinet-navigation__item-status" />
                  </NavLink>
                </Col>
              );

              return isMenuItem ? [...acc, menuItem] : [...acc];
            }, [])}

            <BotPanel syncBot={syncBot} showBotPanel={user.showBotPanel} />
          </Container>
        </div>
      </main>

      <Footer />

      <PersonalCodeModal
        isVisible={personalCodeModalIsVisible}
        handleClose={() => setPersonalCodeModalIsVisible(false)}
      />
    </div>
  );
};

const mapStateToProps = ({ user, patients, patient }) => ({ user, patients, patient });

const mapDispatchToProps = { logout, fetchPatients, getPatientInfo, syncBot };

export default connect(mapStateToProps, mapDispatchToProps)(Office);
