import React from 'react';
import IntroSection from '../introSection';
import NewsSection from '../news';
import CityChange from '../CityChange';
import { isMobile } from '../../../../utils/mobile';

export default function Layout({ user, searchFocused }) {
  return (
    <main>
      {!localStorage.getItem('cityId') && <CityChange />}
      <IntroSection user={user} searchFocused={searchFocused} />
      {!isMobile() && (<NewsSection searchFocused={searchFocused} />)}
    </main>
  );
}
