import Button from 'react-bootstrap/Button';
import React from 'react';
import RenderField from '../../common/renderField';

export default function PatientNameStep({ error, register, show, updateAppointmentState }) {
  return (
    <div style={{ display: show ? 'block' : 'none', border: 0 }}>
      <div className="info_box declaration_false">
        <div className="user_info_box">
          {error && typeof error === 'string' && <span className="error">{error}</span>}
          <RenderField
            text="ПІБ Пацієнта"
            error={error}
            input={{
              type: 'text',
              maxLength: 255,
              placeholder: 'ПІБ',
              name: 'name',
            }}
            register={register}
          />
        </div>
      </div>

      <div className="appointment-form-btn-wrapper">
        <Button type="submit" variant="primary" className="submit-button">
          Підтверджую
        </Button>
        <Button type="button" variant="secondary" className="submit-button" onClick={updateAppointmentState}>
          Почати заново
        </Button>
      </div>
    </div>
  );
}
