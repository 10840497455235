import React from 'react';
import Footer from '../components/global/footer';
import Header from '../components/global/header';

const Link = ({ href, children }) => (
  <a href={href || '#'} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const AboutPage = ({ history }) => {
  const goHome = () => history.push('/');

  return (
    <div>
      <Header />
      <main className="about_main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="about_page">
                <h1 className="title">Про проект</h1>
                <p>
                  Сервіс <b>“Лікарі 24/7”</b> допомагає жителям України самостійно аналізувати та обирати лікарні та
                  лікарів-спеціалістів за об’єктивними показниками.
                </p>
                <p>Пацієнти бачать об’єктивні показники роботи кожної лікарні та окремого лікаря-спеціаліста.</p>
                <p>
                  Показники розраховуються на основі даних лікувальної роботи конкретних лікарів та лікарень відповідно
                  до міжнародної класифікації&nbsp;
                  <Link href="https://en.wikipedia.org/wiki/Diagnosis-related_group">DRG</Link> та аналізу витрат&nbsp;
                  <Link href="https://www.kmu.gov.ua/npas/pro-zatverdzhennya-metodiki-rozrahunku-vartosti-poslugi-z-medichnogo-obslugovuvannya">
                    Cost Acсounting.
                  </Link>
                </p>
                <p>
                  Сервіс розпочав свою роботу з Херсонської області. Наразі до сервісу поступово підключаються інші
                  регіони України.
                </p>
                <p>
                  Проект створений групою компаній&nbsp;
                  <Link href="https://advanter.ua/">“Advanter Health”</Link>
                  ,&nbsp;
                  <Link href="https://ciet-holding.com">“CIET Holding”</Link>
                </p>
                <div className="link_to_home" onClick={goHome}>
                  На головну
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AboutPage;
