import React from 'react';
import { Container } from 'react-bootstrap';

import HeaderLogo from './logo';
import HeaderButton from './button';
import SearchForm from '../searchForm';
import BreadCrumbs from './breadCrumbs';

export default function HeaderSearch({ city, breadCrumbs }) {
  return (
    <header className={`header header--search${breadCrumbs ? ' breadCrumbs' : ''}`}>
      <Container>
        <HeaderLogo city={city} />

        <SearchForm />

        <HeaderButton />
      </Container>

      <BreadCrumbs breadCrumbs={breadCrumbs} />
    </header>
  );
}
