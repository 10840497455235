import { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useListApi({ tabEventKeys, city }) {
  const defaultDoctorsOrderBy = useMemo(
    () =>
      city?.settings?.doctors?.order_by ? city.settings.doctors.order_by.find((orderBy) => orderBy.default) : null,
    [city],
  );
  const defaultDivisionsOrderBy = useMemo(
    () =>
      city?.settings?.divisions?.order_by ? city.settings.divisions.order_by.find((orderBy) => orderBy.default) : null,
    [city],
  );

  const location = useLocation();
  const currentHash = useMemo(() => location.hash.slice(1), [location.hash]);
  const [eventKey, setEventKey] = useState(location.hash.slice(1));
  const [currentDoctorsOrderBy, setCurrentDoctorsOrderBy] = useState(defaultDoctorsOrderBy);
  const [currentDivisionsOrderBy, setCurrentDivisionsOrderBy] = useState(defaultDivisionsOrderBy);

  useEffect(() => {
    if ([tabEventKeys.DIVISIONS_MAP, tabEventKeys.DOCTORS_MAP].includes(currentHash) && !city?.settings?.show_map) {
      setEventKey('denyEvent');
    }
  }, [city, currentHash, eventKey, tabEventKeys.DIVISIONS_MAP, tabEventKeys.DOCTORS_MAP]);

  const onTabSelect = (key) => {
    if (eventKey === tabEventKeys.DIVISIONS_MAP && key === eventKey) {
      setEventKey(tabEventKeys.DIVISIONS);
      return;
    }

    if (eventKey === tabEventKeys.DOCTORS_MAP && key === eventKey) {
      setEventKey(tabEventKeys.DOCTORS);
      return;
    }

    if (eventKey !== key) setEventKey(key);
  };

  useEffect(() => {
    if (window.location.hash !== `#${eventKey}`) {
      window.history.replaceState(null, null, eventKey === 'denyEvent' ? null : `#${eventKey}`);
    }
  }, [eventKey, location]);

  return {
    onTabSelect,
    setEventKey,
    eventKey,
    currentDoctorsOrderBy,
    currentDivisionsOrderBy,
    setCurrentDoctorsOrderBy,
    setCurrentDivisionsOrderBy,
    city,
  };
}
