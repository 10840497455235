import { connect } from 'react-redux';

import CovidComponent from '../next/covid';
import { getDivisions } from '../actions/hospitals';

const mapStateToProps = ({ divisions }) => ({ divisions });

const mapDispatchToProps = { getDivisions };

export default connect(mapStateToProps, mapDispatchToProps)(CovidComponent);
