import React, { useState } from 'react';

import RatingBar from '../../../../components/global/List/RatingBar';
import DivisionListModal from './DivisionListModal';

export default function HospitalDivisionHeader({ divisions, currentDivision, setCurrentDivision }) {
  const [hospitalListVisible, setHospitalListVisible] = useState(false);

  return (
    <div className="institution-info__header">
      <div className="institution-info__header-title" onClick={() => setHospitalListVisible(true)}>
        {currentDivision?.full_name ?? 'Немає даних'}
      </div>

      <RatingBar
        rating={currentDivision?.rating}
        indicators={currentDivision?.indicators}
        full_name={currentDivision?.full_name}
        showRatingPopup={false}
      />

      <div className="institution-info__header-icon" onClick={() => setHospitalListVisible(true)}>
        <i className="fas fa-chevron-down" />
      </div>

      <DivisionListModal
        isVisible={hospitalListVisible}
        divisions={divisions}
        currentDivision={currentDivision}
        setCurrentDivision={setCurrentDivision}
        onClose={() => setHospitalListVisible(false)}
      />
    </div>
  );
}
