import { connect } from 'react-redux';

import BotComponent from '../next/botPage';
import { clearAuthState } from '../actions';

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = {
  clearAuthState,
};

export default connect(mapStateToProps, mapDispatchToProps)(BotComponent);
