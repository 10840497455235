import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { newsItem } from '../helpers';

export default function NewsSection({ searchFocused }) {
  return (
    <Container>
      <Row>
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <div className={`news-section${searchFocused ? ' search-focused' : ''}`}>
            <h2 className="title">Цікаве про медицину</h2>
            <ul className="news-list">
              {newsItem.map(({ icon: Icon, description, link, linkDescription }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li className="news-list__item" key={index}>
                  <div className="news-list__icon">
                    <Icon />
                  </div>
                  <div className="news-list__about">
                    <div className="news-list__description">{description}</div>
                    <a href={link} className="news-list__link">
                      <i className="fas fa-link" />
                      {linkDescription}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
