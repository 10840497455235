import React, { useState, useImperativeHandle } from 'react';

export function TheEndSchedulePopup({ datesRange, doctorSchedule, popupOutsideRef }) {
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);

  useImperativeHandle(popupOutsideRef, () => ({
    clickNext() {
      if (datesRange[datesRange.length - 1] !== doctorSchedule.initialDateTo || isVisiblePopup) return;

      setIsVisiblePopup(true);
    },
  }));

  if (!isVisiblePopup) return null;

  return (
    <div className="schedule-date-list-last">
      <div className="close">
        <i className="fas fa-times" onClick={() => setIsVisiblePopup(false)} />
      </div>
      <i className="fas fa-info-circle" />
      Прийом доступний на найближчі 2 тижні
    </div>
  );
}
