import moment from 'moment';

export const range = (startDate, endDate, format = true) => {
  const dateArr = [];

  const start = moment(startDate);
  const end = moment(endDate);

  while (start < end) {
    dateArr.push(format ? start.format('YYYY-MM-DD') : moment(start.format('YYYY-MM-DD')));
    start.add('1', 'd');
  }

  return dateArr;
};
