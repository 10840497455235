import { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useUrlParser } from '../../../../utils/useUrlParser';

export function useHospitalPageApi({ getHospital, hospital, currentPatient }) {
  const urlParser = useUrlParser();
  const history = useHistory();
  const match = useRouteMatch();
  const [currentDivision, setCurrentDivision] = useState();

  const hospitalId = urlParser.urlData.params.hospital_id;
  const divisionId = urlParser.urlData.params.division_id;

  useEffect(() => {
    if (
      (!urlParser.urlData.params.division_id || !urlParser.urlData.params.division_id) &&
      currentPatient?.registration
    ) {
      history.push({
        pathname: match.path,
        search: `?hospital_id=${currentPatient.registration.institution_id}&division_id=${currentPatient?.registration?.division_id}&family_ambulatory=true`,
      });
    }
  }, [currentPatient, history, match.path, urlParser.urlData.params.division_id]);

  useEffect(() => {
    if (hospitalId) getHospital(hospitalId);
  }, [getHospital, hospitalId, urlParser.urlData.params.hospital_id]);

  useEffect(() => {
    if (divisionId && hospital.data?.divisions?.length) {
      setCurrentDivision(hospital.data.divisions.find((division) => division.id === +divisionId));
    }
  }, [divisionId, hospital.data]);

  const divisionNotExists = useMemo(
    () =>
      hospital.data?.divisions?.length &&
      divisionId &&
      !hospital.data.divisions.find((division) => division.id === +divisionId),
    [divisionId, hospital.data],
  );

  return {
    currentDivision,
    divisionNotExists,
  };
}
