import { useEffect, useRef, useCallback } from 'react';

import { useUrlParser } from '../../../../../utils/useUrlParser';

export function useDivisionMedicsApi({ divisionId, divisionMedics, getDivisionMedics, city }) {
  const colRef = useRef();
  const urlParser = useUrlParser();

  useEffect(() => {
    if (city?.id && divisionId && divisionId !== divisionMedics.divisionId && !divisionMedics.loading) {
      const page =
        +urlParser.urlData.params.page > 0 && Number.isInteger(+urlParser.urlData.params.page) ? +urlParser.urlData.params.page : 1;
      getDivisionMedics({ cityId: city.id, divisionId, pageSize: 10, page });
    }
  }, [getDivisionMedics, divisionId, divisionMedics, city, urlParser.urlData.params.page]);

  useEffect(() => {
    if (divisionMedics.currentPage > 1 && divisionMedics.loaded) {
      const { offsetTop } = colRef.current;

      window.scrollTo(0, offsetTop);
    }
  }, [divisionMedics]);

  const setCurrentPage = useCallback(
    (page) => {
      getDivisionMedics({ cityId: city?.id, divisionId, pageSize: 10, page });
    },
    [getDivisionMedics, city, divisionId],
  );

  return {
    setCurrentPage,
    colRef,
  };
}
