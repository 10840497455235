import React, { Fragment } from 'react';
import { Col } from 'react-bootstrap';
import moment from 'moment';

import { useDivisionPageListApi } from './api';
import Division from '../../../list/divisionPageList/common/Division';
import Pagination from '../../../components/global/List/Pagination';
import DistrictDropdown from './districtDropdown';
import { months, relativeDateString } from '../../../../constants';

const formattedCurrentDate = (date) => {
  if (!date) return 'Онлайн запис неможливий';

  const momentDate = moment(date);
  const day = momentDate.format('DD');
  const month = months[momentDate.format('MMMM')].date_ru;

  return `${day} ${month}`;
};

export default function DivisionPageList({
  districts,
  updateDistrict,
  divisions,
  getDivisions,
  city,
  district,
  allDistrictSelected,
  setAllDistrictSelected,
  disableOwnLink,
}) {
  const { divisionsData } = useDivisionPageListApi({
    district,
    divisions,
    getDivisions,
    city,
  });

  if (divisions.loaded && !divisions.data.length)
    return (
      <DistrictDropdown
        isEmptyDivisions
        setAllDistrictSelected={setAllDistrictSelected}
        allDistrictSelected={allDistrictSelected}
        districts={districts}
        district={district}
        updateDistrict={updateDistrict}
      />
    );

  return (
    <Col md={12} lg={6}>
      {Object.keys(divisionsData)
        .sort((date1, date2) => {
          // move divisions with closest_available_time=null to the end of the list
          const dateA = date1 !== 'null' ? new Date(date1) : new Date(Date.now() * 10);
          const dateB = date2 !== 'null' ? new Date(date2) : new Date(Date.now() * 10);

          return dateA - dateB;
        })
        .map((date) => {
          const currentDate = !Number.isNaN(Date.parse(date)) ? date : null;
          const relativeCurrentDateString = currentDate
            ? `Прийом ${relativeDateString(new Date(), new Date(currentDate))}, `
            : null;

          return (
            <Fragment key={date}>
              <div className="institution-about__date">
                {relativeCurrentDateString}
                {formattedCurrentDate(currentDate)}
              </div>
              {divisionsData[date].map((division) => (
                <Division
                  key={division.id}
                  isVaccination
                  isSmallVaccinationCenter={division.is_small_vaccination_center}
                  remainedMedicinesUrl={division.institution.remained_medicines_url}
                  divisionId={division.id}
                  full_name={division.full_name}
                  institutionId={division.institution.id}
                  institution_full_name={division.institution.full_name}
                  address={division.address}
                  phones={division.contacts.filter((contact) => contact.type === 'phone')}
                  rating={division.rating}
                  photo={division.photo}
                  indicators={division.indicators}
                  city={city}
                  mapUrl={
                    division.address.lat && division.address.lon
                      ? `https://maps.google.com/maps/place/${division.address.lat},${division.address.lon}`
                      : null
                  }
                  disableOwnLink={disableOwnLink}
                  showAboutInformation={false}
                  showRatingIndicator
                  externalMapUrl
                  closestAvailableTime={division.closest_available_time}
                />
              ))}
            </Fragment>
          );
        })}
      <Pagination
        currentPage={divisions.currentPage}
        pageName="divisions_page"
        totalPages={divisions.pagination.total_pages}
      />
    </Col>
  );
}
