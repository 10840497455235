import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export default function IndicatorList({ indicators }) {
  return (
    <div className="institution-info__indicator-item">
      <div className="institution-info__indicator-title">Показники</div>
      <ul className="institution-info__indicator-list">
        {indicators.length ? (
          indicators.map(({ key, name, description, value, units }) => (
            <li className="institution-info__indicator-list-item" key={key}>
              <div className="institution-info__indicator-list-description">{name}</div>
              <div className="institution-info__indicator-list-count">
                <strong>{value}</strong> {units}
                <OverlayTrigger overlay={<Tooltip>{description}</Tooltip>}>
                  <span>
                    <i className="far fa-question-circle" />
                  </span>
                </OverlayTrigger>
              </div>
            </li>
          ))
        ) : (
          <li className="institution-info__indicator-list-item">
            <div className="institution-info__indicator-list-description empty">Немає даних</div>
          </li>
        )}
      </ul>
    </div>
  );
}
