import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { tabEventKeys } from '../../../list';
import { getDoctorsMapData } from '../../../../actions/doctors';

import { getDivisionMapData, getDivisions, getDivisionsMapData } from '../../../../actions/hospitals';
import { fetchPatients } from '../../../../actions/patientsActions';
import { updateCity, updateDistrict } from '../../../../actions';

import MapPageComponent from '../../../mapPage';
import HeadRow from './headRow';
import DivisionPageList from './divisions';
import DistrictDropdown from './districtDropdown';
import BreadCrumbs from '../../components/breadCrumbs';
import { useUrlParser } from '../../../../utils/useUrlParser';

const VaccinationSearch = ({
  user,
  updateCity,
  divisionsMap,
  doctorsMap,
  getDivisionMapData,
  getDivisionsMapData,
  getDivisions,
  divisions,
  getDoctorsMapData,
  updateDistrict,
  history,
  match,
}) => {
  const urlParser = useUrlParser();
  const [allDistrictSelected, setAllDistrictSelected] = useState(
    urlParser.urlData.params.view || Boolean(localStorage.getItem('allDistricts')),
  );

  const handleSelectAllDistricts = (selectAllDistricts = false) => {
    setAllDistrictSelected(selectAllDistricts);

    if (selectAllDistricts) {
      localStorage.setItem('allDistricts', true);
    } else {
      localStorage.removeItem('allDistricts');
    }
  };

  const changeDistrict = (district) => {
    if (district) {
      setAllDistrictSelected(false);
      localStorage.removeItem('allDistricts');
    }

    history.push(`${match.path}${urlParser.urlData.params.view ? '?view=true' : ''}`);

    updateDistrict(district);
  };

  const isViewMode = urlParser.urlData.params.view;

  return (
    <>
      <BreadCrumbs
        breadCrumbs={{ className: 'family-clinic-vaccinations' }}
        redirectLink={!isViewMode ? '/vaccinations' : null}
      />
      <div className="user-cabinet-navigation user-cabinet-navigation--home user-cabinet-navigation--vaccination-search">
        <Container>
          <HeadRow
            districts={user.districts}
            district={user.district}
            setAllDistrictSelected={handleSelectAllDistricts}
            updateDistrict={changeDistrict}
            allDistrictSelected={allDistrictSelected}
          />
          <Row>
            {(user.district || allDistrictSelected) && (
              <DivisionPageList
                disableOwnLink={isViewMode}
                setAllDistrictSelected={handleSelectAllDistricts}
                allDistrictSelected={allDistrictSelected}
                district={user?.district}
                districts={user.districts}
                divisions={divisions}
                getDivisions={getDivisions}
                city={user?.city}
                updateDistrict={changeDistrict}
              />
            )}

            {!allDistrictSelected && !divisions.data.length && !user.district && (
              <DistrictDropdown
                setAllDistrictSelected={handleSelectAllDistricts}
                allDistrictSelected={allDistrictSelected}
                districts={user.districts}
                district={user.district}
                updateDistrict={updateDistrict}
              />
            )}
            <Col md={12} lg={6}>
              <div className="maps-tab-content">
                <MapPageComponent
                  isVaccinationPlace
                  districtsLoaded={user.districtsLoaded}
                  getDivisionsMapData={getDivisionsMapData}
                  getDivisionMapData={getDivisionMapData}
                  divisionsMap={divisionsMap}
                  getDoctorsMapData={getDoctorsMapData}
                  doctorsMap={doctorsMap}
                  tabEventKeys={tabEventKeys}
                  tabEventKey="divisions_map"
                  user={user}
                  updateCity={updateCity}
                  disableOwnLink={isViewMode}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = ({ divisions, divisionsMap, user }) => ({ divisions, divisionsMap, user });

const mapDispatchToProps = {
  getDivisions,
  getDivisionsMapData,
  getDivisionMapData,
  getDoctorsMapData,
  updateDistrict,
  getPatients: fetchPatients,
  updateCity,
};

export default connect(mapStateToProps, mapDispatchToProps)(VaccinationSearch);
