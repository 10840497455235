import React from 'react';

export default function RecipesList({ drugs }) {
  return (
    <ul className="personal-recipes-list">
      {drugs.map(({ id, name, form, signa, released, recipe }) => (
        <li className="personal-recipes-list-item" key={id}>
          <div className="personal-recipes-list-item-drug">
            <div className="personal-recipes-list-item-about">
              <div className="personal-recipes-list-item-about-title">{name}</div>
              <div className="personal-recipes-list-item-about-description">{form}</div>
            </div>
            <div className="personal-recipes-list-item-use-wrapper">
              <div className="personal-recipes-list-item-use">
                <div className="personal-recipes-list-item-use-title">Як приймати</div>
                <div className="personal-recipes-list-item-use-description">{signa}</div>
              </div>
              <div className="personal-recipes-list-item-use">
                <div className="personal-recipes-list-item-use-title">Ознака</div>
                <div className="personal-recipes-list-item-use-description">
                  <a href="/">{recipe?.type_name}</a>
                </div>
              </div>
            </div>
          </div>
          <div className={`personal-recipes-list-item-button${released ? '' : ' disabled'}`}>
            {released && (
              <>
                <div className="personal-recipes-list-item-button-icon">
                  <i className="fas fa-check" />
                </div>
                <div className="personal-recipes-list-item-button-description">Ліки отримано</div>
              </>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
}
