import { createAction } from 'redux-actions';
import * as TYPES from '../constants/types';
import * as api from '../api';

export const setCalendarDate = createAction(TYPES.SET_SCHEDULE_DATE);
export const doctorScheduleDateRangeResize = createAction(TYPES.DOCTOR_SCHEDULE_DATES_RANGE_RESIZE);
export const moveDoctorScheduleDateRangeLeft = createAction(TYPES.MOVE_DOCTOR_SCHEDULE_DATES_RANGE_LEFT);
export const moveDoctorScheduleDateRangeRight = createAction(TYPES.MOVE_DOCTOR_SCHEDULE_DATES_RANGE_RIGHT);

const getDoctorsRequest = createAction(TYPES.GET_DOCTORS_REQUEST);
const getDoctorsSuccess = createAction(TYPES.GET_DOCTORS_SUCCESS);
const getDoctorsFailure = createAction(TYPES.GET_DOCTORS_FAILURE);

export const getDoctors = ({
  cityId,
  name,
  page = 1,
  specializationId,
  orderBy,
  divisionId,
  districtId,
  isVaccinates,
}) => (dispatch) => {
  dispatch(getDoctorsRequest());

  return api
    .getDoctors({ cityId, name, page, specializationId, orderBy, isVaccinates, districtId, divisionId })
    .then((response) => dispatch(getDoctorsSuccess({ response, page, orderBy })))
    .catch((error) => dispatch(getDoctorsFailure({ error, page, orderBy })));
};

const getDoctorsMapDataRequest = createAction(TYPES.GET_DOCTORS_MAP_DATA_REQUEST);
const getDoctorsMapDataSuccess = createAction(TYPES.GET_DOCTORS_MAP_DATA_SUCCESS);
const getDoctorsMapDataFailure = createAction(TYPES.GET_DOCTORS_MAP_DATA_FAILURE);

export const getDoctorsMapData = ({ cityId, name, specializationId, districtId }) => (dispatch) => {
  dispatch(getDoctorsMapDataRequest());

  return api
    .getDoctors({ cityId, name, specializationId, districtId, pageSize: 10000 })
    .then((response) => dispatch(getDoctorsMapDataSuccess({ response })))
    .catch((error) => dispatch(getDoctorsMapDataFailure(error)));
};

const getDoctorMapDataRequest = createAction(TYPES.GET_DOCTOR_MAP_DATA_REQUEST);
const getDoctorMapDataSuccess = createAction(TYPES.GET_DOCTOR_MAP_DATA_SUCCESS);
const getDoctorMapDataFailure = createAction(TYPES.GET_DOCTOR_MAP_DATA_FAILURE);

export const getDoctorMapData = ({ doctorId, divisionId }) => (dispatch) => {
  dispatch(getDoctorMapDataRequest());

  return api
    .getDoctor(doctorId)
    .then((response) => dispatch(getDoctorMapDataSuccess({ response, divisionId })))
    .catch((error) => dispatch(getDoctorMapDataFailure(error)));
};

const getDoctorRequest = createAction(TYPES.GET_DOCTOR_REQUEST);
const getDoctorSuccess = createAction(TYPES.GET_DOCTOR_SUCCESS);
const getDoctorFailure = createAction(TYPES.GET_DOCTOR_FAILURE);

export const getDoctor = (id) => (dispatch) => {
  dispatch(getDoctorRequest());

  return api
    .getDoctor(id)
    .then((response) => dispatch(getDoctorSuccess(response.data)))
    .catch((error) => dispatch(getDoctorFailure(error)));
};

const getDoctorScheduleRequest = createAction(TYPES.GET_DOCTOR_SCHEDULE_REQUEST);
const getDoctorScheduleSuccess = createAction(TYPES.GET_DOCTOR_SCHEDULE_SUCCESS);
const getDoctorScheduleFailure = createAction(TYPES.GET_DOCTOR_SCHEDULE_FAILURE);

export const getDoctorSchedule = ({ ids, date_from, date_to, replace = false, isVaccinates = false }) => (dispatch) => {
  dispatch(getDoctorScheduleRequest());

  return api
    .getDoctorSchedule({ ids, date_from, date_to, isVaccinates })
    .then((response) => dispatch(getDoctorScheduleSuccess({ data: response.data, replace })))
    .catch((error) => dispatch(getDoctorScheduleFailure({ error })));
};

const getDoctorsByDaysScheduleRequest = createAction(TYPES.GET_DOCTORS_BY_DAYS_SCHEDULE_REQUEST);
const getDoctorsByDaysScheduleSuccess = createAction(TYPES.GET_DOCTORS_BY_DAYS_SCHEDULE_SUCCESS);
const getDoctorsByDaysScheduleFailure = createAction(TYPES.GET_DOCTORS_BY_DAYS_SCHEDULE_FAILURE);

export const getDoctorsByDaysSchedule = ({ ids, date_from, date_to }) => (dispatch) => {
  dispatch(getDoctorsByDaysScheduleRequest());

  return api
    .getDoctorsByDaysSchedule({ ids, date_from, date_to })
    .then((response) => dispatch(getDoctorsByDaysScheduleSuccess({ data: response.data })))
    .catch((error) => dispatch(getDoctorsByDaysScheduleFailure({ error })));
};

const getSpecializationsRequest = createAction(TYPES.GET_SPECIALIZATIONS_REQUEST);
const getSpecializationsSuccess = createAction(TYPES.GET_SPECIALIZATIONS_SUCCESS);
const getSpecializationsFailure = createAction(TYPES.GET_SPECIALIZATIONS_FAILURE);

export const getSpecializations = ({ cityId }) => (dispatch) => {
  dispatch(getSpecializationsRequest());

  return api
    .getSpecializations(`?city_id=${cityId}`)
    .then((response) => dispatch(getSpecializationsSuccess(response.data)))
    .catch((error) => dispatch(getSpecializationsFailure(error)));
};
