import React from 'react';
import { NavLink } from 'react-router-dom';

export function SpecializationItem({ specialization: { id, name }, user }) {
  return (
    <div className="search-field-dropdown-result-content-item" key={id}>
      <NavLink
        to={`/city/${user.city?.id}/list?specialization_id=${id}#doctors`}
        className="search-field-dropdown-result-content-link"
      >
        {name}
      </NavLink>
    </div>
  );
}
