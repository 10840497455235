import React from 'react';
import moment from 'moment';

import { ReactComponent as ScheduleInfoSvg } from '../../../../../../../assets/img/schedule-info.svg';

export default function TimeSlot({ timeSlot = {}, handleClickTimeSlot }) {
  if (timeSlot.status !== 'FREE') {
    return <li className="schedule-time-list__item empty" />;
  }

  return (
    <button type="button" className="schedule-time-list__item" onClick={() => handleClickTimeSlot(timeSlot)}>
      {moment(timeSlot.datetime).format('HH:mm')}
      {!!timeSlot?.direct_only && (
        <span className="schedule-time-list__item-icon">
          <ScheduleInfoSvg />
        </span>
      )}
    </button>
  );
}
