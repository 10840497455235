import React, { useState } from 'react';
import { MAX_RATING } from '../../../../../constants/rating';

import IndicatorsModal from '../IndicatorsModal';

const stars = (items, className) =>
  [...Array(items).keys()].map((item) => (
    <li className="rating-list-item" key={item}>
      <i className={`${className} fa-star`} />
    </li>
  ));

export default function RatingList({ rating, indicators, full_name, showRatingPopup = true }) {
  const [indicatorsVisible, setIndicatorsVisible] = useState(false);

  const currentRating = Math.round(rating);

  return (
    <>
      <div
        className={`rating${showRatingPopup && indicators?.length ? ' cursor' : ''}`}
        onClick={showRatingPopup && indicators?.length ? () => setIndicatorsVisible(true) : null}
      >
        <ul className="rating-list">
          {stars(currentRating, 'fas')}
          {stars(MAX_RATING - currentRating, 'far')}
        </ul>
        <div className="rating-numeral">
          <strong>{currentRating}</strong>/{MAX_RATING}
        </div>
      </div>

      <IndicatorsModal
        indicators={indicators}
        full_name={full_name}
        rating={rating}
        isVisible={indicatorsVisible}
        onClose={() => setIndicatorsVisible(false)}
      />
    </>
  );
}
