import React from 'react';
import { Redirect } from 'react-router-dom';

import NotFoundPage from './notFoundPage';
import { useOperatorApi } from './api';
import { useUrlParser } from '../../utils/useUrlParser';

export default function Covid({ divisions, getDivisions }) {
  const urlParser = useUrlParser();
  const isOperator = urlParser.urlData.path.includes('operator');

  const { division, noDivisionsState } = useOperatorApi({ divisions, getDivisions });

  if (noDivisionsState) return <NotFoundPage />;

  if (division)
    return (
      <Redirect
        to={`/city/${division.city_id}/vaccinations/ambulatory?hospital_id=${division.institution.id}&division_id=${
          division.id
        }${isOperator ? '&is_operator=true' : '&source=website_covid'}`}
      />
    );

  return null;
}
