import React from 'react';

export default function Phones({ phones }) {
  if (!phones.length) {
    return <div className="institution-about__information-connection-list-item empty">Немає інформації</div>;
  }

  return (
    <ul className="institution-about__information-connection-list">
      {phones.map((phone, index) => {
        const key = `${index}_${phone}`;

        return (
          <li key={key} className="institution-about__information-connection-list-item">
            {phone.value}
          </li>
        );
      })}
    </ul>
  );
}
