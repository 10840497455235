import { diffInDays } from '../utils/diffInDays';

export const days = {
  Monday: {
    full: 'Понеділок',
    short: 'Пн',
  },
  Tuesday: {
    full: 'Вівторок',
    short: 'Вт',
  },
  Wednesday: {
    full: 'Середа',
    short: 'Ср',
  },
  Thursday: {
    full: 'Четвер',
    short: 'Чт',
  },
  Friday: {
    full: "П'ятниця",
    short: 'Пт',
  },
  Saturday: {
    full: 'Субота',
    short: 'Сб',
  },
  Sunday: {
    full: 'Неділя',
    short: 'Нд',
  },
};

export const daysOfWeek = [
  {
    ru: 'Пн',
    ua: 'Пн',
    full: 'Понеділок',
    en: 'Monday',
  },
  {
    ru: 'Вт',
    ua: 'Вт',
    full: 'Вівторок',
    en: 'Tuesday',
  },
  {
    ru: 'Ср',
    ua: 'Ср',
    full: 'Середа',
    en: 'Wednesday',
  },
  {
    ru: 'Чт',
    ua: 'Чт',
    full: 'Четвер',
    en: 'Thursday',
  },
  {
    ru: 'Пт',
    ua: 'Пт',
    full: "П'ятниця",
    en: 'Friday',
  },
  {
    ru: 'Сб',
    ua: 'Сб',
    full: 'Субота',
    en: 'Saturday',
  },
  {
    ru: 'Вс',
    ua: 'Hд',
    full: 'Неділя',
    en: 'Sunday',
  },
];

export const months = {
  January: {
    full: 'Січень',
    short: 'Січ',
    date_ru: 'січня',
  },
  February: {
    full: 'Лютий',
    short: 'Лют',
    date_ru: 'лютого',
  },
  March: {
    full: 'Березень',
    short: 'Бер',
    date_ru: 'березня',
  },
  April: {
    full: 'Квітень',
    short: 'Квіт',
    date_ru: 'квітня',
  },
  May: {
    full: 'Травень',
    short: 'Трав',
    date_ru: 'травня',
  },
  June: {
    full: 'Червень',
    short: 'Черв',
    date_ru: 'червня',
  },
  July: {
    full: 'Липень',
    short: 'Лип',
    date_ru: 'липня',
  },
  August: {
    full: 'Серпень',
    short: 'Серп',
    date_ru: 'серпня',
  },
  September: {
    full: 'Вересень',
    short: 'Вер',
    date_ru: 'вересня',
  },
  October: {
    full: 'Жовтень',
    short: 'Жовт',
    date_ru: 'жовтня',
  },
  November: {
    full: 'Листопад',
    short: 'Лист',
    date_ru: 'листопада',
  },
  December: {
    full: 'Грудень',
    short: 'Груд',
    date_ru: 'грудня',
  },
};

export const relativeDateString = (startDate, endDate) => {
  const diff = diffInDays(new Date(startDate), new Date(endDate));

  switch (diff) {
    case 0:
      return 'сьогодні';

    case 1:
      return 'завтра';

    case 2:
      return 'післязавтра';

    default:
      return '';
  }
};

export const employeesSortOptions = [
  {
    value: 'rating',
    label: 'Рейтинг',
    hidden: true,
    order: 'desc',
  },
  {
    value: 'number_of_patients',
    label: 'Проліковано хворих',
    title: 'Кількість пролікованих хворих вказана лікарем-спеціалістом, інформацію про яких надала лікарня',
    unit: 'осіб',
    order: 'desc',
  },
  {
    value: 'average_treatment_duration',
    label: 'Середній термін лікування',
    title: 'Середня тривалість лікування пацієнтів (у днях) вказана лікарем-спеціалістом',
    unit: 'днів',
    order: 'asc',
    decimal: 1,
  },
  {
    value: 'complexity_index',
    label: 'Середній індекс складності пролікованих хворих',
    title:
      'Відображає середній показник складності пролікованих хворих лікарем-спеціалістом, ' +
      'відповідно до міжнародної класифікації за DRG, інформацію про яких надала лікарня. ' +
      'Значення показника коливається від 0,2 (найнижчий) до 100 (максимальний)',
    order: 'desc',
    decimal: 3,
  },
];

export const institutionsSortOptions = [
  {
    value: 'institutions.rating',
    label: 'Рейтинг',
    hidden: true,
    order: 'desc',
  },
  {
    value: 'beds_amount',
    label: 'Кількість ліжок',
    title: 'Кількість ліжок у стаціонарному відділенні лікарні.',
    unit: 'шт',
    order: 'desc',
  },
  {
    value: 'number_of_patients',
    label: 'Проліковано хворих',
    title: 'Кількість пролікованих хворих у стаціонарному відділенні, інформацію про яких надала лікарня',
    unit: 'осіб',
    order: 'desc',
  },
  {
    value: 'average_treatment_duration',
    label: 'Середній термін лікування',
    title: 'Середня тривалість лікування пацієнтів (у днях) у вказаному відділені (міжнародна абревіатура – ALOS)',
    unit: 'днів',
    order: 'asc',
    decimal: 1,
  },
  {
    value: 'daily_cost_of_medication',
    label: 'Витрати на медикаменти (1 доба)',
    title:
      'Середні витрати лікарні на медикаментозне забезпечення пацієнта на 1 добу (у гривнях) у вказаному стаціонарному відділенні',
    unit: 'грн',
    order: 'desc',
  },
  {
    value: 'complexity_index',
    label: 'Середній індекс складності пролікованих хворих',
    title:
      'Відображає середній показник складності пролікованих хворих у відділенні, відповідно до міжнародної класифікації за DRG, ' +
      'інформацію про яких надала лікарня. ' +
      'Значення показника коливається від 0,2 (найнижчий) до 100 (максимальний)',
    order: 'desc',
    decimal: 3,
  },
];

export const DNIPRO_CITY_ID = 1;
export const KHERSON_CITY_ID = 2;
