import React from 'react';
import { Col } from 'react-bootstrap';

import BarCodeSvg from '../../../../assets/img/next/code/bar-code.svg';

export default function PersonalCodeCard({ patient, setPersonalCodeModalIsVisible }) {
  const personalCodeTitle = 'Персональний код пацієнта';

  return (
    <Col md={4}>
      <div
        className={`user-cabinet-navigation__item ${patient ? 'pointer' : 'disabled'}`}
        onClick={patient ? () => setPersonalCodeModalIsVisible(true) : null}
      >
        <div className="user-cabinet-navigation__item-description">
          <div className="user-cabinet-navigation__item-description-icon">
            <img src={BarCodeSvg} alt={personalCodeTitle} />
          </div>
          {personalCodeTitle}
        </div>
        <div className="user-cabinet-navigation__item-status" />
      </div>
    </Col>
  );
}
