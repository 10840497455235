import React, { useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';

import RenderJobs from '../../../list/doctorsPageList/common/Doctor/jobs/renderJobs';

export default function Jobs({ jobs, doctor, city }) {
  const [open, setOpen] = useState(false);

  const toggleJobsInfo = () => setOpen((prevState) => !prevState);

  const toggleButtonText = open ? 'Приховати місце роботи' : 'Показати місце роботи';

  if (!jobs) return null;

  return (
    <>
      <Accordion className="medic-feature-profile-information__work-list">
        <div className="medic-feature-profile-information__work-list-head">
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="0"
            className="medic-feature-profile-information__dropdown"
          >
            <div className="medic-feature-profile-information__dropdown-item" onClick={toggleJobsInfo}>
              {toggleButtonText} <i className="fas fa-chevron-down" />
            </div>
          </Accordion.Toggle>
        </div>

        <div className="medic-feature-profile-information__work-list-body">
          <Accordion.Collapse eventKey="0">
            <RenderJobs doctor={doctor} jobs={jobs} city={city} />
          </Accordion.Collapse>
        </div>
      </Accordion>
    </>
  );
}
