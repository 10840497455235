import React from 'react';

import RatingList from './ratingList';

export default function RatingBar({ rating }) {
  return (
    <div className="rating-wrapper">
      <RatingList rating={rating} />
    </div>
  );
}
