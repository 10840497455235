import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import Footer from '../components/global/footer/index';
import HeaderSearch from '../components/global/header/headerSearch';

import Doctor from './common/Doctor';

import { useDoctorPageApi, useDoctorScheduleApi } from './api';
import { usePatientsApi } from '../list/doctorsPageList/api';
import { useSetCity } from '../../hooks/useSetCity';

// TODO: fetch patients on the App-Level;

export default function DoctorPageComponent({
  doctor,
  doctorSchedule,
  getDoctor,
  getDoctorSchedule,
  doctorsByDaysSchedule,
  getDoctorsByDaysSchedule,
  getPatients,
  user,
  updateCity,
  setCalendarDate,
}) {
  const { city } = useSetCity({ user, updateCity });
  useDoctorPageApi({ getDoctor });
  useDoctorScheduleApi({ doctor, getDoctorSchedule, doctorSchedule, getDoctorsByDaysSchedule, city });
  usePatientsApi({ getPatients, token: user.token });

  if (!city || (doctor.loaded && !doctor.data)) return <Redirect to="/not-found-page" />;

  return (
    <div>
      <HeaderSearch city={user?.city} breadCrumbs={{ className: 'doctor-page' }} />
      <main>
        <div className="medic-feature-wrapper">
          <Container>
            {Boolean(doctor.data) && (
              <Doctor
                key={doctor.data.id}
                id={doctor.data.id}
                description={doctor.data.description}
                full_name={doctor.data.full_name}
                jobs={doctor.data.jobs}
                indicators={doctor.data.indicators}
                specializations={doctor.data.specializations}
                doctorSchedule={doctorSchedule}
                currentDoctorSchedule={doctorSchedule.data[0]}
                phones={doctor.data.contacts.filter((contact) => contact.type === 'phone')}
                rating={doctor.data.rating}
                photo={doctor.data.photo}
                doctor={doctor.data}
                byDaysSchedule={doctorsByDaysSchedule.data[0]}
                user={user}
                city={city}
                setCalendarDate={setCalendarDate}
              />
            )}
          </Container>
        </div>
      </main>
      <Footer />
    </div>
  );
}
