import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

export default function useMoveDoctorScheduleRange({ doctorSchedule }) {
  const [currentDatesRange, setCurrentDateRange] = useState(doctorSchedule.datesRange);

  useEffect(() => {
    if (currentDatesRange.length !== doctorSchedule.datesRange.length) setCurrentDateRange(doctorSchedule.datesRange);
  }, [currentDatesRange.length, doctorSchedule.datesRange]);

  const moveDatesRangeLeft = useCallback(() => {
    if (currentDatesRange[0] === doctorSchedule.initialDateFrom) {
      return;
    }

    setCurrentDateRange([
      moment(currentDatesRange[0]).subtract(1, 'd').format('YYYY-MM-DD'),
      ...currentDatesRange.slice(0, currentDatesRange.length - 1),
    ]);
  }, [currentDatesRange, doctorSchedule.initialDateFrom]);

  const moveDatesRangeRight = useCallback(() => {
    if (currentDatesRange[currentDatesRange.length - 1] === doctorSchedule.initialDateTo) {
      return;
    }

    setCurrentDateRange([
      ...currentDatesRange.slice(1, currentDatesRange.length),
      moment(currentDatesRange[currentDatesRange.length - 1])
        .add(1, 'd')
        .format('YYYY-MM-DD'),
    ]);
  }, [currentDatesRange, doctorSchedule.initialDateTo]);

  return { moveDatesRangeLeft, moveDatesRangeRight, currentDatesRange };
}
