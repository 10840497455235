import React from 'react';

export default function MobileEmptySchedule() {
  return (
    <div className="schedule mobile">
      <div className="schedule-body-no-result">
        <div className="schedule-body-no-result-content">
          <i className="fas fa-calendar-times" />В обраний день прийому немає або все зайнято
        </div>
      </div>
    </div>
  );
}
