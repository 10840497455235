import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

import MarkersCluster from './markersCluster';
import MarkerInfo from './markerInfo';
import { useGoogleMapApi } from './api';

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

export default function GoogleMapComponent({
  data,
  activeMarker,
  mapCenter,
  mapZoom,
  city,
  isVaccination = false,
  disableLink = false,
}) {
  const { markers, getMapCenter, currentMapZoom, currentActiveMarker, onMarkerClick, onMapClick } = useGoogleMapApi({
    data,
    activeMarker,
    city,
    mapZoom,
    mapCenter,
  });

  if (!city?.position) return <div className="google-map-wrapper">Для відображення карти виберіть місто.</div>;

  return (
    <div className="google-map-wrapper">
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={getMapCenter()}
          zoom={currentMapZoom}
          onClick={onMapClick}
        >
          <MarkersCluster markers={markers} onMarkerClick={onMarkerClick} />
          <MarkerInfo
            disableLink={disableLink}
            marker={currentActiveMarker}
            city={city}
            onMarkerInfoClose={onMapClick}
            isVaccination={isVaccination}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
