import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Item({ disabled, children, to, onClick, onlySpan }) {
  const isSpan = disabled || onlySpan;
  const Component = isSpan ? 'span' : NavLink;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li className={`page-item${disabled ? ' disabled' : ''}`} onClick={onlySpan ? onClick : null}>
      <Component
        to={isSpan ? null : to}
        className="page-link"
        disabled={disabled}
        role={disabled && onlySpan ? null : 'button'}
      >
        {children}
      </Component>
    </li>
  );
}
