import moment from 'moment';
import React from 'react';

const RenderDate = ({ each, handleDateClick, onCloseCalendar }) => {
  const startDate = moment().format('YYYY-MM-DD');
  const endDate = moment().add(2, 'w').format('YYYY-MM-DD');
  const currentDate = moment(`${each.year}-${each.month + 1}-${each.date}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
  const isActive = currentDate >= startDate && currentDate <= endDate;

  const handleClick = () => {
    if (isActive) {
      onCloseCalendar();
      handleDateClick(currentDate);
    }
  };

  return (
    <div className={`calendar-mobile-item ${isActive ? 'active' : 'disable'}`} onClick={handleClick}>
      {each.date}
    </div>
  );
};

export default function Dates({ dates, handleDateClick, onCloseCalendar }) {
  return (
    <div className="calendar-mobile-body">
      {dates.length > 0 &&
        dates.map((week, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="calendar-mobile-row start" key={index}>
            {week.map((each) => (
              <RenderDate
                key={`${each.year}-${each.month + 1}-${each.date}`}
                each={each}
                handleDateClick={handleDateClick}
                onCloseCalendar={onCloseCalendar}
              />
            ))}
          </div>
        ))}
      <div className="calendar-mobile-footer">Прийом доступний на найближчі 2 тижні</div>
    </div>
  );
}
