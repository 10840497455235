import React from 'react';

const contactsTypes = {
  skype: 'skype',
  email: 'email',
  phone: 'phone',
};

const renderTypeAndValue = ({ type, value }) => {
  switch (type) {
    case contactsTypes.phone:
      return { type: 'Телефон', value };

    case contactsTypes.skype:
      return { type: 'Skype', value };

    case contactsTypes.email:
      return { type: 'E-mail', value: <a href={`mailto:${value}`}>{value}</a> };

    default:
      return { type: '', value: '' };
  }
};

export default function Contacts({ contacts, className }) {
  if (!contacts || !contacts.length) return null;

  return contacts.map((contact) => {
    const rendered = renderTypeAndValue(contact);

    return (
      <li className={className} key={`${contact.type}-${contact.value}`}>
        <div className={`${className}-feature`}>{rendered.type}</div>
        <div className={`${className}-description`}>{rendered.value}</div>
      </li>
    );
  });
}
