import React from 'react';
import { NavLink } from 'react-router-dom';
import DefaultLogo from '../../../../assets/img/next/logo.svg';

const HeaderLogo = ({ city }) => {
  const imageSrc = city?.settings?.web_logo ?? DefaultLogo;

  return (
    <NavLink to="/">
      <div className="logotype">
        <img alt="" src={imageSrc} height="55px" />
        {process.env.REACT_APP_ENV.toLowerCase() !== 'production' && <div className="demo-mode">Демо режим</div>}
      </div>
    </NavLink>
  );
};

export default HeaderLogo;
