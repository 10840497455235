import {
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE
} from '../constants/types';

const initialState = {
  loading: false,
  data: [],
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };

    case GET_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}