import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';

import Logo from '../../assets/img/green-logo.svg';
import GooglePlay from '../../assets/img/google-play.png';
import AppleStore from '../../assets/img/apple-store.png';

const options = {
  autoConfig: true,
  debug: process.env.REACT_APP_FACEBOOK_PIXEL_DEBUG === 'true',
};

if (process.env.REACT_APP_ENV.toLowerCase() !== 'local') {
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, null, options);
}

export default function Application() {
  useEffect(() => {
    if (process.env.REACT_APP_ENV.toLowerCase() !== 'local') ReactPixel.pageView();
  }, []);

  return (
    <div className="application-wrapper">
      <Container>
        <div className="application">
          <div className="application-logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="application-title">Завантажуй додаток</div>
          <div className="application-list">
            <div className="application-list__item">
              <a href="https://play.google.com/store/apps/details?id=ua.inn.likari">
                <img src={GooglePlay} alt="google play" />
              </a>
            </div>

            <div className="application-list__item">
              <a href="https://apps.apple.com/app/id1538791279">
                <img src={AppleStore} alt="apple store" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
