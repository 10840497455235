import React from 'react';

import TimeSlot from './timeSlot';
import AdditionalTimeSlots from './additionalTimeSlots';

export default function VerticalLineSchedule({
  schedule = [],
  handleClickTimeSlot,
  horizontalLines,
  horizontalLinesToShow,
}) {
  return (
    <li className="schedule-list__item">
      <ul className="schedule-time-list">
        {horizontalLines.map((line, index) => (
          <TimeSlot key={line} timeSlot={schedule[index]} handleClickTimeSlot={handleClickTimeSlot} />
        ))}

        <AdditionalTimeSlots
          schedule={schedule}
          handleClickTimeSlot={handleClickTimeSlot}
          horizontalLinesToShow={horizontalLinesToShow}
        />
      </ul>
    </li>
  );
}
