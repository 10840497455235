import React from 'react';
import { NavLink } from 'react-router-dom';

import RecipesLinkSvg from '../../../../assets/img/recipes_link.svg';
import VisitInfo from './info';

export default function VisitsList({ visits, user }) {
  return visits.map((visit) => (
    <li className="personal-visits-list__item" key={visit.id}>
      <VisitInfo {...visit} user={user} />

      {Boolean(visit.drugs.length) && (
        <NavLink to={`/office/visits/${visit.id}`} className="personal-visits-list__item-button">
          <div className="personal-visits-list__item-button-icon">
            <img src={RecipesLinkSvg} alt="recipesLink" />
          </div>
          <div className="personal-visits-list__item-button-description">Рецепти та рекомендації</div>
        </NavLink>
      )}
    </li>
  ));
}
