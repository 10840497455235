import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import CityDropdown from '../../../components/global/footer/cityDropdown';
import { updateCity } from '../../../../actions';
import { useCityChangeApi } from './api';

const CityChange = ({ user, updateCity, history, disableRedirect }) => {
  const { isOpen, onSubmit, setCurrentCity, currentCity } = useCityChangeApi({
    user,
    updateCity,
    history,
    disableRedirect,
  });

  return (
    <Modal show={isOpen} onHide={() => {}} className="modal-city" centered>
      <Modal.Header>
        <Modal.Title>Оберіть місто</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {user.initialCity
          ? `Ми визначили ваше місто як ${user.initialCity.name}, підтвердити?`
          : 'У вашому місті сервіс очікується найближчим часом. Ви можете вибрати такі міста:'}
        <CityDropdown cities={user.cities} onChange={(city) => setCurrentCity(city)} currentCity={currentCity} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmit} disabled={!currentCity}>
          Зберегти
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = { updateCity };

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

export default enhance(CityChange);
