import React from 'react';
import { Col } from 'react-bootstrap';

import DistrictDropdown from '../../components/dropDown';

export default function DistrictSelect({
  isEmptyDivisions,
  districts,
  district,
  updateDistrict,
  allDistrictSelected,
  setAllDistrictSelected,
}) {
  const handleClickAllDistricts = () => {
    setAllDistrictSelected(true);
    updateDistrict();
  };

  return (
    <Col md={6}>
      <div className="choose-district">
        <div className="title">
          {isEmptyDivisions
            ? 'Місця вакцинації не знайдені в обраному районі, спробуйте вибрати інший:'
            : 'Оберіть район для пошуку'}
        </div>
        <DistrictDropdown
          districts={districts}
          district={district}
          allDistrictSelected={allDistrictSelected}
          handleClickAllDistricts={handleClickAllDistricts}
          updateDistrict={updateDistrict}
        />
      </div>
    </Col>
  );
}
