import FamilyClinicVaccinations from './pages/ambulatory';
import SearchVaccinations from './pages/search';

import DoctorImg from '../../assets/img/family_doctor.svg';
import DoctorDisabledImg from '../../assets/img/doctor-disabled.svg';
import Hospital from '../../assets/img/hospital-marker.svg';

export const routes = [
  {
    path: '/ambulatory',
    linkLabel: 'В моїй сімейній амбулаторії',
    Component: FamilyClinicVaccinations,
    icon: DoctorImg,
    disabledIcon: DoctorDisabledImg,
    isMenuItem: true,
    regExp: /^\/ambulatory$/,
    checkError: true,
  },
  {
    path: '/search/district',
    linkLabel: 'Пошук центру вакцинації по районам міста',
    Component: SearchVaccinations,
    icon: Hospital,
    isMenuItem: true,
    regExp: /^\/search\/district$/,
    checkError: false,
  },
];
