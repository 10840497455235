import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { moveDoctorScheduleDateRangeLeft, moveDoctorScheduleDateRangeRight } from '../../../../../actions/doctors';
import { Date } from './date';
import { TheEndSchedulePopup } from './popup';
import { months } from '../../../../../constants';

function ScheduleHeader({
  doctorSchedule,
  moveDoctorScheduleDateRangeLeft,
  moveDoctorScheduleDateRangeRight,

  mobileDatesRange,
  moveMobileDatesRangeLeft,
  moveMobileDatesRangeRight,
  currentMobileDate,
  setCurrentMobileDate,
  isMobile = false,
  show,
}) {
  const theEndSchedulePopupRef = useRef();

  const clickNext = useCallback(() => {
    if (theEndSchedulePopupRef.current) theEndSchedulePopupRef.current.clickNext();

    if (isMobile) {
      moveMobileDatesRangeRight();

      return;
    }

    moveDoctorScheduleDateRangeRight();
  }, [isMobile, moveDoctorScheduleDateRangeRight, moveMobileDatesRangeRight]);

  if (!show) return null;

  return (
    <div className={`schedule-wrapper${isMobile ? ' mobile' : ''}`}>
      <div className="schedule">
        <div className="schedule-header">
          <div
            className="schedule-navigation schedule-navigation__prev"
            onClick={isMobile ? moveMobileDatesRangeLeft : moveDoctorScheduleDateRangeLeft}
          >
            <i className="fas fa-chevron-left" />
          </div>
          <ul className="schedule-date-list">
            {(isMobile ? mobileDatesRange : doctorSchedule.datesRange).map((date) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={date}
                className={`schedule-date-list__item${isMobile && date === currentMobileDate ? ' active' : ''}`}
                onClick={isMobile ? () => setCurrentMobileDate(date) : null}
              >
                <div className="schedule-date-title">{months[moment(date).format('MMMM')].short}.</div>
                <Date date={date} isMobile={isMobile} />
              </li>
            ))}

            <TheEndSchedulePopup
              datesRange={isMobile ? mobileDatesRange : doctorSchedule.datesRange}
              doctorSchedule={doctorSchedule}
              popupOutsideRef={theEndSchedulePopupRef}
            />
          </ul>
          <div className="schedule-navigation schedule-navigation__next" onClick={clickNext}>
            <i className="fas fa-chevron-right" />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  moveDoctorScheduleDateRangeLeft,
  moveDoctorScheduleDateRangeRight,
};

const mapStateToProps = ({ doctorSchedule }) => ({
  doctorSchedule,
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleHeader);
