import React from 'react';
import { NavLink } from 'react-router-dom';

import RatingBar from '../../../List/RatingBar';

export function DivisionItem({ division: { id, full_name, name, institution_id, rating, institution_name }, user }) {
  return (
    <div className="search-field-dropdown-result-content-item">
      <NavLink
        to={`/city/${user.city?.id}/hospitals/${institution_id}?division_id=${id}`}
        className="search-field-dropdown-result-content-link"
      >
        {full_name ?? name}
        {institution_name && <>&nbsp;({institution_name})</>}
      </NavLink>
      <div className="search-field-dropdown-result-content-rating">
        <RatingBar rating={rating} />
      </div>
    </div>
  );
}
