import { refreshToken } from '../actions';
import * as TYPES from '../constants/types';
import { TIME_TO_TOKEN_EXPIRED } from '../constants/config';

export const refreshTokenInterceptor = ({ dispatch, getState }) => next => action => {
  const token = localStorage.getItem('token');

  if (!token || !action.payload) {
    return next(action);
  }

  const { user } = getState();
  const now = Date.now();
  const expiresAt = localStorage.getItem('expiresAt') ?? now;
  const diff = expiresAt - now;

  if (diff <= 0 || action.payload.status === 401) {
    window.location.href = '/';
    dispatch({ type: TYPES.LOGOUT });

    return next();
  }

  if (diff <= TIME_TO_TOKEN_EXPIRED && !user.tokenRefreshing) {
    refreshToken()(dispatch);
  }

  return next(action);
};
