import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useUrlParser } from '../../utils/useUrlParser';

export function useHospitalPageApi({ getHospital, hospital }) {
  const urlParser = useUrlParser();
  const [currentDivisionId, setCurrentDivisionId] = useState(+urlParser.urlData.params.division_id);
  const [currentDivision, setCurrentDivision] = useState();
  const { hospitalId } = useParams();
  const location = useLocation();
  
  useEffect(() => {
    if (currentDivisionId !== +urlParser.urlData.params.division_id || !hospital.loaded) getHospital(hospitalId);
  }, [currentDivisionId, getHospital, hospital.loaded, hospitalId, urlParser.urlData.params.division_id]);

  useEffect(() => {
    if (+urlParser.urlData.params.division_id > 0 && currentDivisionId !== +urlParser.urlData.params.division_id)
      setCurrentDivisionId(+urlParser.urlData.params.division_id);
  }, [currentDivisionId, urlParser.urlData.params.division_id]);

  useEffect(() => {
    
    if (!currentDivisionId && hospital.data?.divisions?.length) {
      window.history.replaceState('', '', `${location.pathname}?division_id=${hospital.data.divisions[0].id}`);
      return;
    }

    if (currentDivisionId && hospital.data?.divisions?.length) {
      setCurrentDivision(hospital.data.divisions.find((division) => division.id === currentDivisionId));
      return;
    }

  }, [currentDivisionId, hospital.data, location.pathname]);

  const divisionNotExists = useMemo(
    () =>
      hospital.data?.divisions?.length &&
      currentDivisionId &&
      !hospital.data.divisions.find((division) => division.id === currentDivisionId),
    [currentDivisionId, hospital.data],
  );

  return {
    currentDivisionId,
    currentDivision,
    setCurrentDivision,
    divisionNotExists,
  };
}
