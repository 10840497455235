import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { syncHistoryWithStore } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import globalHOC from '../HOC/globalHOC';
import { routes } from './routes';

import store from '../store';

const history = syncHistoryWithStore(createBrowserHistory(), store);

export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        {routes.map(({ path, Component, exact = true }) => (
          <Route exact={exact} key={path} path={path} component={globalHOC(Component)} />
        ))}
      </Switch>
    </Router>
  );
}
