import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import HeaderLogo from '../components/global/header/logo';
import Auth from '../../containers/Auth';

import BotsImage from '../../assets/img/next/bot-images/bot.png';
import Telegram from '../../assets/img/next/bot-images/telegram.png';
import Viber from '../../assets/img/next/bot-images/viber.png';

import { connectBot } from '../../api';
import { useBotApi } from '../botPage/api';

export default function Bot({ user, clearAuthState }) {
  const { botType, botButtonClick, authIsOpen, toggleAuthForm } = useBotApi({
    token: user?.token,
    clearAuthState,
    connectBot,
  });

  return (
    <div>
      <header className="header header--search header--logo-centered">
        <Container>
          <HeaderLogo city={user?.city} />
        </Container>
      </header>

      <main>
        <Container>
          <div className="bot-wrapper">
            <Row>
              <Col xs="12" className="bot-about text-center">
                <h2>Установка чат-бота</h2>

                <p className="bot-description">Оберіть платформу:</p>

                <div className="bot-btn-simplified">
                  <button type="button" className=" btn btn-primary" onClick={botButtonClick('telegram')}>
                    <img src={Telegram} alt="" />
                    Telegram
                  </button>
                  <span className="or">або</span>
                  <button type="button" className=" btn btn-primary" onClick={botButtonClick('viber')}>
                    <img src={Viber} alt="" />
                    Viber
                  </button>
                </div>

                <Col xs="12" className="main-img mt-4">
                  <img src={BotsImage} alt="" />
                </Col>
              </Col>
            </Row>
          </div>
        </Container>
      </main>

      <Auth
        isOpen={authIsOpen}
        onClose={toggleAuthForm}
        denyRedirect
        connectToBotFn={botType ? connectBot(botType) : null}
      />
    </div>
  );
}
