import React from 'react';
import { NavLink } from 'react-router-dom';

import RatingBar from '../../../../components/global/List/RatingBar';
import defaultMedicSvg from '../../../../../assets/img/next/svg/medic.svg';

export default function Medic({ id, photo, full_name, rating, specializations, indicators, city }) {
  return (
    <li className="institution-info__medic-list-item" key={id}>
      <div className="institution-info__medic-icon-wrapper">
        <div className="institution-info__medic-icon" style={{ backgroundImage: `url(${photo ?? defaultMedicSvg})` }} />
        <RatingBar rating={rating} full_name={full_name} indicators={indicators} />
      </div>

      <div className="institution-info__medic-about">
        <NavLink to={`/city/${city.id}/doctors/${id}`} className="institution-info__medic-info-title">
          {full_name}
        </NavLink>
        <div key={id} className="institution-info__medic-info-subtitle">
          {specializations.map(({ name }) => name).join('; ')}
        </div>

        <RatingBar rating={rating} full_name={full_name} indicators={indicators} />
      </div>
    </li>
  );
}
