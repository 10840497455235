import Ciet from '../../../../../assets/img/next/partners/ciet.png';
import Advanter from '../../../../../assets/img/next/partners/advanter.png';
import Google from '../../../../../assets/img/google-app.svg';
import Apple from '../../../../../assets/img/apple-app.svg';

const partnersList = [
  {
    item: Ciet,
    link: 'https://ciet-holding.com/',
    alt: 'partners',
  },
  {
    item: Advanter,
    link: 'https://www.facebook.com/advanterhealth/',
    alt: 'partners',
  },
];

const appList = [
  {
    item: Google,
    link: 'https://play.google.com/store/apps/details?id=ua.inn.likari',
    alt: 'Google play',
  },
  {
    item: Apple,
    link: 'https://apps.apple.com/app/id1538791279',
    alt: 'Apple store',
  },
];

const aboutProject = [
  {
    icon: 'fa-exclamation-circle',
    link: '/about',
    text: 'Про проект',
  },
];

const contacts = [
  {
    item: '066 121-03-03',
    link: 'tel:+380661210303',
  },
  {
    item: '093 121-03-03',
    link: 'tel:+380931210303',
  },
  {
    item: '097 121-03-03',
    link: 'tel:+380971210303',
  },
];

export { partnersList, aboutProject, contacts, appList };
