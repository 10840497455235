import React, { useMemo, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import Sticky from 'react-sticky-el';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getHospital } from '../../../../actions/hospitals';
import ScheduleHeader from '../../../components/global/List/ScheduleHeader';
import HeadRow from '../../../office/common/HeadRow';
import Hospital from '../../../list/divisionPageList/common/Division';
import { useSetCity } from '../../../../hooks/useSetCity';
import { useHospitalPageApi } from './api';
import DoctorsList from './doctors';
import Calendar from '../../../components/global/List/Calendar';
import { useUrlParser } from '../../../../utils/useUrlParser';

import { setCalendarDate } from '../../../../actions/doctors';
import { updateCity } from '../../../../actions';
import BreadCrumbs from '../../components/breadCrumbs';

const VaccinationAmbulatory = ({
  currentPatient,
  doctors,
  doctorSchedule,
  setCalendarDate,
  patients,
  setPatient,
  user,
  hospital,
  getHospital,
  updateCity,
}) => {
  const urlParser = useUrlParser();
  const history = useHistory();
  const match = useRouteMatch();
  const { city } = useSetCity({ user, updateCity });
  const calendarDate = useMemo(() => {
    if (!urlParser.urlData.params?.date) return doctorSchedule.date;

    const paramDate = new Date(urlParser.urlData.params.date);

    return paramDate <= new Date(doctorSchedule.initialDateTo) && paramDate >= new Date(doctorSchedule.initialDateFrom)
      ? urlParser.urlData.params.date
      : doctorSchedule.date;
  }, [doctorSchedule, urlParser.urlData.params]);

  useEffect(() => {
    if (doctorSchedule.loaded && urlParser.urlData.params?.date) setCalendarDate(calendarDate);
  }, [calendarDate, doctorSchedule.loaded, setCalendarDate, urlParser.urlData.params]);

  const { currentDivision } = useHospitalPageApi({
    getHospital,
    hospital,
    currentPatient,
  });

  const changePatient = (patient) => {
    setPatient(patient);

    const newPatient = patients.data.find((p) => p.id === patient);
    const prevSearch = `?hospital_id=${currentPatient?.registration.institution_id}&division_id=${currentPatient?.registration?.division_id}`;
    const nextSearch = `?hospital_id=${newPatient?.registration.institution_id}&division_id=${newPatient?.registration?.division_id}`;

    if (prevSearch !== nextSearch) {
      history.push({
        pathname: match.path,
        search: `?hospital_id=${newPatient.registration.institution_id}&division_id=${newPatient?.registration?.division_id}&family_ambulatory=true`,
      });
    }
  };

  return (
    <>
      <BreadCrumbs
        breadCrumbs={{ className: 'family-clinic-vaccinations' }}
        redirectLink={urlParser.urlData.params.family_ambulatory ? '/vaccinations' : false}
      />
      <div className="medic-feature-wrapper medic-feature-wrapper--vaccination">
        <Container>
          <HeadRow
            hideSelect={user?.token || !urlParser.urlData.params.family_ambulatory}
            patient={currentPatient}
            patients={patients}
            setPatient={changePatient}
            user={user}
            headerLabel="Ви обрали амбулаторію"
          />

          {hospital.data && (
            <Hospital
              institutionId={hospital.data.id}
              full_name={hospital.data.full_name}
              address={currentDivision ? currentDivision.address : hospital.data.address}
              remainedMedicinesUrl={hospital.data.remained_medicines_url}
              phones={
                currentDivision
                  ? currentDivision.contacts.filter((contact) => contact.type === 'phone')
                  : hospital.data.contacts.filter((contact) => contact.type === 'phone')
              }
              photo={hospital.data.photo}
              mapUrl={
                currentDivision && currentDivision.address.lat && currentDivision.address.lon
                  ? `https://maps.google.com/maps/place/${currentDivision.address.lat},${currentDivision.address.lon}`
                  : null
              }
              rating={hospital.data.rating}
              description={hospital.data.description}
              city={city}
              currentDivision={currentDivision}
              myAmbulatoryNote={urlParser.urlData.params.family_ambulatory}
              disableOwnLink
              externalMapUrl
            />
          )}
          {Boolean(doctors.data.length) && (
            <>
              <Sticky className="sticky-wrapper">
                <Container className="navigation-container">
                  <div className="row">
                    <div className="navigation">
                      <div className="title">Оберіть кабінет або лікаря для проходження вакцинації</div>
                      <div className="navigation-helpers">
                        <Calendar setCalendarDate={setCalendarDate} calendarDate={calendarDate} />
                      </div>
                      <ScheduleHeader show />
                    </div>
                  </div>
                </Container>
              </Sticky>

              {/* Mobile */}
              <Container className="navigation-container">
                <div className="row">
                  <div className="navigation">
                    <div className="title">Оберіть дату візиту</div>
                    <Sticky className="sticky-wrapper">
                      <div className="navigation-helpers">
                        <Calendar setCalendarDate={setCalendarDate} calendarDate={calendarDate} isMobile />
                      </div>
                      <ScheduleHeader show />
                    </Sticky>
                  </div>
                </div>
              </Container>
            </>
          )}

          {hospital.data && (
            <>
              <Container className="navigation-container mt-3">
                <div className="row">
                  <div className="navigation" style={{ zIndex: 0 }}>
                    <div className="title mb-0">Оберіть кабінет для вакцинації</div>
                  </div>
                </div>
              </Container>
              <DoctorsList
                websiteCovidSource={urlParser.urlData.params.source}
                isOperator={urlParser.urlData.params.is_operator}
                currentDivisionId={currentPatient?.registration?.division_id}
                placeholder={
                  <div className="no-doctors-placeholder">
                    <span>В амбулаторії вакцинація від Covid-19 не проводиться</span>
                  </div>
                }
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = ({ hospital, doctors, doctorSchedule }) => ({ hospital, doctors, doctorSchedule });

const mapDispatchToProps = { getHospital, setCalendarDate, updateCity };

export default connect(mapStateToProps, mapDispatchToProps)(VaccinationAmbulatory);
