import {
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_NEXT_PAGE_REQUEST
} from '../constants/types';

const initialState = {
  loading: false,
  fetching: false,
  error: null,
  data: [],
  pagination: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
        fetching: true
      };

    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: null,
        data: action.payload.data,
        pagination: action.payload.pagination
      };

    case GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: action.payload
      };

    case GET_EMPLOYEES_NEXT_PAGE_REQUEST:
      return {
        ...state,
        fetching: true
      };

    default:
      return state;
  }
}