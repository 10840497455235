import React from 'react';
import { NavLink } from 'react-router-dom';

import RecipesLinkSvg from '../../../../assets/img/recipes_link.svg';
import VisitInfo from './info';
import VisitRecipes from './visitRecipes';
import BuyRecipes from '../../common/buyRecipes';

export default function Visit({ visit, user }) {
  return (
    <li className="personal-visits-list__item" key={visit.id}>
      <VisitInfo {...visit} user={user} />

      <NavLink to="/office/visits" className="personal-visits-list__item-button">
        <div className="personal-visits-list__item-button-icon">
          <img src={RecipesLinkSvg} alt="recipesLink" />
        </div>
        <div className="personal-visits-list__item-button-description">До списку візитів</div>
      </NavLink>

      <VisitRecipes {...visit} />

      <BuyRecipes buUrl={visit.buy_url} />
    </li>
  );
}
