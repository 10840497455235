import React from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

export default function BreadCrumbs({ breadCrumbs, redirectLink }) {
  const history = useHistory();

  if (!breadCrumbs) return null;

  return (
    <div
      className={`breadcrumbs-wrapper breadcrumbs-wrapper--vaccination${
        breadCrumbs.className ? ` ${breadCrumbs.className}` : ''
      }`}
    >
      <Container>
        <div className="breadcrumbs-item">
          <span
            className="breadcrumbs-link"
            onClick={() => {
              if (redirectLink) {
                history.push(redirectLink);
              } else {
                history.goBack();
              }
            }}
          >
            <i className="fas fa-chevron-left" />
            Назад
          </span>
        </div>
      </Container>
    </div>
  );
}
