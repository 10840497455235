import React from 'react';
import { connect } from 'react-redux';

import {
  getCurrentAppointments,
  getPastAppointments,
  cancelAppointment,
} from '../../../../actions/appointmentsActions';

import CurrentAppointments from './current';
import PastAppointments from './past';
import { useAppointmentsApi } from './api';

const StreamingRecords = ({
  appointments,
  cancelAppointment,
  getCurrentAppointments,
  getPastAppointments,
  patient,
  user,
}) => {
  const {
    pastAppointmentsRef,
    currentAppointmentsRef,
    currentAppointmentsVisible,
    pastAppointmentsVisible,
    setCurrentPage,
    goToPastAppointments,
    goToCurrentAppointments,
  } = useAppointmentsApi({ appointments, patient, getCurrentAppointments, getPastAppointments });

  return (
    <>
      <div className="streaming-records-wrapper">
        <CurrentAppointments
          currentRef={currentAppointmentsRef}
          appointments={appointments}
          cancelAppointment={cancelAppointment}
          goToPastAppointments={goToPastAppointments}
          isVisible={currentAppointmentsVisible}
          city={user.city}
        />

        <PastAppointments
          pastRef={pastAppointmentsRef}
          appointments={appointments}
          setCurrentPage={setCurrentPage}
          goToCurrentAppointments={goToCurrentAppointments}
          isVisible={pastAppointmentsVisible}
          city={user.city}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ appointments, user }) => ({ appointments, user });

const mapDispatchToProps = { getCurrentAppointments, getPastAppointments, cancelAppointment };

export default connect(mapStateToProps, mapDispatchToProps)(StreamingRecords);
