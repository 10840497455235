import { connect } from 'react-redux';

import SimplifiedBotComponent from '../next/simplifiedBotPage';
import { clearAuthState } from '../actions';

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = {
  clearAuthState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimplifiedBotComponent);
