import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';
import './next/homepage/next.scss';

import App from './App';
import store from './store';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
