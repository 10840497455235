import React from 'react';

export default function RenderPatient({ patient, register, currentPatientId }) {
  const defaultChecked = currentPatientId === patient.id;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="radio">
      <input type="radio" name="patient" value={patient.id} ref={register} defaultChecked={defaultChecked} />
      <div className="radio__text">{patient.full_name}</div>
    </label>
  );
}
