import React, { useState } from 'react';

export function DetailedDescription({ description }) {
  const [fullDescriptionIsVisible, setFullDescriptionIsVisible] = useState(false);

  if (!description) return null;

  if (description.split(' ').length <= 20)
    return <div className="institution-about__description-description">{description}</div>;

  return (
    <div className="institution-about__description-description">
      {`${description.split(' ').slice(0, 20).join(' ')}`}

      {fullDescriptionIsVisible ? (
        <span className="description-rest">
          &nbsp;
          {`${description.split(' ').slice(20).join(' ')}`}&nbsp;
          <i className="fas fa-chevron-up" onClick={() => setFullDescriptionIsVisible(false)} />
        </span>
      ) : (
        <>
          <span>&nbsp;...&nbsp;</span>
          <i className="fas fa-chevron-down" onClick={() => setFullDescriptionIsVisible(true)} />
        </>
      )}
    </div>
  );
}
