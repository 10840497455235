import * as TYPES from '../constants/types';

const initialState = {
  message: null,
  statusCode: null,
  showMessage: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SHOW_ALERT_MESSAGE: {
      const { message, statusCode } = action.payload;

      return { ...state, message, statusCode, showMessage: true };
    }

    case TYPES.HIDE_ALERT_MESSAGE: {
      return { ...state, message: null, statusCode: null, showMessage: false };
    }

    default:
      return state;
  }
};
