import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

import { getPatientsVaccinations } from '../../../../api';
import TextLoader from '../../../components/global/Loader/text';
import EmptyData from '../../../components/global/emptyData';

const PersonalVaccination = ({ patient }) => {
  const [vaccinations, setVaccinations] = useState(null);

  useEffect(() => {
    if (patient.data) {
      getPatientsVaccinations(patient.data.id).then((response) => {
        setVaccinations(response.data);
      });
    }
  }, [patient.data]);

  if (!vaccinations) return <TextLoader />;

  if (!vaccinations.length) return <EmptyData />;

  return (
    <div className="personal-vaccination-wrapper">
      <div className="personal-vaccination">
        <ul className="personal-vaccination-list">
          <li className="personal-vaccination-list__item">
            <div className="personal-vaccination-list__item-name">Назва щеплення</div>
            <div className="personal-vaccination-list__item-data">Дата</div>
            <div className="personal-vaccination-list__item-description">Коментар</div>
          </li>
          {vaccinations.map(({ comment, date, external_id, vaccin_type }) => (
            <Fragment key={external_id}>
              <li className="personal-vaccination-list__item personal-vaccination-list__item-mobile">
                <div className="personal-vaccination-list__item-data">
                  <i className="far fa-calendar-alt" />
                  {moment(date).format('DD.MM.YYYY')}
                </div>

                <div className="personal-vaccination-list__item-name">
                  Назва щеплення
                  <strong>{vaccin_type}</strong>
                </div>

                <div className="personal-vaccination-list__item-description">
                  Коментар
                  <strong>{comment || '-'}</strong>
                </div>
              </li>
              <li className="personal-vaccination-list__item">
                <div className="personal-vaccination-list__item-name">{vaccin_type}</div>
                <div className="personal-vaccination-list__item-data">
                  <i className="fas fa-calendar-alt" />
                  {moment(date).format('DD.MM.YYYY')}
                </div>
                <div className="personal-vaccination-list__item-description">{comment || '-'}</div>
              </li>
            </Fragment>
          ))}
        </ul>
        <div className="mt-2">
          Перевіртити Ваш сертифікат в міжнародній базі можна&nbsp;
          <a
            href="https://sanipasse.fr/?fbclid=IwAR16PQ-AZCxP4cZHoDsfN9dzdkTu1qF_GX9IK0fqWG2OMk-gdznICZY2xQI"
            target="_blank"
            rel="noopener noreferrer"
          >
            за посиланням
          </a>
        </div>
      </div>
    </div>
  );
};

export default PersonalVaccination;
