import React from 'react';
import InputMask from 'react-input-mask';

const RenderField = ({ text, input, error, register }) => {
  const errorStyles = {
    borderColor: 'red',
  };

  const errorMessage = error && typeof error === 'object' && error[input.name];

  return (
    <div className="appointment_field_container">
      <div className="appointment_field_title">
        <span>{text}</span>
        {input && input.required && <span className="require">*</span>}
      </div>

      <div className="appointment_field_wrapper">
        {input.mask && (<InputMask ref={register} {...input} style={errorMessage ? errorStyles : {}}>{inputProps => <input ref={register} {...inputProps} />}</InputMask>)}
        {!input.mask && <input ref={register} {...input} style={errorMessage ? errorStyles : {}} />}
        {errorMessage && <p className="appointment_input-error">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default RenderField;
