import React from 'react';

export default function EmptySchedule({ placeholder }) {
  return (
    <div className="schedule-wrapper">
      <div className="schedule">
        <div className="schedule-body">
          <div className="schedule-body__placeholder">{placeholder ?? null}</div>
        </div>
      </div>
    </div>
  );
}
