import React from 'react';

import RatingBar from '../../../../components/global/List/RatingBar';

export default function Photo({ photo, defaultPhoto, rating, indicators, full_name }) {
  return (
    <div className="institution-about__icon-wrapper">
      <div className="institution-about__icon" style={{ backgroundImage: `url(${photo ?? defaultPhoto})` }} />
      <RatingBar rating={rating} indicators={indicators} full_name={full_name} />
    </div>
  );
}
