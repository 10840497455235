import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Button from 'react-bootstrap/Button';
import React from 'react';
import RenderField from '../../common/renderField';

export default function OperatorStep({ register, error, show, control }) {
  return (
    <div style={{ display: show ? 'block' : 'none', border: 0 }}>
      <div className="info_box declaration_false">
        <div className="user_info_box">
          {error && typeof error === 'string' && <span className="error">{error}</span>}
          <RenderField
            text="Номер оператора"
            error={error}
            input={{
              type: 'text',
              maxLength: 255,
              required: true,
              placeholder: 'Номер оператора',
              name: 'operatorCode',
            }}
            register={register}
          />
          <div className="appointment_field_container">
            <div className="appointment_field_title">
              <span>Номер пацієнта</span>
              <span className="require">*</span>
            </div>

            <div className="appointment_field_wrapper">
              {error && typeof error === 'object' && error.phone && (
                <p className="appointment_input-error">{error && typeof error === 'object' && error.phone[0]}</p>
              )}
              <Controller
                as={InputMask}
                control={control}
                name="phone"
                type="tel"
                mask="(099) 999 99 99"
                placeholder="(067) 111-22-33"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <Button type="submit" variant="primary" className="submit-button">
        Далі
      </Button>
    </div>
  );
}
