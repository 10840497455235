import React from 'react';
import { Container } from 'react-bootstrap';
import HeaderLogo from './logo';
import HeaderButton from './button';
import SearchForm from '../searchForm';

const Header = ({ city }) => {
  return (
    <header className="header header--search header--feature">
      <Container>
        <HeaderLogo city={city} />

        <SearchForm />

        <HeaderButton />
      </Container>
    </header>
  );
};

export default Header;
