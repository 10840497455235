import React from 'react';

import { useDivisionPageListApi } from './api';
import Division from './common/Division';
import Pagination from '../../components/global/List/Pagination';

export default function DivisionPageList({ divisions, getDivisions, city, currentDivisionsOrderBy }) {
  useDivisionPageListApi({
    divisions,
    getDivisions,
    city,
    currentDivisionsOrderBy,
  });

  if (divisions.loaded && !divisions.data.length) return <div className="empty-list">Нічого не знайдено</div>;

  return (
    <>
      {divisions.data.map((division) => (
        <Division
          key={division.id}
          remainedMedicinesUrl={division.institution.remained_medicines_url}
          divisionId={division.id}
          full_name={division.full_name}
          institutionId={division.institution.id}
          institution_full_name={division.institution.full_name}
          address={division.address}
          phones={division.contacts.filter((contact) => contact.type === 'phone')}
          rating={division.rating}
          photo={division.photo}
          indicators={division.indicators}
          city={city}
          mapUrl={
            division.address.lat && division.address.lon
              ? `https://maps.google.com/maps/place/${division.address.lat},${division.address.lon}`
              : null
          }
          // mapUrl={`/divisions/${division.id}/map`}
          showRatingIndicator
          externalMapUrl
        />
      ))}
      <Pagination
        currentPage={divisions.currentPage}
        pageName="divisions_page"
        totalPages={divisions.pagination.total_pages}
      />
    </>
  );
}
