import { LOCATION_CHANGE } from 'react-router-redux';
import * as TYPES from '../../constants/types';

const initialState = {
  data: null,
  loading: false,
  loaded: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_PATIENT_VISITS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case TYPES.GET_PATIENT_VISITS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data,
        pagination: action.payload.pagination,
      };
    }

    case TYPES.GET_PATIENT_VISITS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
      };

    case LOCATION_CHANGE:
      return initialState;

    default:
      return state;
  }
};
