import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Button from 'react-bootstrap/Button';

import { login, sendSms } from '../../actions';
// import { useTimer } from '../components/global/timer';
import moment from 'moment';

const Input = ({ handleCodeType, index }) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    if (e.target.value.length <= 1) {
      setValue(e.target.value);
      handleCodeType(e);
    }
  };

  return <input id={index} value={value} onChange={handleChange} type="number" required />;
};

// const initialTime = 30; // seconds

const Code = ({
  auth: { phone, error },
  onClose,
  sendSms,
  login,
  appointment,
  history,
  denyRedirect,
  connectToBotFn,
}) => {
  const [currentPhone] = useState(phone.phone);
  const [currentSendTime] = useState(phone.send_time);
  // const [currentTime, setCurrentTime] = useState(initialTime);
  const [code, setCode] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  // const { resetTimer } = useTimer({ initialTime, onChangeTime: setCurrentTime });

  const codeWrapper = useRef(null);

  useEffect(
    () => () => {
      // resetTimer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmit = useCallback(
    (e) => {

      e.preventDefault();

      if (code.length === 4) {
        
        setSubmitButtonDisabled(true);

        login({ phone: currentPhone, code }).then((response) => {
          if (response && response.token) {
            if (connectToBotFn) connectToBotFn();
            if (!appointment && !denyRedirect) history.push('/office');
            if (onClose) onClose();
          }
        });
      }
    },
    [code, login, currentPhone, connectToBotFn, appointment, denyRedirect, history, onClose],
  );

  // const resendSms = () => {
  //   if (currentTime === 0) {
  //     sendSms(phone.phone);

  //     setCurrentTime(initialTime);
  //   }
  // };

  const handleCodeType = (e) => {
    
    setSubmitButtonDisabled(false);

    const inputs = codeWrapper.current.getElementsByTagName('input');

    const id = +e.target.id;

    let code = '';

    Array.from(inputs).forEach((input) => {
      code += input.value;
    });

    setCode(code);

    if (inputs.length > id + 1 && e.target.value) {
      inputs[id + 1].focus();
    }
  };

  useEffect(() => {
    const inputs = codeWrapper.current.getElementsByTagName('input');

    inputs[0].focus();

    const handleBackspace = (e) => {
      const inputs = codeWrapper.current.getElementsByTagName('input');
      const id = +e.target.id;

      if (e.keyCode === 8 && !e.target.value && id > 0) {
        let code = '';

        Array.from(inputs).forEach((input) => {
          code += input.value;
        });

        setCode(code);

        inputs[id - 1].focus();
      }
    };

    Array.from(inputs).forEach((input) => {
      input.addEventListener('keydown', handleBackspace);
    });

    return () => {
      Array.from(inputs).forEach((input) => {
        input.removeEventListener('keydown', handleBackspace);
      });
    };
  }, [setCode]);

  return (
    <form className="confirmation_of_entry sms">
      <p className="appointment-auth-text">
        Вам {moment.unix(currentSendTime).format('DD.MM.YYYY, HH:mm')} було відправлено на номер +38 {currentPhone} повідомлення з кодом. Введіть його в поле нижче.
      </p>
      <div className="auth_code_wrapper">
        <div ref={codeWrapper}>
          {Array.from({ length: 4 }).map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Input handleCodeType={handleCodeType} index={i} key={i} />
          ))}
        </div>
        {error && typeof error === 'object' && error.code && (
          <span className="error_text" style={{ opacity: error.code ? 1 : 0 }}>
            {error.code}
          </span>
        )}
      </div>
      {/* <div style={{ display: 'flex', marginBottom: 10, fontSize: 14, color: '#212529', fontWeight: 'bold' }}>
        {currentTime !== 0 ? (
          <span>Ви зможете відправити код заново через {currentTime} секунд</span>
        ) : (
          <span onClick={resendSms} style={{ cursor: 'pointer' }}>
            Відправити СМС заново
          </span>
        )}
      </div> */}
      <Button
        type="submit"
        variant="primary"
        disabled={submitButtonDisabled}
        onClick={onSubmit}
        className="submit-button"
      >
        Підтверджую
      </Button>
    </form>
  );
};

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = { sendSms, login };

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

export default enhance(Code);
