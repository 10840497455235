import React from 'react';
import { connect } from 'react-redux';

import { fetchPatients } from '../../../../../actions/patientsActions';
import { syncBot, updateCity } from '../../../../../actions';
import DoctorsPageList from '../../../../list/doctorsPageList';

import {
  doctorScheduleDateRangeResize,
  getDoctors,
  getDoctorsByDaysSchedule,
  getDoctorSchedule,
  setCalendarDate,
} from '../../../../../actions/doctors';
import useDoctorScheduleDateRangeResize from '../../../../../hooks/useDoctorScheduleDateRangeResize';

const DoctorsList = ({
  doctor,
  doctors,
  doctorSchedule,
  doctorsByDaysSchedule,
  getDoctors,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  getPatients,
  user,
  syncBot,
  currentDoctorsOrderBy,
  setCalendarDate,
  currentDivisionId,
  placeholder,
  isOperator = false,
  websiteCovidSource = null,
}) => {
  useDoctorScheduleDateRangeResize({ datesRange: doctorSchedule.datesRange, doctorScheduleDateRangeResize });

  return (
    <DoctorsPageList
      websiteCovidSource={websiteCovidSource}
      isOperator={isOperator}
      currentDivisionId={currentDivisionId}
      doctor={doctor}
      doctors={doctors}
      doctorSchedule={doctorSchedule}
      doctorsByDaysSchedule={doctorsByDaysSchedule}
      getDoctors={getDoctors}
      getDoctorSchedule={getDoctorSchedule}
      getDoctorsByDaysSchedule={getDoctorsByDaysSchedule}
      getPatients={getPatients}
      user={user}
      city={user.city}
      syncBot={syncBot}
      currentDoctorsOrderBy={currentDoctorsOrderBy}
      setCalendarDate={setCalendarDate}
      placeholder={placeholder}
      disableOwnLink
      isVaccinates
    />
  );
};

const mapStateToProps = ({ doctor, doctors, doctorSchedule, doctorsByDaysSchedule, user }) => ({
  doctor,
  doctors,
  doctorSchedule,
  user,
  doctorsByDaysSchedule,
});

const mapDispatchToProps = {
  getDoctors,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  setCalendarDate,
  getPatients: fetchPatients,
  syncBot,
  updateCity,
  doctorScheduleDateRangeResize,
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsList);
