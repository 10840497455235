import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import MapLink from './mapLink';
import { DetailedDescription } from './detailedDescription';

export default function Description({
  full_name,
  institution_full_name,
  address,
  mapUrl,
  externalMapUrl,
  divisionId,
  currentDivision,
  institutionId,
  description,
  city,
  disableOwnLink,
  isVaccination,
  isSmallVaccinationCenter,
  myAmbulatoryNote,
  closestAvailableTime,
}) {
  const hospitalLink = `/city/${city?.id}/hospitals/${institutionId}${divisionId ? `?division_id=${divisionId}` : ''}`;
  const vaccinationAmbulatoryLink = `/city/${city?.id}/vaccinations/ambulatory/?hospital_id=${institutionId}&division_id=${divisionId}`;

  const vaccinationAmbulatoryWithDateLink = useMemo(() => {
    if (!isVaccination || !closestAvailableTime) return '';

    const date = new Date(closestAvailableTime);

    return `${vaccinationAmbulatoryLink}&date=${date.getFullYear()}-${date.getMonth() + 1}-${`0${date.getDate()}`.slice(
      -2,
    )}`;
  }, [closestAvailableTime, isVaccination, vaccinationAmbulatoryLink]);

  const closestAvailableTimeString = useMemo(() => {
    if (!isVaccination || !closestAvailableTime) return '';

    return moment(closestAvailableTime).format('DD.MM HH:mm');
  }, [closestAvailableTime, isVaccination]);

  return (
    <div className="institution-about__description">
      <div className="institution-about__description-title">
        {disableOwnLink ? (
          full_name
        ) : (
          <Link to={isVaccination ? vaccinationAmbulatoryLink : hospitalLink}>{full_name}</Link>
        )}
        {isSmallVaccinationCenter && (
          <div>
            <span className="badge badge-warning">Центр вакцинації</span>
          </div>
        )}
      </div>
      {currentDivision && (
        <div className="institution-about__description-direction">
          <i className="fas fa-hospital" />
          {currentDivision.full_name}
        </div>
      )}
      <div className="institution-about__description-appellation">{institution_full_name}</div>
      <div className="institution-about__description-direction">
        <MapLink
          mapUrl={mapUrl}
          externalMapUrl={externalMapUrl}
          text={address.display_text}
          showMapUrl={address.lat && address.lon}
        />
      </div>

      {isVaccination && closestAvailableTime && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link to={vaccinationAmbulatoryWithDateLink} className="institution-about__link">
          Найближчий прийом: <span>{closestAvailableTimeString}</span> <i className="fas fa-chevron-right" />
        </Link>
      )}

      <DetailedDescription description={description} />
      {myAmbulatoryNote && (
        <div className="my-ambulatory-note">
          <i className="fas fa-info-circle" />
          Ваша амбулаторія
        </div>
      )}
    </div>
  );
}
