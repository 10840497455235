import React from 'react';
import { NavLink } from 'react-router-dom';

export function ShowAllDoctorsLink({ term, user }) {
  return (
    <div className="search-field-dropdown-result-content-item">
      <div className="search-field-dropdown-result-content-item-all">
        <div className="search-field-dropdown-result-content-item-all-icon">
          <i className="fas fa-users" />
        </div>
        <NavLink
          to={`/city/${user.city?.id}/list?name=${term}#doctors`}
          className="search-field-dropdown-result-content-item-link"
        >
          Показати всіх лікарів за запитом <i className="fas fa-chevron-right" />
        </NavLink>
      </div>
    </div>
  );
}
