import React from 'react';

import EmptyData from '../../emptyData';
import { Doctors } from './doctors';
import { Divisions } from './divisions';
import { Specializations } from './specializations';

export default function SearchResults({ search, user }) {
  if (!search.loaded) return null;

  if (!search.dataExists) {
    return <EmptyData />;
  }

  return (
    <div className="search-field-dropdown-result">
      <Specializations specializations={search.data.specializations} user={user} />
      <Divisions divisions={search.data.divisions} term={search.term} user={user} />
      <Doctors doctors={search.data.doctors} term={search.term} user={user} />
    </div>
  );
}
