import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import VisitsList from './visitsList';
import Visit from './visit';
import TextLoader from '../../../components/global/Loader/text';
import Loader from '../../../components/global/Loader';
import EmptyData from '../../../components/global/emptyData';
import { getPatientVisits } from '../../../../actions/patientsActions';
import Pagination from '../../../components/global/List/Pagination';
import { useUrlParser } from '../../../../utils/useUrlParser';

const VisitsComponent = ({ patient, patientVisits, getPatientVisits, user }) => {
  const { params } = useRouteMatch();
  const history = useHistory();
  const [currentPatientId, setCurrentPatientId] = useState();
  const { urlData } = useUrlParser();
  
  useEffect(() => {
    if (
      (patient.data && !patientVisits.loading && !patientVisits.loaded) ||
      (patient.data && currentPatientId && currentPatientId !== patient.data.id)
    ) {
      setCurrentPatientId(patient.data.id);
      const page =
        +urlData.params.page > 0 && Number.isInteger(+urlData.params.page) ? +urlData.params.page : 1;
      getPatientVisits(patient.data.id, page);
    }
  }, [currentPatientId, getPatientVisits, patient.data, patientVisits.loaded, patientVisits.loading, urlData.params.page]);

  useEffect(() => {
    if (params.id && currentPatientId && patient.data?.id !== currentPatientId) history.push('/office/visits');
  }, [currentPatientId, history, params.id, patient.data]);

  const visit = useMemo(
    () => (patientVisits.data?.length ? patientVisits.data.find((visit) => visit.id === +params.id) : null),
    [params.id, patientVisits.data],
  );

  if (!patientVisits.data) {
    return <TextLoader />;
  }

  if (patientVisits.data.length === 0 || (params.id && !visit)) {
    return <EmptyData text={params.id ? 'Візит не знайдено' : null} />;
  }

  return (
    <div className="personal-visits-wrapper extended">
      <div className="personal-visits">
        <ul className="personal-visits-list">
          {params.id ? <Visit visit={visit} user={user} /> : <VisitsList visits={patientVisits.data} user={user} />}
        </ul>
      </div>
      <Loader isVisible={patientVisits.loading || patient.loading} />
      { params.id ? '' : (
        <Pagination
          currentPage={patientVisits.pagination.current_page}
          totalPages={patientVisits.pagination.total_pages}
          pageName="page"
        />
      ) }
    </div>
  );
};

const mapStateToProps = ({ patientVisits }) => ({ patientVisits });

const mapDispatchToProps = { getPatientVisits };

const VisitsContainer = connect(mapStateToProps, mapDispatchToProps)(VisitsComponent);

export default VisitsContainer;
