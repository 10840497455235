import { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useUrlParser } from '../../../../utils/useUrlParser';

export function useSearchFormApi({ user, specializations, onSearch, getSpecializations, search, searchFocusedToggle }) {
  const [open, setOpen] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [value, setValue] = useState('');
  const [currentSearch, setCurrentSearch] = useState();
  const [currentLocation, setCurrentLocation] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { urlData, removeParam } = useUrlParser();
  const fieldRef = useRef();
  const formRef = useRef();

  useEffect(() => {
    if (user.city?.id && urlData.path !== '/' && urlData.params.specialization_id) {
      getSpecializations({ cityId: user.city.id });
    }
  }, [getSpecializations, urlData, user.city]);

  useEffect(() => {
    if (urlData.path === '/' && urlData.params.search === '') {
      setOpen(true);

      if (fieldRef.current) fieldRef.current.focus();
    }
  }, [urlData.params.search, urlData.path]);

  useEffect(() => {
    if (urlData.path !== '/' && currentLocation?.key !== location.key) {
      setOpen(false);
    }
  }, [currentLocation, location.key, urlData.path]);

  useEffect(() => {
    if (search.focused !== open) searchFocusedToggle({ focused: open });
  }, [open, search.focused, searchFocusedToggle]);

  useEffect(() => {
    if (
      urlData.path === `/city/${user.city?.id}/list` &&
      urlData.params.specialization_id &&
      specializations.data.length
    ) {
      setCurrentSearch(
        specializations.data.find((specialization) => specialization.id === +urlData.params.specialization_id)?.name,
      );

      return;
    }

    if (urlData.path === `/city/${user.city?.id}/list` && urlData.params.name) {
      setCurrentSearch(urlData.params.name);
    }
  }, [specializations.data, urlData, user.city]);

  const onClosePanel = () => setOpen(false);

  const onOpenPanel = useCallback(() => {
    if (isMobile) {
      history.push('/?search=');
      return;
    }

    setOpen(true);
  }, [history, isMobile]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768 && !isMobile) setIsMobile(true);
      if (window.innerWidth > 768 && isMobile) setIsMobile(false);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  });

  const onClearSearchValue = () => {
    setValue('');
    onClosePanel();
    onSearch({ value: '' });
  };

  const onChange = (e) => {
    setValue(e.target.value);

    onSearch({ cityId: user.city.id, value: e.target.value });

    if (!open) setOpen(true);
  };

  const clearCurrentSearch = useCallback(() => {
    setCurrentSearch(null);
    setValue('');

    history.push(removeParam(['specialization_id', 'name', 'doctors_page', 'divisions_page']).urlData.to);
  }, [history, removeParam]);

  const handleClick = () => {
    if (search.term) onOpenPanel();
  };

  const onRouteChange = useCallback(() => {
    if (open) setOpen(false);
    if (inputFocused) setInputFocused(false);
    if (search.term !== '') onSearch({ value: '' });
  }, [inputFocused, onSearch, open, search.term]);

  const onInputFocus = () => {
    if (!value.length) setInputFocused(true);
    setOpen(true);
  };

  useEffect(() => {
    if (currentLocation?.pathname !== location.pathname) {
      onRouteChange();
      setCurrentLocation(location);
    }
  }, [currentLocation, location, onRouteChange]);

  useEffect(() => {
    if (!search.term.length && !inputFocused) setInputFocused(true);
    if (search.term.length && inputFocused) setInputFocused(false);
  }, [inputFocused, search.term.length]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        !open ||
        !formRef.current ||
        (fieldRef.current && document.activeElement?.id === fieldRef.current.id) ||
        formRef.current.contains(event.target)
      )
        return;

      setOpen(false);

      if (inputFocused) setInputFocused(false);
    },
    [inputFocused, open],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return {
    open,
    currentSearch,
    fieldRef,
    onClosePanel,
    onChange,
    clearCurrentSearch,
    handleClick,
    onOpenPanel,
    onClearSearchValue,
    value,
    inputFocused,
    onInputFocus,
    formRef,
  };
}
