import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReactGA from 'react-ga';
import moment from 'moment';

import { getSettings, getCities, getInitialCity, getDistricts } from '../actions';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  debug: process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG === 'true',
  testMode: process.env.REACT_APP_ENV.toLowerCase() === 'local',
});

const globalHOC = (Component) => (props) => {
  const { user, getSettings, getCities, getInitialCity, getDistricts, ...rest } = props;
  useEffect(() => {
    const url = window.location.pathname + window.location.search + window.location.hash;

    ReactGA.pageview(url);
  }, []);

  const currentDate = moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (!user.settingsLoaded || currentDate !== moment(user.datetime).format('YYYY-MM-DD')) {
      getSettings();
    }
  }, [currentDate, getSettings, user.datetime, user.settingsLoaded]);

  useEffect(() => {
    if (!user.citiesLoaded) getCities();
  }, [getCities, user.citiesLoaded]);

  useEffect(() => {
    if (user.settingsLoaded && user.citiesLoaded && !user.city) getInitialCity();
  }, [getInitialCity, user.citiesLoaded, user.city, user.settingsLoaded]);

  useEffect(() => {
    if (user.citiesLoaded && user.city && !user.district && !user.districtsLoaded) {
      getDistricts(user.city.id);
    }
  }, [getDistricts, user.citiesLoaded, user.city, user.district, user.districtsLoaded]);

  const enableRoutes = user.settingsLoaded && user.citiesLoaded && (user.city || user.initialCityLoaded);

  return enableRoutes ? <Component {...rest} /> : null;
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = { getSettings, getCities, getInitialCity, getDistricts };

const composedGlobalHOC = compose(connect(mapStateToProps, mapDispatchToProps), globalHOC);

export default composedGlobalHOC;
