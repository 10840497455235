import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

import { getPatientsExaminations } from '../../../../api';
import TextLoader from '../../../components/global/Loader/text';
import EmptyData from '../../../components/global/emptyData';

const PersonalReview = ({ patient }) => {
  const [examinations, setExaminations] = useState();

  useEffect(() => {
    if (patient.data) {
      getPatientsExaminations(patient.data.id).then(response => {
        const exams = response.data.reduce((acc, item) => {
          if (acc[item.exam_type_id]) {
            acc[item.exam_type_id] = {
              id: item.exam_type_id,
              name: item.exam_type,
              data: [...acc[item.exam_type_id].data, item],
            };
          } else {
            acc[item.exam_type_id] = {
              id: item.exam_type_id,
              name: item.exam_type,
              data: [item],
            };
          }

          return acc;
        }, {});

        setExaminations(exams);
      });
    }
  }, [patient.data]);

  if (!examinations) return <TextLoader />;

  if (!Object.keys(examinations).length) return <EmptyData />;

  return (
    <>
      <div className="personal-review-wrapper">
        {Object.keys(examinations)
          .map(examId => examinations[examId])
          .map(({ id, name, data }) => (
            <div className="personal-review" key={id}>
              <div className="personal-review-title">{name}</div>
              <ul className="personal-review-list">
                <li className="personal-review-list__item">
                  <div className="personal-review-list__item-date">Дата</div>
                  <div className="personal-review-list__item-boolean">Паталогiя</div>
                  <div className="personal-review-list__item-description">Коментар</div>
                </li>
                {data.map(({ comment, date, id, pathology }) => (
                  <Fragment key={id}>
                    <li className="personal-review-list__item personal-review-list__item-mobile">
                      <div className="personal-review-list__item-date">
                        <i className="far fa-calendar-alt" /> {moment(date).format('DD.MM.YYYY')}
                      </div>
                      <div className="personal-review-list__item-boolean">
                        Паталогiя
                        <strong>{pathology ? 'Так' : 'Нi'}</strong>
                      </div>
                      <div className="personal-review-list__item-description">
                        Коментар
                        <strong>{comment || '-'}</strong>
                      </div>
                    </li>

                    <li className="personal-review-list__item">
                      <div className="personal-review-list__item-date">{moment(date).format('DD.MM.YYYY')}</div>
                      <div className="personal-review-list__item-boolean">{pathology ? 'Так' : 'Нi'}</div>
                      <div className="personal-review-list__item-description">{comment || '-'}</div>
                    </li>
                  </Fragment>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </>
  );
};

export default PersonalReview;
