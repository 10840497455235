import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';

import SearchResults from './results';
import { search, searchFocusedToggle } from '../../../../actions';
import { getSpecializations } from '../../../../actions/doctors';

import { useSearchFormApi } from './api';

const StyledToggle = styled.div`
  top: 65px;
  left: 0;
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(9, 79, 163, 0.1);
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  z-index: 10000;
  @media (max-width: 767px) {
    width: calc(100% + 54px);
  }
  @media (min-width: 768px) {
    max-height: 300px;
    overflow: auto;
  }
  ${({ open, dataExists }) =>
    open && dataExists &&
    `
    display: block;
  `}
`;

const SearchForm = ({ onSearch, search, user, specializations, getSpecializations, searchFocusedToggle }) => {
  const {
    open,
    currentSearch,
    fieldRef,
    onClearSearchValue,
    onChange,
    clearCurrentSearch,
    handleClick,
    onOpenPanel,
    value,
    inputFocused,
    onInputFocus,
    formRef,
  } = useSearchFormApi({ user, specializations, onSearch, getSpecializations, search, searchFocusedToggle });

  return (
    <div className="search-field-form" ref={formRef}>
      <div className="search-field-wrapper">
        {currentSearch ? (
          <div className="search-field-specialty">
            <span>{currentSearch}</span>
            <div className="close" onClick={clearCurrentSearch}>
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
        ) : (
          <DebounceInput
            inputRef={fieldRef}
            debounceTimeout={300}
            onChange={onChange}
            onClick={handleClick}
            onFocus={onInputFocus}
            value={value}
            autoComplete="off"
            type="text"
            id="searchField"
            className="search-field"
            name="input_search"
            placeholder="Поле пошуку: введіть назву медичного закладу чи прізвище лікаря чи спеціальність лікаря"
          />
        )}

        <StyledToggle open={open} dataExists={search.dataExists}>
          <div className="search-field-dropdown">
            {<SearchResults search={search} user={user} />}

            {false && inputFocused && (
              <div className="search-field-dropdown-focus">
                <div className="search-field-dropdown-focus-icon" />
                <div className="search-field-dropdown-focus-description">
                  Почніть вводити прізвіще лікаря, назву медичного закладу чи спеціальність
                </div>
              </div>
            )}
          </div>
        </StyledToggle>

        {!!value.length && (
          <Button className="search-field-btn-close" onClick={onClearSearchValue}>
            <i className="fas fa-times" />
          </Button>
        )}
      </div>
      <Button className="search-field-btn" onClick={onOpenPanel}>
        <i className="fas fa-search" />
      </Button>
    </div>
  );
};

const mapStateToProps = ({ user, search, specializations }) => ({ user, search, specializations });

const mapDispatchToProps = { onSearch: search, getSpecializations, searchFocusedToggle };

const SearchFormContainer = connect(mapStateToProps, mapDispatchToProps)(SearchForm);

export default SearchFormContainer;
