import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'react-bootstrap';

import ModalConfirmation from './modalConfirmation';
import defaultMedicSvg from '../../../../assets/img/next/svg/medic.svg';

export default function Doctor(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const {
    cabinet_num,
    cancelable,
    cancelable_comment,
    cancelAppointment,
    division,
    doctor,
    id,
    institution,
    time_from,
    work_date,
    isPast,
    city,
  } = props;

  const onConfirmDelete = (id) => {
    handleClose();
    cancelAppointment(id);
  };

  return (
    <div className="streaming-records-item">
      <ModalConfirmation isOpen={open} onClose={handleClose} onConfirm={onConfirmDelete} id={id} />
      <div className="streaming-records-item__information">
        <div className="streaming-records-item__user">
          <NavLink
            to={`/city/${city?.id}/doctors/${doctor.id}`}
            className="streaming-records-item__user-icon"
            style={{ backgroundImage: `url(${doctor.photo ?? defaultMedicSvg})` }}
          />
          <div className="streaming-records-item__user-about">
            <NavLink to={`/city/${city?.id}/doctors/${doctor.id}`} className="streaming-records-item__user-title">
              <div className="streaming-records-item__user-title">{doctor.full_name}</div>
            </NavLink>

            <div className="streaming-records-item__user-description">
              {doctor.specializations.map(({ id, name }, index) => (
                <Fragment key={id}>
                  {name.toUpperCase()}
                  {doctor.specializations.length > index + 1 ? ', ' : ''}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="streaming-records-item__address">
          <ul className="streaming-records-item__address-list">
            <li className="streaming-records-item__address-list-item">
              <div className="streaming-records-item__address-list-item-title">Адреса</div>
              <div className="streaming-records-item__address-list-item-description">
                {division.address ? division.address.display_text : ' - '}
              </div>
            </li>
            <li className="streaming-records-item__address-list-item">
              <div className="streaming-records-item__address-list-item-title">Кабінет</div>
              <div className="streaming-records-item__address-list-item-description">
                <strong>{cabinet_num || '-'}</strong>
              </div>
            </li>
            <li className="streaming-records-item__address-list-item">
              <div className="streaming-records-item__address-list-item-title">Заклад</div>
              <div className="streaming-records-item__address-list-item-description">
                <NavLink to={`/city/${city?.id}/hospitals/${institution.id}?division_id=${division.id}`}>
                  {division.full_name}
                </NavLink>
              </div>
            </li>
            {!!division.contacts?.length && division.contacts.find(({ type }) => type === 'phone') && (
              <li className="streaming-records-item__address-list-item">
                <div className="streaming-records-item__address-list-item-title">Телефон реєстратури</div>
                <div className="streaming-records-item__address-list-item-description">
                  {division.contacts.reduce(
                    (acc, contact) =>
                      contact.type === 'phone'
                        ? `${acc.length ? `${acc}, ${contact.value}` : `${contact.value}`}`
                        : acc,
                    '',
                  )}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>

      <div className="streaming-records-item__period">
        <div className="streaming-records-item__period-date">
          <strong>{time_from}</strong>
          {moment(work_date).format('DD.MM.YYYY')}
        </div>
        {!isPast && (
          <Button
            className="streaming-records-item__period-action"
            onClick={handleOpen}
            disabled={!cancelable}
            title={cancelable_comment}
          >
            <i className="fas fa-ban" />
            Скасувати прийом
          </Button>
        )}
      </div>
    </div>
  );
}
