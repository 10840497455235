import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

export default function PreviousPage({ disabled, currentPage, urlParser, pageName, setPageHandler }) {
  const Component = disabled || !!setPageHandler ? 'span' : NavLink;
  const to = disabled || !!setPageHandler ? null : urlParser.calcNewPaginationTo(pageName, currentPage - 1);

  const setPage = useCallback(
    (page) => () => {
      if(setPageHandler) setPageHandler(page);
    },
    [setPageHandler],
  );

  return (
    <li className={`page-item${disabled ? ' disabled' : ''}`} onClick={setPage(currentPage-1)}>
      <Component className="page-link" to={disabled ? null : to}>
        <span aria-hidden="true">&lt;</span>
        <span className="sr-only">Попередня сторінка</span>
      </Component>
    </li>
  );
}
