import React from 'react';

export default function Contacts({ address, phones }) {
  const addressExists = !!(address?.lat && address?.lon);

  const Component = addressExists ? 'a' : 'span';

  return (
    <div className="institution-info__indicator-item">
      <div className="institution-info__indicator-title">Контакти</div>
      <ul className="institution-info__indicator-list">
        {address?.display_text && (
          <li className="institution-info__indicator-list-item">
            <Component
              href={addressExists ? `https://maps.google.com/maps/place/${address.lat},${address.lon}` : null}
              target={addressExists ? '_blank' : null}
              rel={addressExists ? 'noopener noreferrer' : null}
              className={`institution-info__indicator-list-description link${!addressExists ? ' span' : ''}`}
            >
              <i className="fas fa-map-marker-alt" />
              {address.display_text}
            </Component>
          </li>
        )}

        {!!phones.length && (
          <div className="institution-info__phones-wrapper">
            <i className="fas fa-phone-alt" />
            <div>
              {phones.map((phone, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`${index}_${phone}`} className="institution-info__indicator-list-item">
                  {phone.value}
                </li>
              ))}
            </div>
          </div>
        )}

        {!phones.length && !addressExists && (
          <li className="institution-info__indicator-list-item">
            <div className="institution-info__indicator-list-description empty">Немає даних</div>
          </li>
        )}
      </ul>
    </div>
  );
}
