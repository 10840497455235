import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import RecipesList from '../Visits/resipesList';
import TextLoader from '../../../components/global/Loader/text';
import EmptyData from '../../../components/global/emptyData';
import BuyRecipes from '../../common/buyRecipes';
import { getPatientVisits } from '../../../../api';

const PersonalRecipes = ({ patient }) => {
  const [visits, setVisits] = useState(null);

  useEffect(() => {
    if (patient.data) {
      getPatientVisits(patient.data.id).then((response) => {
        setVisits(response.data);
      });
    }
  }, [patient.data]);

  if (!visits) return <TextLoader />;

  if (visits.length === 0 || (visits.length === 1 && !visits[0].drugs.length)) return <EmptyData />;

  return (
    <div className="personal-recipes-wrapper">
      <div className="personal-recipes">
        <div className="personal-recipes-title">Призначені ліки</div>

        <ul className="personal-recipes-data-list">
          {visits.map(({ id, date, drugs, buy_url }) =>
            drugs.length ? (
              <li className="personal-recipes-data-list__item" key={id}>
                <div className="personal-recipes-data-list__item-information">
                  <div className="personal-recipes-data-list__item-date">
                    <div className="personal-recipes-data-list__item-date-title">Дата візиту</div>
                    <div className="personal-recipes-data-list__item-date-description">
                      <i className="fas fa-calendar-alt" /> {moment(date).format('DD.MM.YYYY')}
                    </div>
                  </div>

                  <NavLink to={`/office/visits/${id}`} className="personal-recipes-data-list__item-link">
                    До візиту
                  </NavLink>
                </div>

                <RecipesList drugs={drugs} />

                <BuyRecipes buUrl={buy_url} />
              </li>
            ) : null,
          )}
        </ul>
      </div>
    </div>
  );
};

export default PersonalRecipes;
