import { useCallback, useEffect } from 'react';

import { useUrlParser } from '../../../utils/useUrlParser';

export function useDoctorsPageListApi({
  doctors,
  getDoctors,
  city,
  currentDoctorsOrderBy,
  currentDivisionId,
  isVaccinates = false,
}) {
  const urlParser = useUrlParser();

  const fetchDoctors = useCallback(() => {
    getDoctors({
      cityId: city.id,
      page:
        +urlParser.urlData.params.doctors_page > 0 && Number.isInteger(+urlParser.urlData.params.doctors_page)
          ? +urlParser.urlData.params.doctors_page
          : 1,
      name: urlParser.urlData.params.name,
      specializationId: urlParser.urlData.params.specialization_id,
      orderBy: currentDoctorsOrderBy,
      isVaccinates,
      divisionId: urlParser.urlData.params.division_id,
    });
  }, [
    city.id,
    currentDoctorsOrderBy,
    getDoctors,
    isVaccinates,
    urlParser.urlData.params.division_id,
    urlParser.urlData.params.doctors_page,
    urlParser.urlData.params.name,
    urlParser.urlData.params.specialization_id,
  ]);

  useEffect(() => {
    if (
      !currentDivisionId &&
      city?.id &&
      !doctors.loading &&
      (!doctors.loaded || (doctors.orderBy && doctors.orderBy.key !== currentDoctorsOrderBy?.key))
    ) {
      fetchDoctors();
    }
  }, [currentDivisionId, city, currentDoctorsOrderBy, doctors.loaded, doctors.loading, doctors.orderBy, fetchDoctors]);

  useEffect(() => {
    if (currentDivisionId) {
      fetchDoctors();
    }
  }, [currentDivisionId, fetchDoctors]);

  useEffect(() => {
    if (doctors.loaded) window.scrollTo(0, 0);
  }, [doctors.loaded]);

  return {};
}

export function useDoctorScheduleApi({
  doctorSchedule,
  doctorsByDaysSchedule,
  doctors,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  city,
  isVaccinates,
}) {
  useEffect(() => {
    if (doctors.data.length && city?.settings?.show_map && !doctorSchedule.loading && !doctorSchedule.loaded) {
      const ids = doctors.data.map((doctor) => doctor.id).join(',');
      const dates = {
        date_from: doctorSchedule.initialDateFrom,
        date_to: doctorSchedule.initialDateTo,
      };

      getDoctorSchedule({ ...dates, ids, isVaccinates });
    }
  }, [getDoctorSchedule, doctors.data, doctorSchedule, city, isVaccinates]);

  useEffect(() => {
    if (
      doctors.data.length &&
      city?.settings?.show_map &&
      !doctorsByDaysSchedule.loading &&
      !doctorsByDaysSchedule.loaded
    ) {
      const ids = doctors.data.map((doctor) => doctor.id).join(',');
      const dates = {
        date_from: doctorSchedule.initialDateFrom,
        date_to: doctorSchedule.initialDateTo,
      };

      getDoctorsByDaysSchedule({ ...dates, ids });
    }
  }, [doctors.data, doctorSchedule, city, getDoctorsByDaysSchedule, doctorsByDaysSchedule]);
}

export function usePatientsApi({ getPatients, token }) {
  useEffect(() => {
    if (token) getPatients();
  }, [getPatients, token]);
}
