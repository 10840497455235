import { connect } from 'react-redux';

import { HospitalPageComponent } from '../next/hospitalPage';
import { getHospital } from '../actions/hospitals';
import { updateCity } from '../actions';

const mapDispatchToProps = { getHospital, updateCity };

const mapStateToProps = ({ hospital, user }) => ({
  hospital,
  user,
});

export default connect(mapStateToProps, mapDispatchToProps)(HospitalPageComponent);
