import * as TYPES from '../constants/types';

const initialState = {
  loading: false,
  error: null,
  authorized: false,
  phone: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.CLEAR_AUTH_STATE:
      return initialState;

    case TYPES.SEND_SMS_REQUEST:
      return {
        error: null,
        loading: true,
      };

    case TYPES.SEND_SMS_SUCCESS:
      return {
        loading: false,
        error: null,
        phone: action.payload
      };

    case TYPES.SEND_SMS_FAILURE: {
      return {
        loading: false,
        error: action.payload.error,
        phone: '',
      };
    }

    case TYPES.LOGIN_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case TYPES.LOGIN_SUCCESS:
      return initialState;

    case TYPES.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
