import React from 'react';
import { NavLink } from 'react-router-dom';

import defaultMedicSvg from '../../../../../assets/img/next/svg/medic.svg';
import AppointmentsCalendarModal from './appointmentsCalendarModal';
import RatingBar from '../../../../components/global/List/RatingBar';
import Appointment from '../../../../../containers/Appointment';

import Photo from './photo';
import Specializations from './specializations';
import Jobs from './jobs';
import DesktopSchedule from './schedule/DesktopSchedule';
import MobileSchedule from './schedule/MobileSchedule';
import useDoctorApi from '../../../../doctorPage/common/Doctor/api';

export default function Doctor({
  doctorSchedule,
  doctor,
  full_name,
  indicators,
  jobs,
  photo,
  rating,
  currentDoctorSchedule,
  byDaysSchedule,
  specializations,
  city,
  setCalendarDate,
  isVaccinates = false,
  isOperator = false,
  disableOwnLink = false,
  websiteCovidSource = null,
}) {
  const {
    timeSlot,
    setTimeSlot,
    isVisibleAppointmentsCalendar,
    setIsVisibleAppointmentsCalendar,
    isDirect,
  } = useDoctorApi({ jobs });

  const handleLinkClick = (e) => {
    if (disableOwnLink) e.preventDefault();
  };

  return (
    <div className="feature-card__item">
      <Appointment
        isOperator={isOperator}
        isVaccinates={isVaccinates}
        websiteCovidSource={websiteCovidSource}
        doctor={doctor}
        timeSlot={timeSlot}
        isOpen={!!timeSlot}
        onClose={() => setTimeSlot(null)}
      />
      <div className="medic-feature-profile">
        <div className="medic-feature-profile-status">
          <Photo photo={photo} defaultPhoto={defaultMedicSvg} />
          <div className="medic-feature-profile-status-services">
            <RatingBar rating={rating} indicators={indicators} full_name={full_name} />
          </div>
        </div>

        <div className="medic-feature-profile-information">
          <div className="medic-feature-profile-information__title">
            <NavLink
              to={`/city/${city?.id}/doctors/${doctor.id}`}
              disabled={disableOwnLink}
              onClick={handleLinkClick}
              className={`${disableOwnLink ? 'disabled' : ''}`}
            >
              {full_name}
            </NavLink>
          </div>
          <Specializations specializations={specializations} />
          <Jobs
            doctor={doctor}
            jobs={jobs}
            city={city}
            currentDoctorSchedule={currentDoctorSchedule?.schedule ?? []}
            showAppointmentsCalendar={() => setIsVisibleAppointmentsCalendar(true)}
          />

          {!!city?.settings?.allow_timeslots && (
            <MobileSchedule
              onOpenAppointment={setTimeSlot}
              doctorSchedule={doctorSchedule}
              currentDoctorSchedule={currentDoctorSchedule}
              setCalendarDate={setCalendarDate}
              loaded={doctorSchedule.loaded}
              isDirect={isDirect}
            />
          )}
        </div>
      </div>
      {Boolean(city?.settings?.allow_timeslots) && (
        <DesktopSchedule
          onOpenAppointment={setTimeSlot}
          datesRange={doctorSchedule.datesRange}
          loaded={doctorSchedule.loaded}
          loading={doctorSchedule.loading}
          currentDoctorSchedule={currentDoctorSchedule}
          isDirect={isDirect}
        />
      )}

      <AppointmentsCalendarModal
        doctor={doctor}
        byDaysSchedule={byDaysSchedule}
        show={isVisibleAppointmentsCalendar}
        onClose={() => setIsVisibleAppointmentsCalendar(false)}
      />
    </div>
  );
}
