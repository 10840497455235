import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DistrictDropdown from '../../components/dropDown';

export default function HeadRow({ districts, district, updateDistrict, allDistrictSelected, setAllDistrictSelected }) {
  const handleClickAllDistricts = () => {
    setAllDistrictSelected(true);
    updateDistrict();
  };

  return (
    <Row className="d-flex align-items-center mb-md-4 mb-sm-2">
      <Col md={5}>
        <div className="title">
          {/* &nbsp; - for better break text on the tablets */}
          Пошук&nbsp;центру&nbsp;вакцинації по&nbsp;районам&nbsp;міста
        </div>
      </Col>

      <Col md={{ span: 5, offset: 2 }} lg={{ span: 4, offset: 3 }}>
        <DistrictDropdown
          district={district}
          districts={districts}
          allDistrictSelected={allDistrictSelected}
          handleClickAllDistricts={handleClickAllDistricts}
          updateDistrict={updateDistrict}
        />
      </Col>
    </Row>
  );
}
