import { useMemo, useState } from 'react';
import moment from 'moment';

const transformSchedule = (schedule, doctor) =>
  schedule
    ? schedule.reduce((acc, timeSlot) => {
        const timeFrom = moment(timeSlot.time_from);
        const timeTo = moment(timeSlot.time_to);
        const day = timeFrom.format('YYYY-MM-DD');
        const newTimeSlot = {
          ...timeSlot,
          time_from: timeFrom.format('HH:mm'),
          time_to: timeTo.format('HH:mm'),
          job: doctor.jobs.find(job => job.id === timeSlot.job_id),
        };
        const data = (acc[day] ?? []).concat(newTimeSlot);

        return { ...acc, [day]: data };
      }, {})
    : {};

const generateWeeksRange = (schedule, doctor) => {
  const transformedSchedule = transformSchedule(schedule, doctor);
  const start = moment().startOf('isoWeek');
  const threeWeeks = new Array(3).fill('');

  return threeWeeks.map(() => {
    const range = {};
    let weekDay = 1;

    while (weekDay <= 7) {
      const date = start.format('YYYY-MM-DD');

      range[date] = transformedSchedule[date] ?? [];
      start.add(1, 'day');
      weekDay += 1;
    }

    return range;
  });
};

export function useAppointmentsCalendarModalApi({ byDaysSchedule, doctor }) {
  const [dayData, setDayData] = useState();
  const [subModalIsVisible, setSubModalIsVisible] = useState(false);
  const weeksRange = useMemo(() => generateWeeksRange(byDaysSchedule.schedule, doctor), [
    byDaysSchedule.schedule,
    doctor,
  ]);
  const now = useMemo(() => moment().startOf('day'), []);

  return {
    dayData,
    setDayData,
    subModalIsVisible,
    setSubModalIsVisible,
    weeksRange,
    now,
  };
}
