import React, { useMemo } from 'react';
import moment from 'moment';

import { getOnlyFreeDoctorSchedule } from '../../../../../../../utils/getOnlyFreeDoctorSchedule';
import ScheduleHeader from '../../../../../../components/global/List/ScheduleHeader';
import useMoveDoctorScheduleRange from '../../../../../../../hooks/useMoveDoctorScheduleRange';
import { ScheduleBody } from './body';
import MobileEmptySchedule from './empty';

import { ReactComponent as ScheduleInfoSvg } from '../../../../../../../assets/img/schedule-info.svg';

export default function MobileSchedule({
  onOpenAppointment,
  currentDoctorSchedule,
  loaded,
  doctorSchedule,
  setCalendarDate,
  isDoctorPage = false,
  isDirect,
}) {
  const { moveDatesRangeLeft, moveDatesRangeRight, currentDatesRange } = useMoveDoctorScheduleRange({ doctorSchedule });

  const transformedSchedule = useMemo(
    () =>
      getOnlyFreeDoctorSchedule(currentDoctorSchedule?.schedule || [])[
        moment(doctorSchedule.date).format('YYYY-MM-DD')
      ],
    [doctorSchedule.date, currentDoctorSchedule],
  );

  if (!loaded) return null;

  if (loaded && (!currentDoctorSchedule?.schedule || currentDoctorSchedule?.schedule.length === 0)) {
    return <MobileEmptySchedule />;
  }

  return (
    <div className="schedule mobile">
      {isDoctorPage && (
        <div className="schedule-title">
          <ScheduleHeader
            show
            isMobile
            currentMobileDate={doctorSchedule.date}
            setCurrentMobileDate={setCalendarDate}
            mobileDatesRange={currentDatesRange}
            moveMobileDatesRangeLeft={moveDatesRangeLeft}
            moveMobileDatesRangeRight={moveDatesRangeRight}
          />
        </div>
      )}

      <div className="schedule-body schedule-body--mobile">
        <ScheduleBody transformedSchedule={transformedSchedule} onOpenAppointment={onOpenAppointment} />
        {isDirect && (
          <div className="schedule-info">
            <div className="schedule-info__icon">
              <ScheduleInfoSvg />
            </div>
            Запис можливий за направленням ЕСОЗ
          </div>
        )}
      </div>
    </div>
  );
}
