import React from 'react';
import moment from 'moment';

import { days } from '../../../../../constants';

export function Date({ date, isMobile }) {
  const today = moment(localStorage.getItem('datetime')).startOf('day');

  if (moment(date).isSame(today))
    return (
      <div className={`schedule-date-description today${isMobile ? ' mobile' : ''}`}>
        Сьогодні,&nbsp;{moment(date).format('DD')}
      </div>
    );

  return (
    <div className={`schedule-date-description${isMobile ? ' mobile' : ''}`}>
      {days[moment(date).format('dddd')].short}, <strong>{moment(date).format('DD')}</strong>
    </div>
  );
}
