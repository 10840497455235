import { trackPromise } from 'react-promise-tracker';
/* eslint-disable consistent-return */
/* eslint-disable prefer-promise-reject-errors */
import errors from './constants/errors';

const fetching = ({ url, endpoint, method, body, json = true }) => {
  const token = localStorage.getItem('token');
  const URL = url || `${process.env.REACT_APP_BASE_API_URL}${endpoint}`;

  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  return trackPromise(
    window
      .fetch(URL, {
        method,
        headers,
        body: typeof body === 'string' ? body : JSON.stringify(body),
      })
      .then((response) => {
        if (errors[response.status]) {
          return Promise.reject(response);
        }
        if (response.status === 422) {
          return response.json();
        }
        if (method === 'delete') {
          return response;
        }
        return json ? response.json() : response;
      })
      .then((response) => {
        if (response && response.errors) {
          return Promise.reject({
            errors: response.errors,
            status: 422,
          });
        }
        if (response && response.status === 422) {
          return Promise.reject({
            errors: response.message,
            status: response.status,
          });
        }

        return response;
      })
      .catch((error) => {
        if (typeof error === 'object' && error.status) {
          const { status } = error;

          if (status === 422) return Promise.reject({ error: error.errors, status });

          return Promise.reject({ error: errors[status], status });
        }

        // return Promise.reject(error);
      }),
  );
};

const post = (props) => (body = []) => fetching({ method: 'post', endpoint: props.endpoint, body, ...props });

const get = ({ endpoint }) => (query = '/') => fetching({ method: 'get', endpoint: endpoint + query });

export const authSms = post({ endpoint: '/auth/sms', json: false });

export const logout = post({ endpoint: '/auth/logout', json: false });

export const refreshToken = post({ endpoint: '/auth/refresh' });

export const login = post({ endpoint: '/auth/login' });

export const reservation = post({ endpoint: '/appointments/reservation' });

export const getEmployees = get({ endpoint: '/employees/search/full' });

export const getDoctor = get({ endpoint: '/doctor/' });

export const getHospital = get({ endpoint: `/institution/` });

export const getDivision = get({ endpoint: `/division/` });

export const getDistricts = get({ endpoint: '/dictionaries/districts' });

export const getPositions = get({ endpoint: '/dictionaries/positions' });

export const getSpecializations = get({ endpoint: '/dictionaries/specializations' });

export const getSchedule = get({ endpoint: '/employees/schedule' });

export const getSpecialities = get({ endpoint: '/dictionaries/specialities' });

export const getPatients = get({ endpoint: '/patients' });

export const getRegions = get({ endpoint: '/dictionaries/regions' });

export const getCities = get({ endpoint: '/dictionaries/cities' });

export const getInitialCity = get({ endpoint: '/dictionaries/location' });

export const getAppointments = ({ patientId, query = '' }) =>
  fetching({ method: 'get', endpoint: `/patient/${patientId}/appointments${query}` });

export const getPatient = get({ endpoint: '/patient/' });

export const getPatientVisits = (id, page) => {
  let endpoint = `/patient/${id}/visits`;
  if (page) {
    endpoint += `?page=${page}`;
  }
  return fetching({ method: 'get', endpoint })
};

export const patientsRecipes = (id) => fetching({ method: 'get', endpoint: `/patient/${id}/visit_med_position` });

export const getPatientsExaminations = (id) => fetching({ method: 'get', endpoint: `/patient/${id}/examinations` });

export const getPatientsVaccinations = (id) => fetching({ method: 'get', endpoint: `/patient/${id}/vaccinations` });

export const getPatientCode = (id, type) =>
  fetching({ method: 'get', json: false, endpoint: `/patient/${id}/${type}` });

export const cancelAppointment = (id) => fetching({ method: 'delete', endpoint: `/appointment/${id}` });

export const getLocation = ({ url }) => fetching({ method: 'get', url });

export const requestAppointment = ({ target, id, body }) =>
  fetching({
    method: 'post',
    endpoint: `/${target}/${id}/appointment`,
    body,
  });

export const searchDoctors = get({ endpoint: '/doctors/search' });

export const searchDivisions = get({ endpoint: '/divisions/search' });

export const getDivisions = ({
  cityId,
  page,
  institutionId,
  pageSize,
  specializationId,
  orderBy,
  name,
  isVaccinationPlace,
  districtId,
  eHealthUid,
}) => {
  let endpoint = `/divisions?city_id=${cityId}`;

  if (page) {
    endpoint += `&page=${page}`;
  }

  if (institutionId) {
    endpoint += `&institution_id=${institutionId}`;
  }

  if (specializationId) {
    endpoint += `&specialization_id=${specializationId}`;
  }

  if (pageSize) {
    endpoint += `&page_size=${pageSize}`;
  }

  if (orderBy) {
    endpoint += `&order_by=${orderBy.key}`;
  }

  if (name) {
    endpoint += `&name=${name}`;
  }

  if (isVaccinationPlace) {
    endpoint += `&is_vaccination_place=${Number(isVaccinationPlace)}`;
  }

  if (districtId) {
    endpoint += `&district_id=${districtId}`;
  }

  if (eHealthUid) {
    endpoint += `&ehealth_uid=${eHealthUid}`;
  }

  return fetching({ method: 'get', endpoint });
};

export const getDoctorSchedule = ({ ids, date_from, date_to, isVaccinates = false }) =>
  fetching({
    method: 'get',
    endpoint: `/doctors/schedule?id=${ids}&from=${date_from}&to=${date_to}&only_vaccination_places=${Number(
      isVaccinates,
    )}`,
  });

export const getDoctorsByDaysSchedule = ({ ids, date_from, date_to }) =>
  fetching({ method: 'get', endpoint: `/doctors/schedule/by-days?id=${ids}&from=${date_from}&to=${date_to}` });

export const getDoctors = ({
  cityId,
  page,
  pageSize,
  specializationId,
  orderBy,
  name,
  isVaccinates,
  divisionId,
  districtId,
}) => {
  let endpoint = `/doctors?city_id=${cityId}&page=${page}`;

  if (pageSize) {
    endpoint += `&page_size=${pageSize}`;
  }

  if (specializationId) {
    endpoint += `&specialization_id=${specializationId}`;
  }

  if (orderBy) {
    endpoint += `&order_by=${orderBy.key}`;
  }

  if (name) {
    endpoint += `&name=${name}`;
  }

  if (isVaccinates) {
    endpoint += `&is_vaccinates=${Number(isVaccinates)}`;
  }

  if (divisionId) {
    endpoint += `&division_id=${divisionId}`;
  }

  if (districtId) {
    endpoint += `&disteict_id=${districtId}`;
  }

  return fetching({ method: 'get', endpoint });
};

export const getDivisionMedics = ({ cityId, divisionId, page, pageSize }) =>
  fetching({
    method: 'get',
    endpoint: `/doctors?city_id=${cityId}&division_id=${divisionId}&page=${page}&page_size=${pageSize}`,
  });

export const appoint = ({ patient_id, body }) =>
  fetching({ method: 'post', endpoint: `/patient/${patient_id}/appointments`, body });

export const operatorAppoint = (body) => fetching({ method: 'post', endpoint: `/operator/appointment`, body });

export const connectBot = (botType) => () =>
  get({ endpoint: '/bots/links' })().then((response) => {
    const botData = response.data.find((bot) => bot.type === botType);

    if (botData?.link) {
      window.location.assign(botData.link);
    }
  });

export const botClosed = (type) =>
  fetching({
    method: 'post',
    endpoint: `/bots/closed/${type}`,
  });

export const getSettings = get({ endpoint: '/settings' });

export const findOperatorPatients = (phone) =>
  fetching({ method: 'post', endpoint: '/operator/find-patients', body: { phone } });

export const makeOperatorReservation = (body) => fetching({ method: 'post', endpoint: '/operator/reservation', body });
