import React from 'react';
import { Marker, MarkerClusterer } from '@react-google-maps/api';

const maxZoomForEnabledCluster = 14.0;

// const options = {
//   imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
//   // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
// };

export default function MarkersCluster({ markers, onMarkerClick }) {
  return (
    <MarkerClusterer
      maxZoom={maxZoomForEnabledCluster}
      // options={options}
    >
      {(clusterer) =>
        markers.map((marker) => (
          <Marker
            key={marker.id}
            position={marker.position}
            icon={marker.icon}
            label={marker.label}
            onClick={onMarkerClick(marker)}
            clusterer={clusterer}
          />
        ))
      }
    </MarkerClusterer>
  );
}
