import React from 'react';

import Loader from '../assets/img/gif/loader.gif';
import Error from '../assets/img/error.png';

export default Component => ({ loading, error, ...props }) => {
  if (error) {
    return (
      <div className='loader'>
        <img src={Error} alt=""/>
        <span>{error}</span>
      </div>
    );
  }


  if (loading) {
    return (
      <div className='loader'>
        <img src={Loader} alt="loader"/>
        <span>Зачекайте, будь ласка, йде завантаження...</span>
      </div>
    );
  }

  return (<Component {...props} />);
};