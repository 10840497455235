import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Footer from '../../components/global/footer';
import HeaderLogo from '../../components/global/header/logo';
import VaccinationButton from '../../components/global/header/vaccinationButton';
import HeaderButton from '../../components/global/header/button';
import { fetchPatients, getPatientInfo } from '../../../actions/patientsActions';
import { logout, updateCity } from '../../../actions';
import { useVaccinationsApi } from '../api';
import { useSetCity } from '../../../hooks/useSetCity';

const VaccinationPages = ({ patient, patients, user, getPatientInfo, fetchPatients, logout, updateCity }) => {
  const { city } = useSetCity({ user, updateCity });

  const { currentPatient, setPatient, storagePatientId, currentPage } = useVaccinationsApi({
    patient,
    patients,
    fetchPatients,
    getPatientInfo,
    logout,
  });

  if (!currentPage) return <Redirect to="/not-found-page" />;

  const { path, Component } = currentPage;

  return (
    <div>
      <header className="header header--vaccination">
        <Container>
          <HeaderLogo city={user?.city} />

          <VaccinationButton />

          <HeaderButton />
        </Container>
      </header>

      <main>
        <Switch>
          <>
            <Route
              path={`/city/${city?.id}/vaccinations${path}`}
              render={(routeProps) => (
                <Component
                  user={user}
                  patient={patient}
                  patients={patients}
                  setPatient={setPatient}
                  currentPatient={currentPatient}
                  storagePatientId={storagePatientId}
                  {...routeProps}
                />
              )}
            />
          </>
        </Switch>
      </main>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ user, patients, patient }) => ({ user, patients, patient });

const mapDispatchToProps = { fetchPatients, getPatientInfo, updateCity, logout };

export default connect(mapStateToProps, mapDispatchToProps)(VaccinationPages);
