import { Dropdown } from 'react-bootstrap';
import React from 'react';

import DistrictDropdownItem from './dropdownItem';

export default function DistrictDropdown({
  district,
  districts,
  allDistrictSelected,
  handleClickAllDistricts,
  updateDistrict,
}) {
  return (
    <Dropdown className="user-cabinet-navigation__item dropdown">
      <Dropdown.Toggle id="dropdown-basic" className="single">
        <div className="user-cabinet-navigation__item-description-content">
          {district?.name || (allDistrictSelected ? 'Всі райони' : 'Оберіть район для пошуку')}
        </div>
        <div className="user-cabinet-navigation__item-description-icon-dropdown">
          <i className="fas fa-chevron-down" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <DistrictDropdownItem onClick={handleClickAllDistricts} name="Показати у всіх районах" />
        {districts.map((district) => (
          <DistrictDropdownItem key={district.id} name={district.name} district={district} onClick={updateDistrict} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
