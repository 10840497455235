import React, { useEffect } from 'react';

import TextLoader from '../../../components/global/Loader/text';
import EmptyData from '../../../components/global/emptyData';
import Pagination from '../../../components/global/List/Pagination';
import Doctor from './doctor';

export default function PastAppointments({
  pastRef,
  appointments,
  setCurrentPage,
  goToCurrentAppointments,
  isVisible,
  city,
}) {
  useEffect(() => {
    if (appointments.pastLoaded && pastRef.current) {
      const { offsetTop } = pastRef.current;

      window.scrollTo(0, offsetTop);
    }
  }, [appointments, pastRef]);

  if (!isVisible) return null;

  if (!appointments.past.data.length && appointments.pastLoading) return <TextLoader />;

  return (
    <div className="streaming-records-current disabled" ref={pastRef}>
      <div className="streaming-records-title">
        Попередні записи
        <div className="streaming-records-title-records-wrapper">
          <div className="streaming-records-title-records-item" onClick={goToCurrentAppointments}>
            <i className="fas fa-calendar-alt" />
            До майбутніх візитов
          </div>
        </div>
      </div>

      {appointments.past.data.length ? (
        <>
          {appointments.past.data.map((props) => (
            <Doctor key={props.id} city={city} {...props} isPast />
          ))}
          <Pagination
            currentPage={appointments.past.pagination.current_page}
            totalPages={appointments.past.pagination.total_pages}
            pageName="page"
          />
        </>
      ) : (
        <EmptyData />
      )}
    </div>
  );
}
