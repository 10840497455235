import React from 'react';

const Dots = ({ props }) => {
  const colorFill = props || '#094FA3';

  return (
    <svg width="4" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="4" height="4" rx="2" fill={colorFill.fill} />
      <rect y="8" width="4" height="4" rx="2" fill={colorFill.fill} />
    </svg>
  );
};

export default Dots;
