import * as TYPES from '../constants/types';

const initialState = {
  loading: false,
  error: null,
  patient: null,
  success: false,
  operatorPatients: [],
  operatorPatientsLoading: false,
  operatorPatientsLoaded: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.CHOOSE_PATIENT:
      return {
        ...state,
        patient: action.payload,
      };

    case TYPES.UPDATE_APPOINTMENT_STATE:
      return initialState;

    case TYPES.MAKE_AN_APPOINTMENT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case TYPES.MAKE_AN_APPOINTMENT_SUCCESS:
      return {
        patientId: action.payload.patient_id,
        loading: false,
        error: null,
        success: true,
      };

    case TYPES.MAKE_AN_APPOINTMENT_FAILURE:
      return {
        loading: false,
        error: action.payload.error,
        success: false,
      };

    case TYPES.MAKE_RESERVATION_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case TYPES.MAKE_RESERVATION_SUCCESS:
      return {
        loading: false,
        error: null,
        success: true,
      };

    case TYPES.MAKE_RESERVATION_FAILURE:
      return {
        loading: false,
        error: action.payload.error,
        success: false,
      };

    case TYPES.KHERSON_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case TYPES.KHERSON_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      };

    case TYPES.KHERSON_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: false,
      };

    case TYPES.FIND_OPERATOR_PATIENTS_REQUEST:
      return {
        ...state,
        operatorPatientsLoading: true,
      };

    case TYPES.FIND_OPERATOR_PATIENTS_SUCCESS:
      return {
        ...state,
        operatorPatients: action.payload.data,
        operatorPatientsLoading: true,
        operatorPatientsLoaded: true,
      };

    case TYPES.FIND_OPERATOR_PATIENTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        operatorPatientsLoading: false,
        operatorPatientsLoaded: false,
      };

    default:
      return state;
  }
}
