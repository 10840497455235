import React from 'react';
import { Link } from 'react-router-dom';

import BotsImage from '../../assets/img/next/bot-images/bot.png';
import { Modal } from '../components/global/modal';

import { useBotModalApi } from './api';

export default function BotModal({ isOpen, syncBot, onClose }) {
  const { handleClose } = useBotModalApi({ syncBot, onClose });

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen}>
      <div className="modal-dialog popupBot" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal_title">Відстежуйте скасування або зміну часу і місця прийому в Viber чи Telegram</p>
            <button onClick={handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img className="mx-auto" src={BotsImage} alt="" />
            <div className="installBtn">
              <Link to="/bot">
                <button type="button" className="btn btn-primary">
                  Дізнатися як
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
