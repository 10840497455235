import React from "react";
import { partnersList, } from './helpers'

const PartnersList = () => {

  return (
    <ul className="partners-list">
      {partnersList.map(({ item, link, alt }, index) => (
        <li className="partners-list__item" key={ index }>
          <a href={link} className="partners-list__link">
            <img src={item} alt={alt}/>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default PartnersList;
