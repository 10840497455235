import React from 'react';
import { Container } from 'react-bootstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CityDropdown from './cityDropdown';
import PartnersList from './partnersList';
import FooterNav from './footerNav';
import { updateCity } from '../../../../actions';
import AppsList from './apps';
import VaccinationButton from '../header/vaccinationButton';

const currentYear = new Date().getFullYear();

const Footer = ({ updateCity, history, user }) => {
  const onCityChange = (city) => {
    updateCity(city);

    history.push('/');
  };

  return (
    <footer className="footer">
      <Container>
        <VaccinationButton user={user} />

        <CityDropdown cities={user.cities} onChange={onCityChange} currentCity={user.city} />

        <FooterNav />

        <AppsList />
      </Container>
      <Container className="copyright-container">
        <div className="copyright">© 2019-{currentYear} ТОВ &quot;Advanter Health&quot;</div>
        <PartnersList />
      </Container>
    </footer>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = { updateCity };

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

export default enhance(Footer);
