import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import HeaderSearch from '../components/global/header/headerSearch';
import Footer from '../components/global/footer/index';
import Auth from '../../containers/Auth';

import BotsImage from '../../assets/img/next/bot-images/bot.png';
import Telegram from '../../assets/img/next/bot-images/telegram.png';
import Viber from '../../assets/img/next/bot-images/viber.png';
import { BotListOne, BotListSecond } from './helpers';

import { connectBot } from '../../api';
import { useBotApi } from './api';

export default function Bot({ user, clearAuthState }) {
  const { botType, botButtonClick, authIsOpen, toggleAuthForm } = useBotApi({
    token: user?.token,
    clearAuthState,
    connectBot,
  });

  return (
    <div>
      <HeaderSearch city={user?.city} />

      <main>
        <Container>
          <div className="bot-wrapper">
            <Row>
              <Col xs="12" lg="6" className="bot-about">
                <h1 className="main-title">Кабінет пацієнта в Viber чи Telegram</h1>

                <Col xs="12" lg="12" className="bot-description">
                  <p>Встановіть нашого бота і використовуйте наступні можливості прямо з Viber або Telegram:</p>
                  <div className="bot-list-wrapper">
                    <ul className="bot-list">
                      {BotListOne.map(({ id, icon: Icon, description, component: Component }) => (
                        <li className="bot-list__item" key={id}>
                          <div className="bot-list__item-about">
                            <div className="bot-list__item-icon">
                              <Icon />
                            </div>
                            <div className="bot-list__item-description">{description}</div>
                          </div>
                          {Component && <Component />}
                        </li>
                      ))}
                    </ul>

                    <ul className="bot-list">
                      {BotListSecond.map(({ id, icon: Icon, description, component: Component }) => (
                        <li className="bot-list__item" key={id}>
                          <div className="bot-list__item-about">
                            <div className="bot-list__item-icon">
                              <Icon />
                            </div>
                            <div className="bot-list__item-description">{description}</div>
                          </div>
                          {Component && <Component />}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>

                <Col xs="12" className="main-img d-lg-none">
                  <img src={BotsImage} alt="" />
                </Col>

                <Col xs="12" className="bot-btn d-lg-block">
                  <button type="button" className=" btn btn-primary" onClick={botButtonClick('telegram')}>
                    <img src={Telegram} alt="" />
                    Telegram
                  </button>
                  <button type="button" className=" btn btn-primary" onClick={botButtonClick('viber')}>
                    <img src={Viber} alt="" />
                    Viber
                  </button>
                </Col>
              </Col>

              <Col xs="6" className="main-img">
                <img src={BotsImage} alt="" />
              </Col>
            </Row>
          </div>
        </Container>
      </main>

      <Footer />

      <Auth
        isOpen={authIsOpen}
        onClose={toggleAuthForm}
        denyRedirect
        connectToBotFn={botType ? connectBot(botType) : null}
      />
    </div>
  );
}
