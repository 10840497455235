import RenderField from '../common/renderField';
export const declarationSignedFalse = [
  {
    text: "Ім'я пацієнта",
    input: { type: 'text', maxLength: 255, required: true, placeholder: "Ім'я", name: 'name' },
    Component: RenderField,
  },
  {
    text: "Прізвище пацієнта",
    input: { type: 'text', maxLength: 255, required: true, placeholder: "Прізвище", name: 'last_name' },
    Component: RenderField,
  },
  {
    text: "Дата народження пацієнта",
    input: { type: 'text', required: true, placeholder: "Дата народження", name: 'birthday', mask:"99.99.9999" },
    Component: RenderField
  },
  {
    text: 'Причина звернення',
    input: { type: 'text', placeholder: 'Причина...', maxLength: 255, name: 'reserv_comment', required: true },
    Component: RenderField,
  },
];
