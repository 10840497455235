import React from 'react';
import { Container, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getDivisions, getDivisionsMapData, getDivisionMapData } from '../../actions/hospitals';
import {
  getDoctors,
  getDoctorSchedule,
  getDoctorsMapData,
  getDoctorMapData,
  setCalendarDate,
  getDoctorsByDaysSchedule,
  doctorScheduleDateRangeResize,
} from '../../actions/doctors';
import { fetchPatients } from '../../actions/patientsActions';
import { syncBot, updateCity } from '../../actions';

import Navigation from '../components/global/List/Navigation';
import HeaderSearchList from '../components/global/header/indexSearchList';
import Footer from '../components/global/footer';

import DivisionPageList from './divisionPageList';
import DoctorsPageList from './doctorsPageList';
import MapPageComponent from '../mapPage';

import useDoctorScheduleDateRangeResize from '../../hooks/useDoctorScheduleDateRangeResize';
import { useListApi } from './api';
import { useSetCity } from '../../hooks/useSetCity';

export const tabEventKeys = {
  DOCTORS: 'doctors',
  DIVISIONS: 'divisions',
  DOCTORS_MAP: 'doctors_map',
  DIVISIONS_MAP: 'divisions_map',
};

const LayoutList = ({
  doctor,
  doctors,
  doctorsMap,
  doctorSchedule,
  getDoctors,
  getDoctorsMapData,
  getDoctorMapData,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  doctorsByDaysSchedule,
  getDivisions,
  getDivisionsMapData,
  getDivisionMapData,
  getPatients,
  divisions,
  divisionsMap,
  setCalendarDate,
  user,
  syncBot,
  updateCity,
  doctorScheduleDateRangeResize,
}) => {
  const { city } = useSetCity({ user, updateCity });
  useDoctorScheduleDateRangeResize({ datesRange: doctorSchedule.datesRange, doctorScheduleDateRangeResize });
  const {
    eventKey,
    onTabSelect,
    setEventKey,
    currentDoctorsOrderBy,
    currentDivisionsOrderBy,
    setCurrentDoctorsOrderBy,
    setCurrentDivisionsOrderBy,
  } = useListApi({ tabEventKeys, city });

  if (
    !city ||
    !tabEventKeys[eventKey.toUpperCase()] ||
    ([tabEventKeys.DIVISIONS_MAP, tabEventKeys.DOCTORS_MAP].includes(eventKey) && !city?.settings?.show_map)
  ) {
    return <Redirect to="/not-found-page" />;
  }

  return (
    <div>
      <HeaderSearchList city={user?.city} />
      <main>
        <div className="data-list-wrapper">
          <Container>
            <div className="tabs-wrapper">
              <Tab.Container activeKey={eventKey} onSelect={onTabSelect}>
                <Navigation
                  tabEventKeys={tabEventKeys}
                  setEventKey={setEventKey}
                  eventKey={eventKey}
                  setCalendarDate={setCalendarDate}
                  divisionsTotal={divisions.pagination.total}
                  doctorsTotal={doctors.pagination.total}
                  calendarDate={doctorSchedule.date}
                  doctorsOrderByList={city?.settings?.doctors?.order_by ?? []}
                  divisionsOrderByList={city?.settings?.divisions?.order_by ?? []}
                  currentDivisionsOrderBy={currentDivisionsOrderBy}
                  currentDoctorsOrderBy={currentDoctorsOrderBy}
                  setCurrentDoctorsOrderBy={setCurrentDoctorsOrderBy}
                  setCurrentDivisionsOrderBy={setCurrentDivisionsOrderBy}
                  city={city}
                />
                <Tab.Content>
                  <Tab.Pane eventKey={tabEventKeys.DIVISIONS}>
                    <DivisionPageList
                      getDivisions={getDivisions}
                      divisions={divisions}
                      city={city}
                      currentDivisionsOrderBy={currentDivisionsOrderBy}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={tabEventKeys.DOCTORS}>
                    <DoctorsPageList
                      doctor={doctor}
                      doctors={doctors}
                      doctorSchedule={doctorSchedule}
                      doctorsByDaysSchedule={doctorsByDaysSchedule}
                      getDoctors={getDoctors}
                      getDoctorSchedule={getDoctorSchedule}
                      getDoctorsByDaysSchedule={getDoctorsByDaysSchedule}
                      getPatients={getPatients}
                      user={user}
                      city={city}
                      syncBot={syncBot}
                      currentDoctorsOrderBy={currentDoctorsOrderBy}
                      setCalendarDate={setCalendarDate}
                    />
                  </Tab.Pane>
                  {eventKey === tabEventKeys.DIVISIONS_MAP && (
                    <Tab.Pane eventKey={tabEventKeys.DIVISIONS_MAP} className="maps-tab">
                      <div className="maps-tab-content">
                        <MapPageComponent
                          getDivisionsMapData={getDivisionsMapData}
                          getDivisionMapData={getDivisionMapData}
                          divisionsMap={divisionsMap}
                          getDoctorsMapData={getDoctorsMapData}
                          doctorsMap={doctorsMap}
                          tabEventKeys={tabEventKeys}
                          tabEventKey={eventKey}
                          user={user}
                          updateCity={updateCity}
                        />
                      </div>
                    </Tab.Pane>
                  )}
                  {eventKey === tabEventKeys.DOCTORS_MAP && (
                    <Tab.Pane eventKey={tabEventKeys.DOCTORS_MAP} className="maps-tab">
                      <div className="maps-tab-content">
                        <MapPageComponent
                          divisionsMap={divisionsMap}
                          getDoctorsMapData={getDoctorsMapData}
                          getDoctorMapData={getDoctorMapData}
                          doctorsMap={doctorsMap}
                          tabEventKeys={tabEventKeys}
                          tabEventKey={eventKey}
                          user={user}
                          updateCity={updateCity}
                        />
                      </div>
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </Tab.Container>
            </div>
          </Container>
        </div>
      </main>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({
  doctor,
  doctors,
  doctorsMap,
  doctorSchedule,
  doctorsByDaysSchedule,
  divisions,
  divisionsMap,
  user,
}) => ({
  doctor,
  doctors,
  doctorsMap,
  doctorSchedule,
  divisions,
  divisionsMap,
  user,
  doctorsByDaysSchedule,
});

const mapDispatchToProps = {
  getDoctors,
  getDoctorsMapData,
  getDoctorMapData,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  getDivisions,
  getDivisionsMapData,
  getDivisionMapData,
  setCalendarDate,
  getPatients: fetchPatients,
  syncBot,
  updateCity,
  doctorScheduleDateRangeResize,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutList);
