import { useEffect, useState } from 'react';

import { botClosed } from '../../api';

export function useBotApi({ token, clearAuthState, connectBot }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [botType, setBotType] = useState(null);
  const [authIsOpen, setAuthIsOpen] = useState(false);

  const toggleAuthForm = currentBotType => {
    setBotType(currentBotType);

    if (authIsOpen) clearAuthState();

    setAuthIsOpen(prevState => !prevState);
  };

  const botButtonClick = currentBotType => () =>
    token ? connectBot(currentBotType)() : toggleAuthForm(currentBotType);

  return {
    botType,
    botButtonClick,
    authIsOpen,
    toggleAuthForm,
  };
}

export function useBotModalApi({ syncBot, onClose }) {
  const handleClose = e => {
    e.preventDefault();
    onClose();

    botClosed('popup').then(() => {
      syncBot();
    });
  };

  return {
    handleClose,
  };
}
