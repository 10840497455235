import React, { useCallback } from 'react';

import Item from './item';
import { useUrlParser } from '../../../../../utils/useUrlParser';

const pageElement = ({ item, index, to, onClick, onlySpan, disabled }) => (
  <Item key={index} disabled={disabled} to={to} onClick={onClick} onlySpan={onlySpan}>
    {item}
  </Item>
);

export function usePaginationApi({ setPageHandler, totalPages, currentPage, pageName }) {
  const urlParser = useUrlParser();
  const setPage = useCallback(
    (page) => () => {
      if (page >= 1 && page <= totalPages) setPageHandler(page);
    },
    [setPageHandler, totalPages],
  );

  const renderButtons = useCallback(() => {
    const showAllPagesCount = 10;
    const maxStartEndPagesCount = 5;
    const minStartEndPagesCount = 2;
    const middlePagesCount = 3;

    if (totalPages <= showAllPagesCount) {
      return [...Array(totalPages).keys()].map((item) =>
        pageElement({
          item: item + 1,
          index: item + 1,
          to: urlParser.calcNewPaginationTo(pageName, item + 1),
          onClick: setPage(item + 1),
          onlySpan: !!setPageHandler,
          disabled: currentPage === item + 1,
        }),
      );
    }

    const pages = [];

    if (currentPage <= maxStartEndPagesCount - 1) {
      [...Array(maxStartEndPagesCount).keys()].forEach((item) =>
        pages.push({ value: item + 1, disabled: currentPage === item + 1 }),
      );
      pages.push({ value: '...', disabled: true });
      [...Array(minStartEndPagesCount).keys()].forEach((item) =>
        pages.push({ value: totalPages - minStartEndPagesCount + 1 + item, disabled: false }),
      );
    }

    if (totalPages - currentPage <= maxStartEndPagesCount - 2) {
      [...Array(minStartEndPagesCount).keys()].forEach((item) =>
        pages.push({ value: item + minStartEndPagesCount - 1, disabled: false }),
      );
      pages.push({ value: '...', disabled: true });
      [...Array(maxStartEndPagesCount).keys()].forEach((item) =>
        pages.push({
          value: totalPages - maxStartEndPagesCount + 1 + item,
          disabled: currentPage === totalPages - maxStartEndPagesCount + 1 + item,
        }),
      );
    }

    if (currentPage > maxStartEndPagesCount - 1 && totalPages - currentPage > maxStartEndPagesCount - 2) {
      [...Array(minStartEndPagesCount).keys()].forEach((item) =>
        pages.push({ value: item + minStartEndPagesCount - 1, disabled: false }),
      );
      pages.push({ value: '...', disabled: true });
      [...Array(middlePagesCount).keys()].forEach((item) =>
        pages.push({
          value: currentPage - Math.floor(middlePagesCount / 2) + item,
          disabled: currentPage === currentPage - Math.floor(middlePagesCount / 2) + item,
        }),
      );
      pages.push({ value: '...', disabled: true });
      [...Array(minStartEndPagesCount).keys()].forEach((item) =>
        pages.push({ value: totalPages - minStartEndPagesCount + 1 + item, disabled: false }),
      );
    }

    return pages.map((item, index) =>
      pageElement({
        item: item.value,
        index: `${index}_${item.value}`,
        to: urlParser.calcNewPaginationTo(pageName, item.value),
        onClick: setPage(item.value),
        onlySpan: !!setPageHandler,
        disabled: item.disabled,
      }),
    );
  }, [currentPage, pageName, setPage, setPageHandler, totalPages, urlParser]);

  return {
    urlParser,
    setPage,
    renderButtons,
  };
}
