import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getPatientBarCode, getPatientQrCode } from '../../../../actions/patientsActions';
import usePersonalCodeModalApi from './api';

const PersonalCodeModal = ({ isVisible, handleClose, patient, getBarCode, getQrCode }) => {
  const { patientPhone, getCodeSvg, onChangeCode, getHtmlCode, onCloseModal, getButtonText } = usePersonalCodeModalApi({
    isVisible,
    handleClose,
    patient,
    getBarCode,
    getQrCode,
  });

  if (!isVisible) return null;

  return (
    <Modal show={isVisible} onHide={onCloseModal} className="modal-personal-code" centered>
      <Modal.Header closeButton>
        <Modal.Title>Персональний код пацієнта</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="user_name">{patient?.data?.full_name}</div>
        <div className="user_code_number">Код: {patient?.data?.patient_code?.code}</div>
        <div className="user_phone">{patientPhone}</div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="user_code" dangerouslySetInnerHTML={{ __html: getHtmlCode() }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onChangeCode}>
          <span>
            <img src={getCodeSvg()} alt="" />
          </span>
          <span>{getButtonText()}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({ patient }) => ({ patient });

const mapDispatchToProps = { getBarCode: getPatientBarCode, getQrCode: getPatientQrCode };

export default connect(mapStateToProps, mapDispatchToProps)(PersonalCodeModal);
