import React from 'react';

import About from './about';
import Address from './address';

export default function Division({
  full_name,
  institution_full_name,
  address,
  phones,
  rating,
  photo,
  indicators,
  mapUrl,
  divisionId,
  institutionId,
  description,
  remainedMedicinesUrl,
  city,
  currentDivision,
  showAboutInformation = true,
  externalMapUrl = false,
  disableOwnLink = false,
  isVaccination = false,
  isSmallVaccinationCenter = false,
  myAmbulatoryNote = false,
  closestAvailableTime,
}) {
  return (
    <div className="institution-about">
      <Address
        isVaccination={isVaccination}
        isSmallVaccinationCenter={isSmallVaccinationCenter}
        currentDivision={currentDivision}
        full_name={full_name}
        institution_full_name={institution_full_name}
        address={address}
        rating={rating}
        photo={photo}
        indicators={indicators}
        mapUrl={mapUrl}
        divisionId={divisionId}
        institutionId={institutionId}
        description={description}
        externalMapUrl={externalMapUrl}
        city={city}
        disableOwnLink={disableOwnLink}
        myAmbulatoryNote={myAmbulatoryNote}
        closestAvailableTime={closestAvailableTime}
      />
      <About phones={phones} remainedMedicinesUrl={remainedMedicinesUrl} show={showAboutInformation} />
    </div>
  );
}
