export function useErrors({ user, patient, patients, callbacks }) {
  if (localStorage.getItem('token') && localStorage.getItem('patientId') && patient) return null;

  if (!user.token) {
    return {
      disableButton: false,
      errorText: 'Даний режим призначений тільки для пацієнтів, які уклали декларацію з сімейним лікарем',
      callback: callbacks.auth,
    };
  }

  if (!user.declarationSigned) {
    return {
      disableButton: true,
      errorText: 'Даний режим призначений тільки для пацієнтів, які уклали декларацію з сімейним лікарем',
    };
  }

  if (!patients.data.some((patient) => patient?.registration?.is_division_vaccination_place)) {
    return { disableButton: true, errorText: 'В амбулаторії немає вакцинації' };
  }

  return null;
}
