import { LOCATION_CHANGE } from 'react-router-redux';
import * as TYPES from '../../constants/types';

import { GOOGLE_MARKER } from '../../constants/config';

const initialState = {
  data: [],
  activeMarker: null,
  loading: false,
  loaded: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_DOCTORS_MAP_DATA_REQUEST:
    case TYPES.GET_DOCTOR_MAP_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case TYPES.GET_DOCTORS_MAP_DATA_SUCCESS: {
      const markers = {};

      action.payload.response.data.forEach((doctor) => {
        doctor.jobs.forEach((job) => {
          if (!job.division.address.lat || !job.division.address.lon) return;

          const lanLngKey = `${job.division.address.lat}-${job.division.address.lon}`;

          const doctorJobMarkerData = {
            id: doctor.id,
            title: doctor.full_name,
            title_link: `/doctors/${doctor.id}`,
            content: doctor.specializations[0]?.name,
            rating: doctor.rating,
          };

          if (markers[lanLngKey]) {
            markers[lanLngKey].icon = GOOGLE_MARKER.doctor.many.icon;
            markers[lanLngKey].iconsList = {
              icon: GOOGLE_MARKER.doctor.many.icon,
              activeIcon: GOOGLE_MARKER.doctor.many.active.icon,
            };
            markers[lanLngKey].data = [...markers[lanLngKey].data, doctorJobMarkerData];
            markers[lanLngKey].label.text = `${markers[lanLngKey].data.length}`;

            return;
          }

          markers[lanLngKey] = {
            id: lanLngKey,
            icon: GOOGLE_MARKER.doctor.one.icon,
            iconsList: {
              icon: GOOGLE_MARKER.doctor.one.icon,
              activeIcon: GOOGLE_MARKER.doctor.one.active.icon,
            },
            position: {
              lat: +job.division.address.lat,
              lng: +job.division.address.lon,
            },
            label: {
              text: '1',
              ...GOOGLE_MARKER.label,
            },
            labelsColor: {
              color: GOOGLE_MARKER.label.color,
              activeColor: GOOGLE_MARKER.activeLabel.color,
            },
            data: [doctorJobMarkerData],
          };
        });
      });

      return {
        ...state,
        data: Object.values(markers),
        activeMarker: null,
        loading: false,
        loaded: true,
      };
    }

    case TYPES.GET_DOCTOR_MAP_DATA_SUCCESS: {
      const doctor = action.payload.response.data;
      const job = doctor.jobs.find((job) => job.division.id === +action.payload.divisionId);

      if (!job?.division?.address?.lat || !job?.division?.address?.lon)
        return {
          ...state,
          data: [],
          activeMarker: null,
          loading: false,
          loaded: true,
        };

      const doctorJobMarkerData = {
        id: doctor.id,
        title: doctor.full_name,
        title_link: `/doctors/${doctor.id}`,
        content: doctor.specializations[0]?.name,
        rating: doctor.rating,
      };
      const marker = {
        id: `${job.division.address.lat}-${job.division.address.lon}`,
        icon: GOOGLE_MARKER.one.active.icon,
        position: {
          lat: +job.division.address.lat,
          lng: +job.division.address.lon,
        },
        label: null,
        data: [doctorJobMarkerData],
      };

      return {
        ...state,
        data: [marker],
        activeMarker: marker,
        loading: false,
        loaded: true,
      };
    }

    case TYPES.GET_DOCTORS_MAP_DATA_FAILURE:
    case TYPES.GET_DOCTOR_MAP_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
      };

    case LOCATION_CHANGE:
      return initialState;

    default:
      return state;
  }
}
