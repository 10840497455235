import React from 'react';
import InputMask from 'react-input-mask';

export default function ReferralInput({
  timeSlot,
  referralNumber,
  register,
  setReferralNumber,
  style,
  formErrors,
  trigger,
}) {
  if (!timeSlot?.direct_only) return null;

  const onChange = (e) => {
    setReferralNumber(e.target.value);

    if (formErrors.referral_number) {
      trigger('referral_number');
    }
  };

  return (
    <div className="direction-area" style={style}>
      <div className="direction-area__title">Введіть номер направлення*:</div>
      <InputMask
        mask="9999-9999-9999-9999"
        type="tel"
        name="referral_number"
        placeholder="____-____-____-____"
        onChange={onChange}
        value={referralNumber}
      >
        {(inputProps) => (
          <input
            {...inputProps}
            ref={register({
              required: {
                value: true,
                message: "Поле обов'язкове для заповнення",
              },
              pattern: {
                value: /^\d{4}-\d{4}-\d{4}-\d{4}$/,
                message: 'Невірний формат',
              },
            })}
          />
        )}
      </InputMask>
      {formErrors.referral_number && <div className="validation_error">{formErrors.referral_number.message}</div>}
      <div className="direction-area__info">
        * На цей час можна записатися тільки по направленню. Введіть номер, який ви отримали раніше або запишіться на
        інший час
      </div>
    </div>
  );
}
