import React from 'react';

import GoogleMap from '../components/global/GoogleMap';
import { useMapApi } from './api';
import { useSetCity } from '../../hooks/useSetCity';

export default function MapPageComponent({
  divisionsMap,
  doctorsMap,
  tabEventKey,
  tabEventKeys,
  getDoctorsMapData,
  getDoctorMapData,
  getDivisionsMapData,
  getDivisionMapData,
  user,
  isVaccinationPlace,
  districtsLoaded,
  disableOwnLink,
  updateCity,
}) {
  const { city } = useSetCity({ user, updateCity });
  const { mapData, activeMarker } = useMapApi({
    divisionsMap,
    doctorsMap,
    getDoctorsMapData,
    getDoctorMapData,
    getDivisionsMapData,
    getDivisionMapData,
    tabEventKey,
    tabEventKeys,
    isVaccinationPlace,
    districtsLoaded,
    disableOwnLink,
    city: city || user?.city,
    district: user?.district,
  });

  return (
    <GoogleMap
      data={mapData}
      activeMarker={activeMarker}
      city={city || user.city}
      disableLink={disableOwnLink}
      isVaccination
    />
  );
}
