import React from 'react';

export default function Photo({ photo, defaultPhoto }) {
  return (
    <div className="medic-feature-profile-status__icon-wrapper">
      <div
        className="medic-feature-profile-status__icon"
        style={{ backgroundImage: `url(${photo ?? defaultPhoto})` }}
      />
    </div>
  );
}
