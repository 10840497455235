import React from 'react';

const Education = ({ show }) => {
  if (!show) return null;

  return (
    <li className="medic-feature-profile-detail-list__item">
      <div className="medic-feature-profile-detail__title">Подробная информация о специалисте</div>
      <div className="medic-feature-profile-detail__experience">
        <ul className="medic-feature-profile-detail__experience-list">
          <li className="medic-feature-profile-detail__experience-list-item">
            <div className="medic-feature-profile-detail__experience-title">Образование</div>
            <ul className="medic-feature-profile-detail__experience-info-list">
              <li className="medic-feature-profile-detail__experience-info-list-item">
                Первый Московский государственный медицинский университет имени И. М. Сеченова (2006 г.)
              </li>
              <li className="medic-feature-profile-detail__experience-info-list-item">
                Ординатура по специальности &quot;Сердечно-сосудистая хирургия&quot;, Национальный медицинский
                исследовательский центр сердечно-сосудистой хирургии имени А. Н. Бакулева (2015 г.)
              </li>
            </ul>
          </li>
          <li className="medic-feature-profile-detail__experience-list-item">
            <div className="medic-feature-profile-detail__experience-title">Курсы повышения квалификации</div>
            <ul className="medic-feature-profile-detail__experience-info-list">
              <li className="medic-feature-profile-detail__experience-info-list-item">
                &quot;Детская кардиология&quot; (2011 г.)
              </li>
              <li className="medic-feature-profile-detail__experience-info-list-item">
                &quot;Детская хирургия&quot; (2013 г.)
              </li>
            </ul>
          </li>
          <li className="medic-feature-profile-detail__experience-list-item">
            <div className="medic-feature-profile-detail__experience-title">
              Все виды вмешательств при всевозможных нарушениях ритма:
            </div>
            <ul className="medic-feature-profile-detail__experience-info-list">
              <li className="medic-feature-profile-detail__experience-info-list-item">
                Имплантация антиаритмических устройств (электрокардиостимуляторы (ЭКС) и имплантируемые
                кардиовертеры-дефибрилляторы (ИКД) при бради- и тахиситолических нарушениях ритма;
              </li>
              <li className="medic-feature-profile-detail__experience-info-list-item">
                Радиочастотные (РЧА) и криодеструкции аритмогенных очагов при различных тахисистолических нарушения
                ритма, включая фибрилляцию предсердий.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default Education;
