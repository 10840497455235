import React from 'react';
import { useHistory } from 'react-router-dom';

export default function BreadCrumbs({ breadCrumbs }) {
  const history = useHistory();

  if (!breadCrumbs) return null;

  return (
    <div className="breadcrumbs-wrapper">
      <div className={`breadcrumbs-item${breadCrumbs.className ? ` ${breadCrumbs.className}` : ''}`}>
        <span
          className="breadcrumbs-link"
          onClick={() => {
            history.goBack();
          }}
        >
          <i className="fas fa-chevron-left" />
          Назад
        </span>
      </div>
    </div>
  );
}
