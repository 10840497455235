import mapMarkerDoctorSvg from '../assets/img/next/svg/map-marker-doctor.svg';
import mapMarkerDoctorsSvg from '../assets/img/next/svg/map-marker-doctors.svg';
import mapMarkerDoctorActiveSvg from '../assets/img/next/svg/map-marker-doctor-active.svg';
import mapMarkerDoctorsActiveSvg from '../assets/img/next/svg/map-marker-doctors-active.svg';

import mapMarkerDivisionSvg from '../assets/img/next/svg/map-marker-division.svg';
import mapMarkerDivisionsSvg from '../assets/img/next/svg/map-marker-divisions.svg';
import mapMarkerDivisionActiveSvg from '../assets/img/next/svg/map-marker-division-active.svg';
import mapMarkerDivisionsActiveSvg from '../assets/img/next/svg/map-marker-divisions-active.svg';

import mapMarkerVaccinationPlaceSvg from '../assets/img/next/svg/map-marker-vaccination-place.svg';
import mapMarkerVaccinationPlacesSvg from '../assets/img/next/svg/map-marker-vaccination-places.svg';
import mapMarkerVaccinationPlaceActiveSvg from '../assets/img/next/svg/map-marker-vaccination-place-active.svg';
import mapMarkerVaccinationPlacesActiveSvg from '../assets/img/next/svg/map-marker-vaccination-places-active.svg';

export const TIME_TO_TOKEN_EXPIRED = 300_000; // 5min
export const SCHEDULE_DAYS_COUNT = 5;

const origin = { x: 8, y: -2 };

export const GOOGLE_MARKER = {
  label: {
    color: '#094FA3',
    fontSize: '14px',
    fontWeight: '700',
  },
  activeLabel: {
    color: 'white',
  },
  doctor: {
    one: {
      icon: { url: mapMarkerDoctorSvg, origin },
      active: {
        icon: { url: mapMarkerDoctorActiveSvg, origin },
      },
    },
    many: {
      icon: { url: mapMarkerDoctorsSvg, origin },
      active: {
        icon: { url: mapMarkerDoctorsActiveSvg, origin },
      },
    },
  },
  division: {
    one: {
      icon: { url: mapMarkerDivisionSvg, origin },
      active: {
        icon: { url: mapMarkerDivisionActiveSvg, origin },
      },
    },
    many: {
      icon: { url: mapMarkerDivisionsSvg, origin },
      active: {
        icon: { url: mapMarkerDivisionsActiveSvg, origin },
      },
    },
  },
  vaccinationPlace: {
    one: {
      icon: { url: mapMarkerVaccinationPlaceSvg, origin },
      active: {
        icon: { url: mapMarkerVaccinationPlaceActiveSvg, origin },
      },
    },
    many: {
      icon: { url: mapMarkerVaccinationPlacesSvg, origin },
      active: {
        icon: { url: mapMarkerVaccinationPlacesActiveSvg, origin },
      },
    },
  },
};

export const IS_VACCINATION_PAGE_AVAILABLE = process.env.REACT_APP_IS_VACCINATION_PAGE_AVAILABLE;

export const devToolsConfig = { autoPause: true, maxAge: 20 };
