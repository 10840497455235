import { useCallback, useEffect } from 'react';

import { useUrlParser } from '../../utils/useUrlParser';

export function useOperatorApi({ divisions, getDivisions }) {
  const urlParser = useUrlParser();

  useEffect(() => {
    if (!divisions.loading && !divisions.loaded && urlParser.urlData.params.division_id) {
      getDivisions({
        cityId: 1,
        eHealthUid: urlParser.urlData.params.division_id,
        isVaccinationPlace: true,
      });
    }
  }, [divisions.loaded, divisions.loading, getDivisions, urlParser.urlData.params.division_id]);

  const checkIfNoDivisionsFound = useCallback(() => {
    return divisions.loaded && !divisions.loading && !divisions.data.length;
  }, [divisions.data.length, divisions.loaded, divisions.loading]);

  return {
    isOperator: true,
    noDivisionsState: checkIfNoDivisionsFound(),
    division: divisions.data[0],
  };
}
