import React from 'react';
import { Nav } from 'react-bootstrap';
import Sticky from 'react-sticky-el';

import { useNavigationApi } from './api';
import Sort from '../Sort';
import Calendar from '../Calendar';
import ScheduleHeader from '../ScheduleHeader';
import MapTabLink from './mapTabLink';

export default function Navigation({
  doctorsTotal,
  calendarDate,
  tabEventKeys,
  eventKey,
  divisionsTotal,
  setCalendarDate,
  doctorsOrderByList,
  divisionsOrderByList,
  currentDoctorsOrderBy,
  currentDivisionsOrderBy,
  setCurrentDoctorsOrderBy,
  setCurrentDivisionsOrderBy,
  city,
}) {
  const { orderByList, setCurrentOrderBy, currentOrderBy } = useNavigationApi({
    tabEventKeys,
    eventKey,
    doctorsOrderByList,
    divisionsOrderByList,
    currentDoctorsOrderBy,
    currentDivisionsOrderBy,
    setCurrentDoctorsOrderBy,
    setCurrentDivisionsOrderBy,
  });

  return (
    <Sticky className="sticky-wrapper">
      <div className="navigation">
        <Nav>
          <Nav.Item>
            <Nav.Link
              eventKey={tabEventKeys.DOCTORS}
              className={`nav-link${eventKey === tabEventKeys.DOCTORS_MAP ? ' active' : ''}`}
            >
              Лікарі <span>{doctorsTotal}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={tabEventKeys.DIVISIONS}
              className={`nav-link${eventKey === tabEventKeys.DIVISIONS_MAP ? ' active' : ''}`}
            >
              Відділення <span>{divisionsTotal}</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="navigation-helpers">
          {!!city?.settings?.show_map && (
            <>
              {eventKey === tabEventKeys.DIVISIONS && <MapTabLink eventKey={tabEventKeys.DIVISIONS_MAP} />}
              {eventKey === tabEventKeys.DIVISIONS_MAP && <MapTabLink eventKey={tabEventKeys.DIVISIONS} list />}
              {eventKey === tabEventKeys.DOCTORS && <MapTabLink eventKey={tabEventKeys.DOCTORS_MAP} />}
              {eventKey === tabEventKeys.DOCTORS_MAP && <MapTabLink eventKey={tabEventKeys.DOCTORS} list />}
            </>
          )}

          <Sort
            showMobileText={eventKey === tabEventKeys.DIVISIONS || eventKey === tabEventKeys.DIVISIONS_MAP}
            showDesktopText
            orderByList={orderByList}
            setCurrentOrderBy={setCurrentOrderBy}
            currentOrderBy={currentOrderBy}
          />
          {(eventKey === tabEventKeys.DOCTORS || eventKey === tabEventKeys.DOCTORS_MAP) &&
            !!city?.settings?.allow_timeslots && (
              <Calendar setCalendarDate={setCalendarDate} calendarDate={calendarDate} />
            )}
        </div>

        <ScheduleHeader
          show={
            !!city?.settings?.allow_timeslots &&
            (eventKey === tabEventKeys.DOCTORS || eventKey === tabEventKeys.DOCTORS_MAP)
          }
        />
      </div>
    </Sticky>
  );
}
