import React from 'react';

import { useDoctorScheduleApi, useDoctorsPageListApi, usePatientsApi } from './api';
import Doctor from './common/Doctor';
import Pagination from '../../components/global/List/Pagination';

export default function DoctorsPageListComponent({
  doctors,
  doctorSchedule,
  getDoctors,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  doctorsByDaysSchedule,
  getPatients,
  user,
  city,
  currentDoctorsOrderBy,
  setCalendarDate,
  isVaccinates,
  currentDivisionId,
  placeholder,
  isOperator,
  disableOwnLink = false,
  websiteCovidSource = null,
}) {
  useDoctorsPageListApi({
    doctors,
    getDoctors,
    city,
    currentDivisionId,
    currentDoctorsOrderBy,
    isVaccinates,
  });

  useDoctorScheduleApi({
    doctors,
    getDoctorSchedule,
    doctorSchedule,
    doctorsByDaysSchedule,
    getDoctorsByDaysSchedule,
    city,
    isVaccinates,
  });

  usePatientsApi({ getPatients, token: user.token });

  if (doctors.loaded && !doctors.data.length) {
    return placeholder || <div className="empty-list">Нічого не знайдено</div>;
  }

  return (
    <>
      {doctors.data.map((doctor) => (
        <Doctor
          websiteCovidSource={websiteCovidSource}
          isOperator={isOperator}
          isVaccinates={isVaccinates}
          key={doctor.id}
          full_name={doctor.full_name}
          jobs={doctor.jobs}
          indicators={doctor.indicators}
          specializations={doctor.specializations}
          doctorSchedule={doctorSchedule}
          currentDoctorSchedule={doctorSchedule.data.find((item) => item.id === doctor.id)}
          byDaysSchedule={doctorsByDaysSchedule.data.find((item) => item.id === doctor.id)}
          phones={doctor.contacts.filter((contact) => contact.type === 'phone')}
          rating={doctor.rating}
          photo={doctor.photo}
          doctor={doctor}
          city={city}
          showRatingIndicator
          disableOwnLink={disableOwnLink}
          setCalendarDate={setCalendarDate}
        />
      ))}
      <Pagination
        currentPage={doctors.currentPage}
        pageName="doctors_page"
        totalPages={doctors.pagination.total_pages}
      />
    </>
  );
}
