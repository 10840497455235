import React from 'react';

import OperatorStep from './operatorStep';
import PatientNameStep from './patientNameStep';
import PatientStep from './patientStep';

export default function Operator({
  operatorPatientsLoaded,
  operatorPatients,
  handleSubmit,
  onSubmit,
  error,
  register,
  isOperatorChooseOtherPatient,
  updateAppointmentState,
  watch,
  control,
}) {
  const commonProps = {
    operatorPatientsLoaded,
    updateAppointmentState,
    operatorPatients,
    error,
    register,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="confirmation_of_entry confirmed">
      <OperatorStep
        show={!isOperatorChooseOtherPatient && !operatorPatientsLoaded}
        watch={watch}
        control={control}
        {...commonProps}
      />
      <PatientStep
        show={!isOperatorChooseOtherPatient && operatorPatientsLoaded && operatorPatients.length}
        {...commonProps}
      />
      <PatientNameStep
        show={isOperatorChooseOtherPatient || (operatorPatientsLoaded && !operatorPatients.length)}
        {...commonProps}
      />
    </form>
  );
}
