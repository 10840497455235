import React from 'react';

export default function Description({ description }) {
  return (
    <div className="institution-info__indicator-item">
      <div className="institution-info__indicator-title">Опис</div>
      <div className={`institution-info__indicator-describe${!description ? ' empty' : ''}`}>
        {description ?? 'Немає даних'}
      </div>
    </div>
  );
}
