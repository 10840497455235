import { NavLink } from 'react-router-dom';
import React from 'react';

import MapLink from '../../../../../doctorPage/common/Doctor/mapLink';
import JobsPagination from './pagination';

export default function Job({
  doctor,
  activeKey,
  division,
  id,
  institution,
  isPagesAvailable,
  jobs,
  position,
  setKey,
  city,
}) {
  return (
    <div className="medic-feature-profile-information__place-service" key={id}>
      <p className="medic-feature-profile-information__place-service-space">{institution.full_name}</p>
      <div className="medic-feature-profile-information__place-service-title">
        <NavLink to={`/city/${city?.id}/hospitals/${institution.id}?division_id=${division.id}`}>
          <i className="fas fa-hospital" />
          {division.full_name}
        </NavLink>
      </div>

      <MapLink
        doctorId={doctor?.id}
        division={division}
        className="medic-feature-profile-information__place-service-address"
        mapUrl={
          division.address.lat && division.address.lon
            ? `https://maps.google.com/maps/place/${division.address.lat},${division.address.lon}`
            : null
        }
        externalMapUrl
      />
      <div className="medic-feature-profile-information__place-service-position">{position.name}</div>
      <JobsPagination isPagesAvailable={isPagesAvailable} setKey={setKey} activeKey={activeKey} jobs={jobs} />
    </div>
  );
}
