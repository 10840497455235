import React from 'react';

import pdf from '../../../../assets/pdfs/laws.pdf';

const icon1 = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.958 20.73v-3.728a4.987 4.987 0 00-4.98-4.981h-.997v-1.993h.996c.55 0 .996-.446.996-.996V2.99A2.992 2.992 0 0020.985 0H9.03a2.992 2.992 0 00-2.989 2.989v6.043c0 .55.446.996.996.996h.996v1.993h-.996A4.987 4.987 0 002.058 17V27.03a4.987 4.987 0 004.98 4.98H20.09A6.951 6.951 0 0024.97 34c3.84 0 6.973-3.1 6.973-6.97a6.96 6.96 0 00-3.985-6.3zM8.034 2.989c0-.55.447-.997.996-.997h11.955c.55 0 .996.447.996.997v5.047h-1.993V4.98a.996.996 0 10-1.992 0v3.055h-1.992V4.98a.996.996 0 10-1.993 0v3.055H12.02V4.98a.996.996 0 10-1.992 0v3.055H8.034V2.989zm11.955 7.04v1.992h-9.962v-1.993h9.962zm-15.94 7.969h8.966c.55 0 .996.447.996.996v6.044c0 .549-.446.996-.996.996H4.05v-8.036zm2.99 12.02a2.993 2.993 0 01-2.818-1.992h8.794a2.992 2.992 0 002.989-2.988v-6.044a2.992 2.992 0 00-2.989-2.989H4.221a2.993 2.993 0 012.817-1.992h15.94a2.992 2.992 0 012.988 2.989v3.129c-4.222-.605-7.97 2.66-7.97 6.899 0 1.051.231 2.066.67 2.988H7.037zm12.95-2.988c0-.992.288-1.94.824-2.747l6.903 6.902c-3.308 2.189-7.727-.198-7.727-4.155zm9.137 2.747l-6.903-6.903a4.977 4.977 0 012.747-.823 4.975 4.975 0 014.98 4.979c0 .992-.288 1.94-.824 2.747z"
      fill="#1A7EF7"
    />
  </svg>
);

const icon2 = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.012 0h-7.969c-.765 0-1.463.289-1.992.763A2.976 2.976 0 0019.059 0h-7.97c-.46 0-.897.105-1.287.292A.998.998 0 009.098 0h-6.11A2.992 2.992 0 000 2.988v28.024A2.992 2.992 0 002.988 34h28.024A2.992 2.992 0 0034 31.012V2.988A2.992 2.992 0 0031.012 0zM10.094 2.988c0-.549.447-.996.996-.996h7.969c.549 0 .996.447.996.996v20.57a12.835 12.835 0 01-1.992-.648v-5.976a.996.996 0 00-.997-.997h-3.984a.996.996 0 00-.996.997v.916a12.872 12.872 0 01-1.992-6.893V2.988zM16.07 17.93v3.934a13.05 13.05 0 01-1.992-1.572V17.93h1.992zm15.938 13.082c0 .549-.447.996-.996.996H2.988a.997.997 0 01-.996-.996V2.988c0-.549.447-.996.996-.996h5.114v8.965c0 8.228 6.665 14.941 14.941 14.941h8.965v5.114zm-5.977-7.106V17.93h1.992v5.976h-1.992zm5.977 0h-1.992v-6.972a.996.996 0 00-.996-.997h-3.985a.996.996 0 00-.996.997v6.972h-.996c-.335 0-.667-.013-.996-.038V2.988c0-.549.447-.996.996-.996h7.969c.549 0 .996.447.996.996v20.918z"
      fill="#1A7EF7"
    />
    <path
      d="M27.027 3.984a2.992 2.992 0 00-2.988 2.989 2.992 2.992 0 002.988 2.988 2.992 2.992 0 002.989-2.988 2.992 2.992 0 00-2.989-2.989zm0 3.985a.997.997 0 010-1.992.997.997 0 010 1.992zM15.074 3.984a2.992 2.992 0 00-2.988 2.989 2.992 2.992 0 002.988 2.988 2.992 2.992 0 002.989-2.988 2.992 2.992 0 00-2.989-2.989zm0 3.985a.997.997 0 010-1.992.997.997 0 010 1.992zM29.02 11.953h-3.985a.996.996 0 100 1.992h3.985a.996.996 0 000-1.992zM17.066 11.953h-3.984a.996.996 0 000 1.992h3.984a.996.996 0 000-1.992zM11.09 23.906H4.98a.996.996 0 00-.996.996v3.985c0 .55.446.996.996.996h6.11c.55 0 .996-.446.996-.996v-3.985a.996.996 0 00-.996-.996zm-.996 3.985H5.977v-1.993h4.117v1.993z"
      fill="#1A7EF7"
    />
  </svg>
);

const newsItem = [
  {
    icon: icon1,
    description: 'Перевірка реєстрації або заборони серії лікарського засобу',
    link: 'https://xn--h1adc2i.xn--j1amh/consumers_drugs.html',
    linkDescription: 'ліки.укр',
  },
  {
    icon: icon2,
    description: 'Нормативна база реформи',
    link: pdf,
    linkDescription: 'likari.in.ua',
  },
];

const historyItem = [
  {
    description: 'Соколов Олександр Михайлович',
  },
  {
    description: 'Соколов',
  },
  {
    description: 'Педіатр',
  },
  {
    description: 'Педіатрія',
  },
];

export { newsItem, historyItem };
