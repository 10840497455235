import { createAction } from 'redux-actions';

import * as api from '../api';
import * as TYPES from '../constants/types';

const makeAnAppointmentRequest = createAction(TYPES.MAKE_AN_APPOINTMENT_REQUEST);
const makeAnAppointmentSuccess = createAction(TYPES.MAKE_AN_APPOINTMENT_SUCCESS);
const makeAnAppointmentFailure = createAction(TYPES.MAKE_AN_APPOINTMENT_FAILURE);

export const makeAnAppointment = ({ patient_id, body }) => (dispatch) => {
  dispatch(makeAnAppointmentRequest());

  return api
    .appoint({ patient_id, body })
    .then((response) => {
      dispatch(makeAnAppointmentSuccess({ ...response, patient_id }));
      return response;
    })
    .catch((error) => {
      dispatch(makeAnAppointmentFailure(error));
      return Promise.reject(error);
    });
};

const makeReservationRequest = createAction(TYPES.MAKE_RESERVATION_REQUEST);
const makeReservationSuccess = createAction(TYPES.MAKE_RESERVATION_SUCCESS);
const makeReservationFailure = createAction(TYPES.MAKE_RESERVATION_FAILURE);

export const makeReservation = (body) => (dispatch) => {
  dispatch(makeReservationRequest());

  return api
    .reservation(body)
    .then((response) => {
      dispatch(makeReservationSuccess(response));
      return response;
    })
    .catch((error) => {
      dispatch(makeReservationFailure(error));
      return Promise.reject(error);
    });
};

const khersonAppointmentRequest = createAction(TYPES.KHERSON_APPOINTMENT_REQUEST);
const khersonAppointmentSuccess = createAction(TYPES.KHERSON_APPOINTMENT_SUCCESS);
const khersonAppointmentFailure = createAction(TYPES.KHERSON_APPOINTMENT_FAILURE);

export const khersonAppointment = ({ target, id, body }) => (dispatch) => {
  dispatch(khersonAppointmentRequest());

  return api
    .requestAppointment({ target, id, body })
    .then((response) => dispatch(khersonAppointmentSuccess(response)))
    .catch((error) => dispatch(khersonAppointmentFailure(error)));
};

export const choosePatient = createAction(TYPES.CHOOSE_PATIENT);
export const updateAppointmentState = createAction(TYPES.UPDATE_APPOINTMENT_STATE);

const getCurrentAppointmentsRequest = createAction(TYPES.GET_CURRENT_APPOINTMENTS_REQUEST);
const getCurrentAppointmentsSuccess = createAction(TYPES.GET_CURRENT_APPOINTMENTS_SUCCESS);
const getCurrentAppointmentsFailure = createAction(TYPES.GET_CURRENT_APPOINTMENTS_FAILURE);

export const getCurrentAppointments = ({ patientId, query }) => (dispatch) => {
  dispatch(getCurrentAppointmentsRequest());

  return api
    .getAppointments({ query, patientId })
    .then((response) => {
      dispatch(getCurrentAppointmentsSuccess({ data: response, patientId }));
      return response;
    })
    .catch((error) => dispatch(getCurrentAppointmentsFailure(error)));
};

const getPastAppointmentsRequest = createAction(TYPES.GET_PAST_APPOINTMENTS_REQUEST);
const getPastAppointmentsSuccess = createAction(TYPES.GET_PAST_APPOINTMENTS_SUCCESS);
const getPastAppointmentsFailure = createAction(TYPES.GET_PAST_APPOINTMENTS_FAILURE);

export const getPastAppointments = ({ query = '', patientId }) => (dispatch) => {
  dispatch(getPastAppointmentsRequest());

  return api
    .getAppointments({ query: `?past=true&${query}`, patientId })
    .then((response) => {
      dispatch(getPastAppointmentsSuccess({ data: response, patientId }));
      return response;
    })
    .catch((error) => dispatch(getPastAppointmentsFailure(error)));
};

const cancelAppointmentRequest = createAction(TYPES.CANCEL_APPOINTMENT_REQUEST);
const cancelAppointmentSuccess = createAction(TYPES.CANCEL_APPOINTMENT_SUCCESS);
const cancelAppointmentFailure = createAction(TYPES.CANCEL_APPOINTMENT_FAILURE);

export const cancelAppointment = (id) => (dispatch) => {
  dispatch(cancelAppointmentRequest());

  return api
    .cancelAppointment(id)
    .then(() => dispatch(cancelAppointmentSuccess({ id })))
    .catch((error) => dispatch(cancelAppointmentFailure({ error, id })));
};

export const makeAnOperatorAppointment = (body) => (dispatch) => {
  dispatch(makeAnAppointmentRequest());

  return api
    .operatorAppoint(body)
    .then((response) => {
      dispatch(makeAnAppointmentSuccess({ ...response, patient_id: body.patient_id }));
      return response;
    })
    .catch((error) => {
      dispatch(makeAnAppointmentFailure(error));
      return Promise.reject(error);
    });
};

export const makeAnOperatorReservation = (body) => (dispatch) => {
  dispatch(makeReservationRequest());

  return api
    .makeOperatorReservation(body)
    .then((response) => {
      dispatch(makeReservationSuccess({ ...response, patient_id: body.patient_id }));
      return response;
    })
    .catch((error) => {
      dispatch(makeReservationFailure(error));
      return Promise.reject(error);
    });
};

const findOperatorPatientsRequest = createAction(TYPES.FIND_OPERATOR_PATIENTS_REQUEST);
const findOperatorPatientsSuccess = createAction(TYPES.FIND_OPERATOR_PATIENTS_SUCCESS);
const findOperatorPatientsFailure = createAction(TYPES.FIND_OPERATOR_PATIENTS_FAILURE);

export const findOperatorPatients = (phone) => (dispatch) => {
  dispatch(findOperatorPatientsRequest());

  return api
    .findOperatorPatients(phone)
    .then((response) => {
      dispatch(findOperatorPatientsSuccess(response));
      return response;
    })
    .catch((error) => {
      dispatch(findOperatorPatientsFailure(error));

      return Promise.reject(error);
    });
};
