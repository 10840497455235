import React from 'react';

import Phones from './phones';
import { RemainedMedicines } from './remainedMedicines';

export default function About({ phones, remainedMedicinesUrl, show }) {
  if (!show) return null;

  return (
    <div className="institution-about__information">
      <div className="institution-about__information-period">
        <div className="institution-about__information-connection">
          <i className="fas fa-phone-alt" />
          <Phones phones={phones} />
        </div>
      </div>
      <div className="institution-about__remained-medicines">
        <RemainedMedicines remainedMedicinesUrl={remainedMedicinesUrl} />
      </div>
    </div>
  );
}
