import React from 'react';
import { Nav } from 'react-bootstrap';

export default function MapTabLink({ eventKey, list }) {
  return (
    <Nav.Link className="map-search-link" eventKey={eventKey}>
      {list ? (
        <>
          <i className="fas fa-align-left" />
          Списком
        </>
      ) : (
        <>
          <i className="far fa-map" />
          На мапi
        </>
      )}
    </Nav.Link>
  );
}
