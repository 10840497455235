import { LOCATION_CHANGE } from 'react-router-redux';
import * as TYPES from '../constants/types';

const initialState = {
  currentLoading: false,
  pastLoading: false,
  cancelLoading: false,
  currentLoaded: false,
  pastLoaded: false,
  cancelLoaded: false,
  error: null,
  current: {
    data: [],
    pagination: {},
    patientId: 0,
  },
  past: {
    data: [],
    pagination: {},
    patientId: 0,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_CURRENT_APPOINTMENTS_REQUEST:
      return {
        ...state,
        currentLoading: true,
        currentLoaded: false,
        error: null,
      };

    case TYPES.GET_CURRENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        currentLoading: false,
        currentLoaded: true,
        current: {
          data: action.payload.data.data,
          pagination: action.payload.data.pagination,
          patientId: action.payload.patientId,
        },
      };

    case TYPES.GET_CURRENT_APPOINTMENTS_FAILURE:
      return {
        ...state,
        currentLoading: false,
        currentLoaded: true,
        error: action.payload,
      };

    case TYPES.CANCEL_APPOINTMENT_REQUEST:
      return { ...state, cancelLoading: true, cancelLoaded: false };

    case TYPES.CANCEL_APPOINTMENT_SUCCESS:
    case TYPES.CANCEL_APPOINTMENT_FAILURE: {
      const { current } = state;

      return {
        ...state,
        cancelLoading: false,
        cancelLoaded: true,
        current: {
          ...current,
          pagination: current.pagination,
          data: current.data.filter(item => +item.id !== +action.payload.id),
        },
      };
    }

    case TYPES.GET_PAST_APPOINTMENTS_REQUEST:
      return {
        ...state,
        pastLoading: true,
        pastLoaded: false,
        error: null,
      };

    case TYPES.GET_PAST_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        pastLoading: false,
        pastLoaded: true,
        past: {
          data: action.payload.data.data,
          pagination: action.payload.data.pagination,
          patientId: action.payload.patientId,
        },
      };

    case TYPES.GET_PAST_APPOINTMENTS_FAILURE:
      return {
        ...state,
        pastLoading: false,
        pastLoaded: true,
        error: action.payload,
      };

    case LOCATION_CHANGE:
      return initialState;

    default:
      return state;
  }
}
