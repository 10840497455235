import { useState, useCallback, useEffect } from 'react';

const defaultMapZoom = 11.5;
const defaultActiveMarkerMapZoom = 17.0;

export function useGoogleMapApi({ data, activeMarker, mapZoom, mapCenter, city }) {
  const [currentMapZoom, setCurrentMapZoom] = useState(defaultMapZoom);
  const [markers, setMarkers] = useState([]);
  const [currentActiveMarker, setCurrentActiveMarker] = useState(activeMarker);

  useEffect(() => {
    setMarkers(data);
  }, [data]);

  useEffect(() => {
    if (mapZoom) setCurrentMapZoom(mapZoom);
  }, [mapZoom]);

  useEffect(() => {
    if (activeMarker) {
      setCurrentMapZoom(defaultActiveMarkerMapZoom);
      setCurrentActiveMarker(activeMarker);
    }
  }, [activeMarker]);

  const onMarkerClick = useCallback(
    (clickedMarker) => () => {
      setCurrentActiveMarker(clickedMarker);
      setMarkers(
        markers.map((marker) => {
          if (marker.id !== clickedMarker.id)
            return {
              ...marker,
              icon: marker.iconsList.icon,
              label: activeMarker ? null : { ...marker.label, color: marker.labelsColor.color },
            };

          return {
            ...marker,
            icon: marker.iconsList.activeIcon,
            label: activeMarker ? null : { ...marker.label, color: marker.labelsColor.activeColor },
          };
        }),
      );
    },
    [activeMarker, markers],
  );

  const onMapClick = useCallback(() => {
    if (currentActiveMarker) {
      setCurrentActiveMarker(null);
      setMarkers(
        markers.map((marker) => ({
          ...marker,
          icon: marker.iconsList.icon,
          label: activeMarker ? null : { ...marker.label, color: marker.labelsColor.color },
        })),
      );
    }
  }, [activeMarker, currentActiveMarker, markers]);

  const getMapCenter = useCallback(() => {
    if (mapCenter) return mapCenter;

    if (activeMarker) return activeMarker.position;

    return city?.position ?? { lat: 0, lng: 0 };
  }, [activeMarker, city, mapCenter]);

  return {
    markers,
    currentActiveMarker,
    onMarkerClick,
    onMapClick,
    getMapCenter,
    currentMapZoom,
  };
}
