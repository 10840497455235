import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import HospitalDivision from './common/HospitalDivision';
import Hospital from '../list/divisionPageList/common/Division';
import HeaderSearch from '../components/global/header/headerSearch';
import Footer from '../components/global/footer/index';
import { useHospitalPageApi } from './api';
import { useSetCity } from '../../hooks/useSetCity';

export function HospitalPageComponent({ hospital, getHospital, user, updateCity }) {
  const { city } = useSetCity({ user, updateCity });
  const { currentDivision, setCurrentDivision, divisionNotExists } = useHospitalPageApi({
    getHospital,
    hospital,
  });

  if (!city || (hospital.loaded && !hospital.data) || divisionNotExists) return <Redirect to="/not-found-page" />;

  return (
    <div>
      <HeaderSearch city={city} breadCrumbs={{ className: 'hospital-page' }} />

      <main>
        <div className="institution-wrapper">
          <Container>
            {hospital.data && (
              <>
                <Hospital
                  institutionId={hospital.data.id}
                  full_name={hospital.data.full_name}
                  address={hospital.data.address}
                  remainedMedicinesUrl={hospital.data.remained_medicines_url}
                  phones={hospital.data.contacts.filter((contact) => contact.type === 'phone')}
                  photo={hospital.data.photo}
                  mapUrl={
                    hospital.data.address.lat && hospital.data.address.lon
                      ? `https://maps.google.com/maps/place/${hospital.data.address.lat},${hospital.data.address.lon}`
                      : null
                  }
                  rating={hospital.data.rating}
                  description={hospital.data.description}
                  city={city}
                  disableOwnLink
                  externalMapUrl
                />

                {!!hospital.data.divisions.length && (
                  <HospitalDivision
                    city={city}
                    currentDivision={currentDivision}
                    setCurrentDivision={setCurrentDivision}
                    divisions={hospital.data.divisions}
                  />
                )}
              </>
            )}
            {hospital.loaded && !hospital.data && <div className="institution-not-found">Лікарню не знайдено</div>}
          </Container>
        </div>
      </main>

      <Footer />
    </div>
  );
}
