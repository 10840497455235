import React from 'react';

import RecipesList from './resipesList';

export default function VisitRecipes({ drugs }) {
  return (
    <div className="personal-recipes">
      <div className="personal-recipes-title">
        Рецепти препаратів щодо візиту <span>({drugs.length})</span>
      </div>

      <RecipesList drugs={drugs} />
    </div>
  );
}
