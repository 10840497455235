import { useState, useMemo } from 'react';

export default function useDoctorApi({ jobs }) {
  const [timeSlot, setTimeSlot] = useState();
  const [isVisibleAppointmentsCalendar, setIsVisibleAppointmentsCalendar] = useState(false);
  const isDirect = useMemo(() => jobs.some((job) => job.direct_only === true), [jobs]);

  return {
    timeSlot,
    setTimeSlot,
    isVisibleAppointmentsCalendar,
    setIsVisibleAppointmentsCalendar,
    isDirect,
  };
}
