import { createAction } from 'redux-actions';

import * as TYPES from '../constants/types';
import * as api from '../api';

const getPatientsRequest = createAction(TYPES.GET_PATIENTS_REQUEST);
const getPatientsSuccess = createAction(TYPES.GET_PATIENTS_SUCCESS);
const getPatientsFailure = createAction(TYPES.GET_PATIENTS_FAILURE);

export const fetchPatients = (id) => (dispatch) => {
  dispatch(getPatientsRequest());

  return api
    .getPatients(id)
    .then((response) => {
      dispatch(getPatientsSuccess(response.data));
      return response;
    })
    .catch((error) => dispatch(getPatientsFailure(error)));
};

const getPatientInfoRequest = createAction(TYPES.GET_PATIENT_INFO_REQUEST);
const getPatientInfoSuccess = createAction(TYPES.GET_PATIENT_INFO_SUCCESS);
const getPatientInfoFailure = createAction(TYPES.GET_PATIENT_INFO_FAILURE);

export const getPatientInfo = (id) => (dispatch) => {
  dispatch(getPatientInfoRequest());

  return api
    .getPatient(id)
    .then((response) => {
      dispatch(getPatientInfoSuccess(response.data));
      return response;
    })
    .catch((error) => dispatch(getPatientInfoFailure(error)));
};

const getPatientRecipesRequest = createAction(TYPES.GET_PATIENTS_RECIPES_REQUEST);
const getPatientRecipesSuccess = createAction(TYPES.GET_PATIENTS_RECIPES_SUCCESS);
const getPatientRecipesFailure = createAction(TYPES.GET_PATIENTS_RECIPES_FAILURE);

export const getPatientsRecipes = (id) => (dispatch) => {
  dispatch(getPatientRecipesRequest());

  return api
    .patientsRecipes(id)
    .then((response) => {
      dispatch(getPatientRecipesSuccess(response));
      return response;
    })
    .catch((error) => dispatch(getPatientRecipesFailure(error)));
};

const getPatientContactsRequest = createAction(TYPES.GET_PATIENT_VISITS_REQUEST);
const getPatientContactsSuccess = createAction(TYPES.GET_PATIENT_VISITS_SUCCESS);
const getPatientContactsFailure = createAction(TYPES.GET_PATIENT_VISITS_FAILURE);

export const getPatientVisits = (id, page) => (dispatch) => {
  dispatch(getPatientContactsRequest());

  return api
    .getPatientVisits(id, page)
    .then((response) => dispatch(getPatientContactsSuccess(response)))
    .catch((error) => dispatch(getPatientContactsFailure(error)));
};

const getPatientBarCodeRequest = createAction(TYPES.GET_PATIENT_BAR_CODE_REQUEST);
const getPatientBarCodeSuccess = createAction(TYPES.GET_PATIENT_BAR_CODE_SUCCESS);
const getPatientBarCodeFailure = createAction(TYPES.GET_PATIENT_BAR_CODE_FAILURE);

export const getPatientBarCode = (patientId) => (dispatch) => {
  dispatch(getPatientBarCodeRequest());

  return api
    .getPatientCode(patientId, 'bar-code')
    .then((response) => response.text())
    .then((response) => dispatch(getPatientBarCodeSuccess({ response, patientId })))
    .catch((error) => dispatch(getPatientBarCodeFailure({ error, patientId })));
};

const getPatientQrCodeRequest = createAction(TYPES.GET_PATIENT_QR_CODE_REQUEST);
const getPatientQrCodeSuccess = createAction(TYPES.GET_PATIENT_QR_CODE_SUCCESS);
const getPatientQrCodeFailure = createAction(TYPES.GET_PATIENT_QR_CODE_FAILURE);

export const getPatientQrCode = (patientId) => (dispatch) => {
  dispatch(getPatientQrCodeRequest());

  return api
    .getPatientCode(patientId, 'qr-code')
    .then((response) => response.text())
    .then((response) => dispatch(getPatientQrCodeSuccess({ response, patientId })))
    .catch((error) => dispatch(getPatientQrCodeFailure({ error, patientId })));
};
