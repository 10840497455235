import React from 'react';
import { NavLink } from 'react-router-dom';

export default function AppointmentsCalendarDaySubModal({ show, dayData, onClose, city }) {
  if (!show || !dayData) return null;

  const onButtonClick = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <>
      <div className="sub-modal-backdrop" />
      <div className="sub-modal">
        <div className="sub-modal-header">
          <div className="sub-modal-header__title">{dayData.title}</div>
          <button type="button" className="close" onClick={onButtonClick}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="sub-modal-body">
          {dayData.data.map((data) => (
            <div
              className={`calendar-day__information${['ABSENT', 'PAST'].includes(data.status) ? ' off' : ''}`}
              key={`${data.job_id}-${data.time_from}`}
            >
              {data.status === 'ABSENT' && (
                <div className="calendar-day__information-off">
                  <i className="fas fa-suitcase-rolling" /> {data.absence_reason}
                </div>
              )}

              <div className="calendar-day__information-title">
                {data.time_from} - {data.time_to}
              </div>
              <NavLink
                to={`/city/${city?.id}/hospitals/${data.job.institution.id}?division_id=${data.job.division.id}`}
                className="calendar-day__information-description"
              >
                {data.job.division.full_name}
              </NavLink>

              {data.substitution && (
                <div className="calendar-day__information-replace">
                  <div className="calendar-day__information-replace-title">Лікаря замінює:</div>
                  <NavLink
                    to={`/city/${city?.id}/doctors/${data.substitution.id}`}
                    className="calendar-day__information-replace-description"
                  >
                    {data.substitution.full_name}
                  </NavLink>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
