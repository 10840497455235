import React from 'react';
import { Link } from 'react-router-dom';
import { InfoWindow } from '@react-google-maps/api';

import RatingList from './ratingList';

export default function MarkerInfo({ marker, city, onMarkerInfoClose, isVaccination, disableLink }) {
  const handleLinkClick = (e) => {
    if (disableLink) e.preventDefault();
  };

  if (!marker) return null;

  return (
    <InfoWindow
      position={marker.position}
      onCloseClick={onMarkerInfoClose}
      options={{ pixelOffset: { height: -45, width: -10 } }}
    >
      <div className="google-map-overlay">
        {marker.data.map((item) => (
          <div className="google-map-overlay__element" key={item.id}>
            <div className="google-map-overlay__title">
              <Link
                onClick={handleLinkClick}
                to={
                  disableLink
                    ? '/'
                    : `/city/${city?.id}${isVaccination ? item.vaccination_title_link : item.title_link}`
                }
              >
                {item.title}
              </Link>
            </div>

            {item.content ? <div className="google-map-overlay__content">{item.content}</div> : null}

            <RatingList rating={item.rating} />
          </div>
        ))}
      </div>
    </InfoWindow>
  );
}
