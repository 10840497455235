import { useEffect } from 'react';

import { useUrlParser } from '../../../utils/useUrlParser';

export function useDivisionPageListApi({ divisions, getDivisions, city, currentDivisionsOrderBy }) {
  const urlParser = useUrlParser();

  useEffect(() => {
    if (
      city?.id &&
      !divisions.loading &&
      (!divisions.loaded || (divisions.orderBy && divisions.orderBy.key !== currentDivisionsOrderBy?.key))
    ) {
      const divisionsPage = +urlParser.urlData.params.divisions_page;
      const page = divisionsPage > 0 && Number.isInteger(divisionsPage) ? divisionsPage : 1;

      getDivisions({
        cityId: city.id,
        page,
        specializationId: urlParser.urlData.params.specialization_id,
        name: urlParser.urlData.params.name,
        orderBy: currentDivisionsOrderBy,
      });
    }
  }, [
    city,
    currentDivisionsOrderBy,
    divisions.loaded,
    divisions.loading,
    divisions.orderBy,
    getDivisions,
    urlParser.urlData.params.divisions_page,
    urlParser.urlData.params.name,
    urlParser.urlData.params.specialization_id,
  ]);

  useEffect(() => {
    if (divisions.loaded) window.scrollTo(0, 0);
  }, [divisions.currentPage, divisions.loaded]);

  return {};
}
