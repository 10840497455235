import { LOCATION_CHANGE } from 'react-router-redux';
import * as TYPES from '../../constants/types';

const initialState = {
  data: [],
  pagination: {
    total: 0,
    total_pages: 1,
  },
  currentPage: 1,
  orderBy: null,
  loading: false,
  loaded: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_DIVISIONS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case TYPES.GET_DIVISIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.response.data,
        pagination: action.payload.response.pagination,
        currentPage: action.payload.page,
        orderBy: action.payload.orderBy,
        loading: false,
        loaded: true,
      };

    case TYPES.GET_DIVISIONS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        currentPage: action.payload.page,
        orderBy: action.payload.orderBy,
        error: action.payload.error,
      };

    case LOCATION_CHANGE:
      return initialState;

    default:
      return state;
  }
}
