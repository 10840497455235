import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Indicators = ({ indicators }) => {
  if (!indicators || !indicators.length) return null;

  return (
    <li className="medic-feature-profile-detail-list__item">
      <div className="medic-feature-profile-detail__title">Показники</div>

      <ul className="medic-feature-profile-detail__indicator">
        {indicators.map(({ key, description, name, units, value }) => (
          <li className="medic-feature-profile-detail__indicator-item" key={key}>
            <div className="medic-feature-profile-detail__indicator-item-description">{name}:</div>
            <div className="medic-feature-profile-detail__indicator-item-count">
              <strong>{value}</strong> {units}
              <OverlayTrigger overlay={<Tooltip>{description}</Tooltip>}>
                <span>
                  <i className="far fa-question-circle" />
                </span>
              </OverlayTrigger>
            </div>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default Indicators;
