import React from 'react';
import { Container } from 'react-bootstrap';
import HeaderLogo from './logo';
import HeaderButton from './button';
import VaccinationButton from './vaccinationButton';

export default function Header({ searchFocused, user }) {
  return (
    <header className={`header${searchFocused ? ' search-focused' : ''}`}>
      <Container>
        <HeaderLogo city={user?.city} />

        <VaccinationButton user={user} />

        <HeaderButton />
      </Container>
    </header>
  );
}
