import React from 'react';
import { Col } from 'react-bootstrap';

import Medic from './medic';
import Pagination from '../../../../components/global/List/Pagination';
import { useDivisionMedicsApi } from './api';

export function DivisionMedicsComponent({ divisionId, divisionMedics, getDivisionMedics, city }) {
  const { colRef } = useDivisionMedicsApi({
    divisionId,
    divisionMedics,
    getDivisionMedics,
    city,
  });

  return (
    <Col xs={{ span: 12 }} lg={{ span: 6 }} ref={colRef}>
      <div className="institution-info__medic">
        <div className="institution-info__medic-title">Лікарі відділення</div>
        <ul className="institution-info__medic-list">
          {divisionMedics.data.length ? (
            divisionMedics.data.map((medic) => <Medic {...medic} key={medic.id} city={city} />)
          ) : (
            <li className="institution-info__medic-list-item">
              <div className="institution-info__medic-about">
                <div className="institution-info__medic-info-subtitle empty">Немає даних</div>
              </div>
            </li>
          )}
        </ul>

        <Pagination
          currentPage={divisionMedics.currentPage}
          totalPages={divisionMedics.pagination.total_pages}
          pageName="page"
        />
      </div>
    </Col>
  );
}
