import { connect } from 'react-redux';

import AppointmentComponent from '../next/appointment';
import { clearAuthState, sendSms, syncBot } from '../actions';
import { fetchPatients } from '../actions/patientsActions';
import { getDoctorSchedule } from '../actions/doctors';
import {
  choosePatient,
  makeAnAppointment,
  makeReservation,
  updateAppointmentState,
  makeAnOperatorAppointment,
  findOperatorPatients,
  makeAnOperatorReservation,
} from '../actions/appointmentsActions';

const mapStateToProps = ({ user, patients, appointment, auth }) => ({
  user,
  patients,
  appointment,
  auth,
});

const mapDispatchToProps = {
  fetchPatients,
  makeAnAppointment,
  makeAnOperatorAppointment,
  findOperatorPatients,
  makeReservation,
  updateAppointmentState,
  makeAnOperatorReservation,
  clearAuthState,
  choosePatient,
  sendSms,
  getDoctorSchedule,
  syncBot,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentComponent);
