import { useCallback } from 'react';
import { useLocation } from 'react-router';

export function useDivisionListModalApi({ setCurrentDivision, onClose }) {
  const location = useLocation();

  const setDivision = useCallback(
    division => () => {
      window.history.pushState('', '', `${location.pathname}?division_id=${division.id}`);
      setCurrentDivision(division);
      onClose();
    },
    [location.pathname, onClose, setCurrentDivision],
  );

  return {
    setDivision,
  };
}
