import React from 'react';
import { NavLink } from 'react-router-dom';

export default function DoctorMapLink({ doctorId, division, className, mapUrl, externalMapUrl = false }) {
  if (!division.address.display_text) return null;

  const ExternalComponent = !division.address.lat || !division.address.lon ? 'span' : 'a';

  if (externalMapUrl) {
    return (
      <div className={className}>
        <ExternalComponent
          href={ExternalComponent === 'a' ? mapUrl : null}
          target={ExternalComponent === 'a' ? '_blank' : null}
          rel={ExternalComponent === 'a' ? 'noopener noreferrer' : null}
        >
          <i className="fas fa-map-marker-alt" />
          {division.address.display_text}
        </ExternalComponent>
      </div>
    );
  }

  if (!mapUrl) return null;

  const Component = !division.address.lat || !division.address.lon ? 'div' : NavLink;

  return (
    <div className={className}>
      <Component to={Component === NavLink ? `/doctors/${doctorId}/map?division_id=${division.id}` : null}>
        <i className="fas fa-map-marker-alt" /> {division.address.display_text}
      </Component>
    </div>
  );
}
