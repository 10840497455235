import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Contacts from '../../common/contacts';
import Specializations from '../../../list/doctorsPageList/common/Doctor/specializations';
import defaultMedicSvg from '../../../../assets/img/next/svg/medic.svg';
import { getDoctor } from '../../../../api';
import TextLoader from '../../../components/global/Loader/text';
import { Job } from './job';

export default function PersonalMedic({ patient, user }) {
  const [doctor, setDoctor] = useState(null);

  useEffect(() => {
    if (
      (patient.data?.registration && !doctor) ||
      (doctor && patient.data?.registration?.doctor_id && patient.data.registration.doctor_id !== doctor.data.id)
    ) {
      getDoctor(patient.data.registration.doctor_id).then((response) => {
        setDoctor(response);
      });
    }
  }, [doctor, patient.data]);

  if (!doctor) return <TextLoader />;

  const { full_name, specializations, contacts, jobs, photo, id } = doctor.data;

  return (
    <div className="personal-medic-wrapper">
      <div className="personal-medic">
        <ul className="personal-medic-list">
          <li className="personal-medic-list__item">
            <div className="personal-medic-list-item__data">
              <div
                className="personal-medic-list-item__data-avatar"
                style={{ backgroundImage: `url(${photo ?? defaultMedicSvg})` }}
              />
              <div className="personal-medic-list-item__data-about">
                <div className="personal-medic-list-item__data-about-title">{full_name}</div>
                <div className="personal-medic-list-item__data-about-subtitle">
                  <Specializations specializations={specializations} />
                </div>
              </div>
            </div>
          </li>

          <Contacts contacts={contacts} className="personal-medic-list__item" />

          <li className="personal-medic-list__item">
            <NavLink
              to={`/city/${user.city?.id}/doctors/${id}`}
              className="personal-medic-list__item-button btn btn-primary"
            >
              <i className="far fa-calendar-alt" /> Записатися на прийом
            </NavLink>
          </li>
        </ul>
      </div>

      {jobs?.length && (
        <div className="personal-medic">
          <div className="personal-medic-title">Місце роботи</div>
          <ul className="personal-medic-list personal-medic-list--workspace">
            <li className="personal-medic-list__item">
              {jobs.map((job) => (
                <Job key={job.id} job={job} user={user} />
              ))}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
