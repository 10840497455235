import React from 'react';

import { MAX_RATING } from '../../../../constants/rating';

const stars = (items, className) =>
  [...Array(items).keys()].map(item => (
    <li className="rating-list-item" key={item}>
      <i className={`${className} fa-star`} />
    </li>
  ));

export default function RatingList({ rating }) {
  if (!rating) return null;

  const currentRating = Math.round(rating);

  return (
    <div className="google-map-overlay__rating-list">
      <ul className="rating-list">
        {stars(currentRating, 'fas')}
        {stars(MAX_RATING - currentRating, 'far')}
      </ul>
      <div className="rating-numeral">
        <strong>{currentRating}</strong>/{MAX_RATING}
      </div>
    </div>
  );
}
