import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function useDoctorPageApi({ getDoctor }) {
  const { doctorId } = useParams();

  useEffect(() => {
    getDoctor(doctorId);
  }, [getDoctor, doctorId]);
}

export function useDoctorScheduleApi({ doctor, getDoctorSchedule, doctorSchedule, getDoctorsByDaysSchedule, city }) {
  useEffect(() => {
    if (doctor.data && city?.settings?.show_map) {
      const data = {
        date_from: doctorSchedule.initialDateFrom,
        date_to: doctorSchedule.initialDateTo,
        ids: doctor.data.id,
      };

      getDoctorSchedule(data);
      getDoctorsByDaysSchedule(data);
    }
  }, [
    getDoctorSchedule,
    doctor.data,
    doctorSchedule.initialDateFrom,
    doctorSchedule.initialDateTo,
    getDoctorsByDaysSchedule,
    city,
  ]);
}
