import React, { useState, useCallback } from 'react';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';

import { sendSms } from '../../actions';
import ReferralInput from '../components/refferal';

const transformPhoneNumber = (phone) => phone.replace(/[()\s]/g, '');

const Phone = ({ sendSms, auth: { error }, timeSlot, children, notice, referralNumber, setReferralNumber }) => {
  const [isAgree, setAgreement] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    trigger,
  } = useForm({ reValidateMode: 'onBlur' });

  const onSubmit = useCallback(
    (data) => {
      sendSms(transformPhoneNumber(data.phone));
    },
    [sendSms],
  );

  const onChangePhone = () => {
    if (formErrors.phone) {
      trigger('phone');
    }
  };

  const onCheck = () => setAgreement((prevState) => !prevState);

  return (
    <form className="confirmation_of_entry phone_form" onSubmit={handleSubmit(onSubmit)}>
      <ReferralInput
        register={register}
        style={{ marginBottom: '10px' }}
        timeSlot={timeSlot}
        referralNumber={referralNumber}
        setReferralNumber={setReferralNumber}
        formErrors={formErrors}
        trigger={trigger}
      />

      {children || (
        <p className="appointment-auth-text">
          Вкажіть номер телефону, який Ви вказували при укладення декларації з Вашим сімейним лікарем
        </p>
      )}

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={error && typeof error === 'object' && error.phone ? 'error' : ''} aria-required>
        <InputMask
          mask="(099) 999 99 99"
          type="tel"
          name="phone"
          placeholder="(067) 111-22-33"
          onChange={onChangePhone}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              ref={register({
                required: {
                  value: true,
                  message: "Поле обов'язкове для заповнення",
                },
                pattern: {
                  value: /^\(\d{3}\) \d{3} \d{2} \d{2}$/,
                  message: 'Невірний формат',
                },
              })}
            />
          )}
        </InputMask>
        {formErrors.phone && <div className="validation_error">{formErrors.phone.message}</div>}
        {error && typeof error === 'object' && error.phone && <span className="error_text">{error.phone}</span>}
      </label>
      {notice && <p className="small">{notice}</p>}

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="checkbox" aria-required>
        <input
          type="checkbox"
          name="agree"
          checked={isAgree}
          ref={register({ required: true, validate: (value) => value === true })}
          onChange={onCheck}
          required
        />
        <div className="checkbox__text">
          Я підтверджую готовність отримати на мій телефон SMS-запит з кодом підтведження
        </div>
      </label>
      <Button type="submit" variant="primary" className="submit-button">
        Підтверджую
      </Button>
    </form>
  );
};

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = { sendSms };

export default connect(mapStateToProps, mapDispatchToProps)(Phone);
