import React from 'react';
import { Dropdown } from 'react-bootstrap';

import UserOfficeSvgGrey from '../../../../assets/img/gray-user.svg';

export default function PatientsSelect({ patients, setPatient }) {
  if (!patients.data.length || patients.data.length === 1) return null;

  return (
    <Dropdown.Menu>
      {patients.data.map(({ full_name, id }) => (
        <Dropdown.Item className="user-cabinet-navigation__item-description" onClick={() => setPatient(id)} key={id}>
          <div className="user-cabinet-navigation__item-description-icon">
            <img src={UserOfficeSvgGrey} alt="user" />
          </div>
          {full_name}
          <div className="user-cabinet-navigation__item-description-arrow">
            <i className="fas fa-chevron-right" />
          </div>
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );
}
