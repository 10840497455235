import React from 'react';

import { SpecializationItem } from './specializationItem';

export function Specializations({ specializations, user }) {
  if (!specializations.length) return null;

  return (
    <div className="search-field-dropdown-result-item">
      <div className="search-field-dropdown-result-content-wrapper">
        <div className="search-field-dropdown-result-item-content-title">Cпеціальності</div>

        <div className="search-field-dropdown-result-content">
          {specializations.slice(0, 3).map((specialization) => (
            <SpecializationItem specialization={specialization} key={specialization.id} user={user} />
          ))}
        </div>
      </div>
    </div>
  );
}
