import React from 'react';
import { appList } from './helpers';

const AppsList = () => {
  return (
    <ul className="apps-list">
      {appList.map(({ item, link, alt }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className="apps-list__item" key={index}>
          <a href={link} className="apps-list__link">
            <img src={item} alt={alt} />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default AppsList;
