import { createAction } from 'redux-actions';

import * as TYPES from '../constants/types';
import * as api from '../api';

const getDivisionsRequest = createAction(TYPES.GET_DIVISIONS_REQUEST);
const getDivisionsSuccess = createAction(TYPES.GET_DIVISIONS_SUCCESS);
const getDivisionsFailure = createAction(TYPES.GET_DIVISIONS_FAILURE);

export const getDivisions = ({
  cityId,
  page = 1,
  name,
  institutionId,
  specializationId,
  orderBy,
  isVaccinationPlace,
  districtId,
  eHealthUid,
}) => (dispatch) => {
  dispatch(getDivisionsRequest());

  return api
    .getDivisions({
      cityId,
      page,
      name,
      institutionId,
      districtId,
      specializationId,
      orderBy,
      isVaccinationPlace,
      eHealthUid,
    })
    .then((response) => dispatch(getDivisionsSuccess({ response, page, orderBy })))
    .catch((error) => dispatch(getDivisionsFailure({ error, page, orderBy })));
};

const getDivisionsMapDataRequest = createAction(TYPES.GET_DIVISIONS_MAP_DATA_REQUEST);
const getDivisionsMapDataSuccess = createAction(TYPES.GET_DIVISIONS_MAP_DATA_SUCCESS);
const getDivisionsMapDataFailure = createAction(TYPES.GET_DIVISIONS_MAP_DATA_FAILURE);

export const getDivisionsMapData = ({ cityId, name, specializationId, isVaccinationPlace, districtId }) => (
  dispatch,
) => {
  dispatch(getDivisionsMapDataRequest());

  return api
    .getDivisions({ cityId, name, specializationId, isVaccinationPlace, districtId, pageSize: 10000 })
    .then((response) => dispatch(getDivisionsMapDataSuccess({ response })))
    .catch((error) => dispatch(getDivisionsMapDataFailure(error)));
};

const getDivisionMapDataRequest = createAction(TYPES.GET_DIVISION_MAP_DATA_REQUEST);
const getDivisionMapDataSuccess = createAction(TYPES.GET_DIVISION_MAP_DATA_SUCCESS);
const getDivisionMapDataFailure = createAction(TYPES.GET_DIVISION_MAP_DATA_FAILURE);

export const getDivisionMapData = ({ divisionId }) => (dispatch) => {
  dispatch(getDivisionMapDataRequest());

  return api
    .getDivision(divisionId)
    .then((response) => dispatch(getDivisionMapDataSuccess({ response })))
    .catch((error) => dispatch(getDivisionMapDataFailure(error)));
};

const getHospitalRequest = createAction(TYPES.GET_HOSPITAL_REQUEST);
const getHospitalSuccess = createAction(TYPES.GET_HOSPITAL_SUCCESS);
const getHospitalFailure = createAction(TYPES.GET_HOSPITAL_FAILURE);

export const getHospital = (id) => (dispatch) => {
  dispatch(getHospitalRequest());

  return api
    .getHospital(id)
    .then((response) => dispatch(getHospitalSuccess(response.data)))
    .catch((error) => dispatch(getHospitalFailure(error)));
};

const getDivisionMedicsRequest = createAction(TYPES.GET_DIVISION_MEDICS_REQUEST);
const getDivisionMedicsSuccess = createAction(TYPES.GET_DIVISION_MEDICS_SUCCESS);
const getDivisionMedicsFailure = createAction(TYPES.GET_DIVISION_MEDICS_FAILURE);

export const getDivisionMedics = ({ cityId, divisionId, page = 1, pageSize = 10 }) => (dispatch) => {
  dispatch(getDivisionMedicsRequest());

  return api
    .getDivisionMedics({ cityId, divisionId, page, pageSize })
    .then((response) => dispatch(getDivisionMedicsSuccess({ response, page, divisionId })))
    .catch((error) => dispatch(getDivisionMedicsFailure(error)));
};
