import moment from 'moment';

import * as TYPES from '../constants/types';
import { parseJwt } from '../utils/parseJwt';

const initialState = {
  city: null,
  initialCity: null,
  error: null,
  cities: [],
  token: localStorage.getItem('token') ?? '',
  name: localStorage.getItem('username') ?? '',
  datetime: moment().toISOString(),
  declarationSigned: false,
  showBotPanel: false,
  showBotPopup: false,
  tokenRefreshing: false,
  settingsLoaded: false,
  initialCityLoaded: false,
  citiesLoaded: false,
  districtsLoaded: false,
  districts: [],
  district: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_SETTINGS_REQUEST:
      return { ...state };

    case TYPES.GET_SETTINGS_SUCCESS: {
      const { datetime, show_bot_panel, show_bot_popup } = action.payload;

      localStorage.setItem('datetime', datetime);

      return {
        ...state,
        datetime,
        declarationSigned: localStorage.getItem('declarationSigned') === 'true',
        showBotPanel: show_bot_panel ?? false,
        showBotPopup: show_bot_popup ?? false,
        settingsLoaded: true,
      };
    }

    case TYPES.GET_SETTINGS_FAILURE:
      return { ...state, settingsLoaded: true };

    case TYPES.CHANGE_CITY: {
      // TODO: Use real city center position
      const city = {
        ...action.payload,
        position: {
          lat: +action.payload.lat,
          lng: +action.payload.lon,
        },
      };

      localStorage.setItem('cityId', city.id);
      localStorage.removeItem('districtId');

      return {
        ...state,
        city,
        districts: initialState.districts,
        districtsLoaded: initialState.districtsLoaded,
        district: initialState.district,
      };
    }

    case TYPES.UPDATE_DISTRICT: {
      if (action.payload) {
        localStorage.setItem('districtId', action.payload.id);
      } else {
        localStorage.removeItem('districtId');
      }

      return { ...state, district: action.payload };
    }

    case TYPES.LOGIN_SUCCESS: {
      const { token, expires_in } = action.payload;
      const { name, declarationSigned } = parseJwt(token);

      localStorage.setItem('token', token);
      localStorage.setItem('expiresAt', expires_in * 1000 + Date.now());
      localStorage.setItem('declarationSigned', declarationSigned);
      localStorage.setItem('username', name);

      return { ...state, token, name, declarationSigned };
    }

    case TYPES.LOGOUT: {
      localStorage.removeItem('token');
      localStorage.removeItem('expiresAt');
      localStorage.removeItem('username');
      localStorage.removeItem('declarationSigned');
      localStorage.removeItem('patientId');

      return { ...state, token: '', name: '', declarationSigned: false };
    }

    case TYPES.REFRESH_TOKEN_REQUEST: {
      return { ...state, tokenRefreshing: true };
    }

    case TYPES.REFRESH_TOKEN_SUCCESS: {
      const { token, expires_in } = action.payload;

      localStorage.setItem('token', token);
      localStorage.setItem('expiresAt', expires_in * 1000 + Date.now());

      return { ...state, token, tokenRefreshing: false };
    }

    case TYPES.REFRESH_TOKEN_FAILURE:
      return { ...state, tokenRefreshing: false };

    case TYPES.GET_CITIES_REQUEST: {
      return { ...state };
    }

    case TYPES.GET_CITIES_SUCCESS: {
      const cityId = localStorage.getItem('cityId');
      const currentCity = action.payload.data.find((city) => city.id === +cityId);

      if (!currentCity) localStorage.removeItem('cityId');

      return {
        ...state,
        citiesLoaded: true,
        cities: action.payload.data,
        city: currentCity
          ? {
              ...currentCity,
              position: {
                lat: +currentCity.lat,
                lng: +currentCity.lon,
              },
            }
          : null,
      };
    }

    case TYPES.GET_CITIES_FAILURE: {
      return { ...state, citiesLoaded: true, error: action.payload };
    }

    case TYPES.GET_DISTRICTS_REQUEST: {
      return { ...state };
    }

    case TYPES.GET_DISTRICTS_SUCCESS: {
      const districtId = localStorage.getItem('districtId');
      const currentDistrict = action.payload.data.find((district) => district.id === +districtId);

      if (!currentDistrict) localStorage.removeItem('districtId');

      return {
        ...state,
        districtsLoaded: true,
        districts: action.payload.data,
        district: currentDistrict || null,
      };
    }

    case TYPES.GET_DISTRICTS_FAILURE: {
      return { ...state, citiesLoaded: true, error: action.payload };
    }

    case TYPES.SYNC_BOT_REQUEST:
      return state;

    case TYPES.SYNC_BOT_SUCCESS: {
      return {
        ...state,
        showBotPanel: action.payload.show_bot_panel ?? false,
        showBotPopup: action.payload.show_bot_popup ?? false,
      };
    }

    case TYPES.SYNC_BOT_FAILURE:
      return { ...state };

    case TYPES.GET_INITIAL_CITY_REQUEST:
      return { ...state };

    case TYPES.GET_INITIAL_CITY_SUCCESS:
      return { ...state, initialCity: action.payload?.data, initialCityLoaded: true };

    case TYPES.GET_INITIAL_CITY_FAILURE:
      return { ...state, initialCityLoaded: true, error: action.payload };

    default:
      return state;
  }
};
