import React from 'react';

import Header from '../components/global/header/index';
import Footer from '../components/global/footer/index';

export default function NotFoundPage() {
  return (
    <div>
      <Header />

      <main>
        <div className="not-found-page-wrapper">
          <div className="not-found-page-content">
            <p>Не знайдено відділення з таким ідентифікатором</p>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
