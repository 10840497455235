import React from 'react';

export default function TextLoader() {
  return (
    <>
      <div className="cabinet-nothing-found">
        <span>Зачекайте, будь ласка, йде завантаження...</span>
      </div>
    </>
  );
}
