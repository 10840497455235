import {
  botImg1,
  botImg2,
  botImg3,
  botImg4,
  botImg5,
  botImg6,
  botImg7,
  botImg8,
} from '../../../assets/img/next/bot-images/index';

const BotListOne = [
  {
    id: 1,
    icon: botImg1,
    description: 'Перегляд записів на прийом',
  },
  {
    id: 2,
    icon: botImg2,
    description: 'Скасування запису на прийом',
  },
  {
    id: 3,
    icon: botImg3,
    description: 'Повідомлення за 2 години до прийому',
  },
  {
    id: 4,
    icon: botImg4,
    description: 'Повідомлення про скасування прийому',
  },
];

const BotListSecond = [
  {
    id: 1,
    icon: botImg5,
    description: 'Інформація про щеплення',
  },
  {
    id: 2,
    icon: botImg6,
    description: 'Свої червоні показники',
  },
  {
    id: 3,
    icon: botImg7,
    description: 'Інформація про сімейного лікаря',
  },
  {
    id: 4,
    icon: botImg8,
    description: 'Виписані рецепти',
  },
];

export { BotListOne, BotListSecond };
