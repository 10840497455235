import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';
import { serverErrorInterceptor } from './middlewares/serverErrorInterceptor';
import { refreshTokenInterceptor } from './middlewares/refreshTokenInterceptor';
import { devToolsConfig } from './constants/config';

const middleware = [thunk, serverErrorInterceptor, refreshTokenInterceptor];
const local = process.env.REACT_APP_ENV === 'local';

const store = createStore(
  rootReducer,
  local ? composeWithDevTools(devToolsConfig)(applyMiddleware(...middleware)) : applyMiddleware(...middleware),
);

export default store;
