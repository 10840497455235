import { Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import React from 'react';

export default function VaccinationLink({ path, disabledIcon, icon, linkLabel, error }) {
  const handleClick = (e) => {
    if (!error) return;

    e.preventDefault();

    if (error.callback) error.callback();
  };

  return (
    <Col md={6} key={path}>
      <NavLink onClick={handleClick} to={path} disabled={Boolean(error)}>
        <div className={`user-cabinet-navigation__item ${error ? 'disabled' : ''}`}>
          <div className="user-cabinet-navigation__item-description">
            <div className="user-cabinet-navigation__item-description-icon">
              <img src={error?.disableButton ? disabledIcon : icon} alt={linkLabel} />
            </div>
          </div>
          <div className="user-cabinet-navigation__item-description-content">
            {linkLabel}
            {error && (
              <div className="error">
                <i className="fas fa-info-circle" />
                {error.errorText}
              </div>
            )}
          </div>
        </div>
      </NavLink>
    </Col>
  );
}
