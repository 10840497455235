/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { datesGenerator } from 'dates-generator';
import Dots from './dots';
import moment from 'moment';

import { days, daysOfWeek, months } from '../../../../../constants';
import Dates from './dates';

const StyledCalendar = styled.div`
  top: 55px;
  left: 0;
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 280px;
  z-index: 2;
  @media (min-width: 768px) {
    left: auto;
    right: 0;
    width: 410px;
  }
  ${({ open }) =>
    open &&
    `
    display: block;
  `}
`;

const StyledButton = styled.button`
  &::after {
    @media (min-width: 768px) {
      content: '${(props) => (props.showText ? props.date : '')}';
    }
  }
`;

export default function Calendar({ showText, setCalendarDate, calendarDate, isMobile = false }) {
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState([]);
  const calendarRef = useRef();

  const [calendar, setCalendar] = useState({
    month: new Date(calendarDate).getMonth(),
    year: new Date(calendarDate).getFullYear(),
    startingDay: 1,
  });

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const body = {
      month: calendar.month,
      year: calendar.year,
      startingDay: 1,
    };

    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([...dates]);
    setCalendar({
      ...calendar,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  }, []);

  const onClickNext = () => {
    const body = { month: calendar.nextMonth, year: calendar.nextYear, startingDay: 1 };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([...dates]);
    setCalendar({
      ...calendar,
      month: calendar.nextMonth,
      year: calendar.nextYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  };

  const onClickPrevious = () => {
    const body = { month: calendar.previousMonth, year: calendar.previousYear, startingDay: 1 };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([...dates]);
    setCalendar({
      ...calendar,
      month: calendar.previousMonth,
      year: calendar.previousYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  };

  const toggleCalendar = () => setOpen((prevState) => !prevState);

  const calendarMonth = moment(
    `${calendar.year}-${calendar.month + 1 > 9 ? calendar.month + 1 : `0${calendar.month + 1}`}`,
  );
  const month = months[calendarMonth.format('MMMM')].full;
  const currentDay = moment(calendarDate);

  return (
    <div style={{ position: 'relative' }} ref={calendarRef}>
      <StyledButton
        type="button"
        className="calendar"
        onClick={toggleCalendar}
        showText={showText}
        style={{ backgroundColor: open ? '#1A7EF7' : '#e3f6ff', height: isMobile ? '50px' : null }}
      >
        <i className="far fa-calendar-alt" style={{ color: open ? '#ffffff' : '#094FA3' }} />
        <div className="calendar-info">
          {!isMobile && (
            <div className="calendar-info-day" style={{ color: open ? '#ffffff' : '#5B5B5B' }}>
              Дата візиту:
            </div>
          )}
          <div className="calendar-info-date" style={{ color: open ? '#ffffff' : '#094FA3' }}>
            {currentDay.date()} {months[currentDay.format('MMMM')].date_ru}
          </div>
        </div>
        <Dots props={{ fill: open ? '#ffffff' : '#094FA3' }} />
      </StyledButton>
      <StyledCalendar open={open}>
        <div className="calendar-mobile-wrapper">
          <div className="calendar-mobile-title">
            <div className="calendar-mobile-title-nav calendar-mobile-title-prev" onClick={onClickPrevious} />
            {month}, {calendar.year}
            <div className="calendar-mobile-title-nav calendar-mobile-title-next" onClick={onClickNext} />
          </div>
          <div className="calendar-mobile">
            <div className="calendar-mobile-head">
              {daysOfWeek.map((day) => (
                <div key={day.ua} className="calendar-mobile-head-item">
                  {day.ua}
                </div>
              ))}
            </div>
            <Dates dates={dates} onCloseCalendar={toggleCalendar} handleDateClick={setCalendarDate} />
          </div>
        </div>
      </StyledCalendar>
    </div>
  );
}
