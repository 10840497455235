import React from 'react';
import { NavLink } from 'react-router-dom';

import { DNIPRO_CITY_ID } from '../../../../constants';

export default function VaccinationButton({ user }) {
  if (!user?.city || user.city.id !== DNIPRO_CITY_ID) return null;

  return (
    <>
      <NavLink to="/vaccinations" className="btn btn--vaccination">
        <i className="fas fa-syringe" />
        Вакцинація COVID-19
      </NavLink>
    </>
  );
}
