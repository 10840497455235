import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import PatientInfo from '../PatientInfo';

export default function HeadRow({ patient, patients, setPatient, user, headerLabel, hideSelect }) {
  return (
    <Container className="user-cabinet-navigation">
      <Row className="user-cabinet-header-row">
        <Col md={5}>
          <div className="user-cabinet-navigation-title">{headerLabel}</div>
        </Col>

        {!hideSelect && (
          <PatientInfo
            wrapperStyle={{ md: { span: 5, offset: 2 }, lg: { span: 4, offset: 3 } }}
            patient={patient}
            patients={patients}
            setPatient={setPatient}
            user={user}
          />
        )}
      </Row>
    </Container>
  );
}
