import React from 'react';

import NextPage from './nextPage';
import PreviousPage from './previousPage';

import { usePaginationApi } from './api';

export default function Pagination({ currentPage = 1, totalPages = 1, setPageHandler, pageName }) {
  const { renderButtons, urlParser } = usePaginationApi({ currentPage, totalPages, setPageHandler, pageName });

  if (totalPages <= 1) return <div className="pagination" />;

  return (
    <ul className="pagination">
      <PreviousPage disabled={currentPage === 1} pageName={pageName} currentPage={currentPage} urlParser={urlParser} setPageHandler={setPageHandler} />
      {renderButtons()}
      <NextPage
        disabled={currentPage === totalPages}
        pageName={pageName}
        currentPage={currentPage}
        urlParser={urlParser}
        setPageHandler={setPageHandler}
      />
    </ul>
  );
}
