import React from 'react';
import { connect } from 'react-redux';

import Layout from './components/layout';
import Header from '../components/global/header/index';
import Footer from '../components/global/footer/index';

const HomePageComponent = ({ user, search }) => {
  return (
    <>
      <Header searchFocused={search.focused} user={user} />

      <Layout user={user} searchFocused={search.focused} />

      <Footer />
    </>
  );
};

const mapStateToProps = ({ user, search }) => ({ user, search });

const HomePageContainer = connect(mapStateToProps, null)(HomePageComponent);

export default HomePageContainer;
