import * as TYPES from '../../constants/types';

const initialState = {
  data: null,
  qrCode: null,
  barCode: null,
  qrCodePatientId: null,
  barCodePatientId: null,
  loading: false,
  loaded: false,
  barCodeLoading: false,
  qrCodeLoading: false,
  barCodeLoaded: false,
  qrCodeLoaded: false,
  fetching: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_PATIENT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_PATIENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case TYPES.GET_PATIENT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
      };

    case TYPES.GET_PATIENT_BAR_CODE_REQUEST:
      return {
        ...state,
        barCode: null,
        barCodeLoading: true,
        barCodeLoaded: false,
      };

    case TYPES.GET_PATIENT_BAR_CODE_SUCCESS:
      return {
        ...state,
        barCodeLoading: false,
        barCodeLoaded: true,
        barCode: action.payload.response,
        barCodePatientId: action.payload.patientId,
      };

    case TYPES.GET_PATIENT_BAR_CODE_FAILURE:
      return {
        ...state,
        barCodeLoading: false,
        barCodeLoaded: true,
        barCodePatientId: action.payload.patientId,
      };

    case TYPES.GET_PATIENT_QR_CODE_REQUEST:
      return {
        ...state,
        qrCode: null,
        qrCodeLoading: true,
        qrCodeLoaded: false,
      };

    case TYPES.GET_PATIENT_QR_CODE_SUCCESS:
      return {
        ...state,
        qrCodeLoading: false,
        qrCodeLoaded: true,
        qrCode: action.payload.response,
        qrCodePatientId: action.payload.patientId,
      };

    case TYPES.GET_PATIENT_QR_CODE_FAILURE:
      return {
        ...state,
        qrCodeLoading: false,
        qrCodeLoaded: true,
        qrCodePatientId: action.payload.patientId,
      };

    case TYPES.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
