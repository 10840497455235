import React from 'react';
import { connect } from 'react-redux';

import DoctorPageComponent from '../next/doctorPage';
import {
  getDoctor,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  doctorScheduleDateRangeResize,
  setCalendarDate,
} from '../actions/doctors';
import { updateCity } from '../actions';

import { fetchPatients } from '../actions/patientsActions';

import useDoctorScheduleDateRangeResize from '../hooks/useDoctorScheduleDateRangeResize';

const DoctorPageContainer = ({ doctorScheduleDateRangeResize, doctorSchedule, ...props }) => {
  useDoctorScheduleDateRangeResize({ doctorScheduleDateRangeResize, datesRange: doctorSchedule.datesRange });

  return <DoctorPageComponent doctorSchedule={doctorSchedule} {...props} />;
};

const mapDispatchToProps = {
  getDoctor,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  getPatients: fetchPatients,
  doctorScheduleDateRangeResize,
  setCalendarDate,
  updateCity,
};

const mapStateToProps = ({ doctor, doctors, doctorSchedule, doctorsByDaysSchedule, user }) => ({
  doctor,
  doctors,
  doctorSchedule,
  doctorsByDaysSchedule,
  user,
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorPageContainer);
