import React from 'react';
import { NavLink } from 'react-router-dom';
import { aboutProject } from './helpers';

const FooterNav = () => {
  return (
    <div className="footer-navigation">
      <ul className="about-project-list">
        {aboutProject.map(({ icon, link, text }) => (
          <li to={link} className="about-project-list__item" key={link}>
            <i className={`fas ${icon}`} />
            <NavLink to={link} className="about-project-list__link">
              {text}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="contacts-wrapper">
        <ul className="phone-list">
          <i className="fas fa-book" />
          <li className="phone-list__item">
            <a
              href="https://drive.google.com/file/d/1CUVIq3kbDQ8hy3CgVQewsAE2SHwTPevd/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="phone-list__item-link"
            >
              Інструкція користувача
            </a>
          </li>
        </ul>
        <div className="email">
          <i className="fas fa-envelope" />
          <a href="mailto:info@ciet-holding.com" className="email__link">
            info@ciet-holding.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterNav;
