import React, { Fragment } from 'react';
import moment from 'moment';
import Contacts from '../../common/contacts';

import TextLoader from '../../../components/global/Loader/text';

const sexObject = {
  female: 'Жiноча',
  male: 'Чоловiча',
};

const PersonalData = ({ patient }) => {
  if (!patient.data) return <TextLoader />;

  const { sex, addresses, contacts, birthday, full_name, identity_code, idCards } = patient.data;

  return (
    <div className="personal-data-wrapper">
      <div className="personal-data">
        <div className="personal-data-title">Моя iнформацiя</div>
        <ul className="personal-data-list">
          {full_name && (
            <li className="personal-data-list__item">
              <div className="personal-data-list__item-feature">ПIБ</div>
              <div className="personal-data-list__item-description">{full_name}</div>
            </li>
          )}
          {birthday && (
            <li className="personal-data-list__item">
              <div className="personal-data-list__item-feature">Дата народження</div>
              <div className="personal-data-list__item-description">{moment(birthday).format('DD.MM.YYYY')}</div>
            </li>
          )}
          {identity_code && (
            <li className="personal-data-list__item">
              <div className="personal-data-list__item-feature">IПН</div>
              <div className="personal-data-list__item-description">{identity_code}</div>
            </li>
          )}

          {idCards && Boolean(idCards.length) && (
            <li className="personal-data-list__item">
              <div className="personal-data-list__item-feature">ID картка</div>
              <div className="personal-data-list__item-description">
                {idCards.map(({ code, expired_date, issued_by, issued_date, number, series }) => (
                  <Fragment key={`${code}-${series}-${number}`}>
                    <div className="id-card-series">
                      {series} {number}
                    </div>
                    <span>Дата видачі: {moment(issued_date).format('YYYY-MM-DD')}</span>
                    <span>Термін: {expired_date ? moment(expired_date).format('YYYY-MM-DD') : '-'} </span>
                    <span>Виданий: {issued_by}</span>
                  </Fragment>
                ))}
              </div>
            </li>
          )}
          {sex && (
            <li className="personal-data-list__item">
              <div className="personal-data-list__item-feature">Стать</div>
              <div className="personal-data-list__item-description">{sexObject[sex]}</div>
            </li>
          )}
        </ul>
      </div>

      <div className="personal-data">
        <div className="personal-data-title">Контактнi данi</div>
        <ul className="personal-data-list">
          {addresses?.length &&
            addresses.map((address) => (
              <li className="personal-data-list__item" key={address.display_text}>
                <div className="personal-data-list__item-feature">Адреса</div>
                <div className="personal-data-list__item-description">{address.display_text}</div>
              </li>
            ))}

          <Contacts contacts={contacts} className="personal-data-list__item" />
        </ul>
      </div>
    </div>
  );
};

export default PersonalData;
