import React from 'react';

export default ({ jobs, activeKey, setKey, isPagesAvailable }) => {
  const handleClickLeft = () => {
    if (activeKey > 0) setKey(activeKey - 1);
  };

  const handleClickRight = () => {
    if (activeKey < jobs.length - 1) setKey(activeKey + 1);
  };

  if (!isPagesAvailable) return null;

  return (
    <div className="medic-feature-profile-information__jobs-pagination">
      <div className="medic-feature-profile-information__jobs-pagination-button" onClick={handleClickLeft}>
        <i className="fas fa-chevron-left" />
      </div>
      <div className="medic-feature-profile-information__jobs-pagination-count">
        <span className="medic-feature-profile-information__jobs-pagination-current-page">{activeKey + 1}/</span>
        <span className="medic-feature-profile-information__jobs-pagination-total-pages">{jobs.length}</span>
      </div>
      <div className="medic-feature-profile-information__jobs-pagination-button" onClick={handleClickRight}>
        <i className="fas fa-chevron-right" />
      </div>
    </div>
  );
};
