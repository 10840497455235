import React from 'react';

import Day from './day';

export default function Week({ weekDays, now, showSubModal, setDayData, city }) {
  return (
    <div className="calendar-row">
      {Object.keys(weekDays).map((date) => (
        <Day
          key={date}
          now={now}
          city={city}
          date={date}
          dayData={weekDays[date]}
          setDayData={setDayData}
          showSubModal={showSubModal}
        />
      ))}
    </div>
  );
}
