import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import * as api from '../../../../api';
import { logout } from '../../../../actions';
import Auth from '../../../../containers/Auth';

const HeaderButton = ({ logout, user }) => {
  const [authIsOpen, setAuthIsOpen] = useState(false);
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (!user.token) {
      setAuthIsOpen(true);
    } else {
      history.push('/office');
    }
  }, [history, user.token]);

  const onLogout = useCallback(() => {
    api.logout().then(() => {
      history.push('/');
      logout();
    });
  }, [history, logout]);

  return (
    <>
      {authIsOpen && <Auth isOpen onClose={() => setAuthIsOpen(false)} />}

      <div className={`btn login-btn${user.token ? ' logged-in' : ''}`}>
        <div className="login-btn__description" onClick={handleClick}>
          <i className="fas fa-id-card-alt" />
          {user.token ? 'Мiй' : 'Увiйти в'} кабiнет
        </div>
        {user.token && (
          <>
            <hr className="divider" />
            <div onClick={onLogout} className="logout">
              <i className="fas fa-sign-out-alt" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(HeaderButton);
