import React, { useEffect } from 'react';

import ErrorBoundary from './ErrorBoundary';
import Routes from './routes';

import GlobalAlert from './next/components/global/alert';
import GlobalLoader from './next/components/global/Loader';

export default function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENV.toLowerCase() !== 'production') {
      document.head.getElementsByTagName('title')[0].innerHTML += ' ** ДЕМО РЕЖИМ ** ';
    }
  }, []);

  return (
    <ErrorBoundary>
      <Routes />

      <GlobalAlert />
      <GlobalLoader />
    </ErrorBoundary>
  );
}
