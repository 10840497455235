import React from 'react';
import { Row } from 'react-bootstrap';

import HospitalDivisionHeader from './common/header';
import Indicators from './common/indicators';
import DivisionMedics from '../DivisionMedics';

export default function HospitalDivision({ divisions, currentDivision, setCurrentDivision, city }) {
  return (
    <div className="institution-info">
      <HospitalDivisionHeader
        currentDivision={currentDivision}
        divisions={divisions}
        setCurrentDivision={setCurrentDivision}
      />

      <Row>
        <Indicators
          description={currentDivision?.description}
          address={currentDivision?.address}
          indicators={currentDivision?.indicators}
          phones={
            currentDivision?.contacts ? currentDivision.contacts.filter((contact) => contact.type === 'phone') : []
          }
        />
        <DivisionMedics divisionId={currentDivision?.id} city={city} />
      </Row>
    </div>
  );
}
