import React, { useMemo } from 'react';

import { getOnlyFreeDoctorSchedule } from '../../../../../../../utils/getOnlyFreeDoctorSchedule';
import ScheduleHeader from '../../../../../../components/global/List/ScheduleHeader';

import EmptySchedule from './empty';
import VerticalLineSchedule from './verticalLine';

import { ReactComponent as ScheduleInfoSvg } from '../../../../../../../assets/img/schedule-info.svg';

const HORIZONTAL_LINES_TO_SHOW = 3;

export default function DesktopSchedule({
  datesRange,
  currentDoctorSchedule = {},
  loaded,
  onOpenAppointment,
  showScheduleHeader,
  isDirect,
}) {
  const horizontalLines = Array.from({ length: HORIZONTAL_LINES_TO_SHOW }, (_, index) => index);

  const freeDoctorSchedule = useMemo(() => getOnlyFreeDoctorSchedule(currentDoctorSchedule.schedule || []), [
    currentDoctorSchedule.schedule,
  ]);

  if (!loaded) return <EmptySchedule placeholder="Завантаження ...." />;

  if (loaded && (!currentDoctorSchedule.schedule || currentDoctorSchedule.schedule.length === 0)) {
    return (
      <EmptySchedule
        placeholder={
          <div className="schedule-body-no-result">
            <div className="schedule-body-no-result-content">
              <i className="fas fa-calendar-times" />
              Найближчим часом прийому немає або все зайнято
            </div>
          </div>
        }
      />
    );
  }

  return (
    <div className="schedule-wrapper">
      <div className="schedule">
        <ScheduleHeader show={showScheduleHeader} />

        <div className="schedule-body">
          <ul className="schedule-list">
            {datesRange.map((date) => (
              <VerticalLineSchedule
                key={date}
                schedule={freeDoctorSchedule[date]}
                horizontalLines={horizontalLines}
                handleClickTimeSlot={onOpenAppointment}
                horizontalLinesToShow={HORIZONTAL_LINES_TO_SHOW}
              />
            ))}
          </ul>
          {isDirect && (
            <div className="schedule-info">
              <div className="schedule-info__icon">
                <ScheduleInfoSvg />
              </div>
              Запис можливий за направленням ЕСОЗ
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
