import * as TYPES from '../../constants/types';

const initialState = {
  data: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_PATIENTS_RECIPES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case TYPES.GET_PATIENTS_RECIPES_SUCCESS: {
      const { data, pagination } = action.payload;

      return {
        ...state,
        loading: false,
        data,
        pagination
      };
    }

    case TYPES.GET_PATIENTS_RECIPES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    }

    case TYPES.LOGOUT:
      return {
        ...state,
        data: null
      };

    default:
      return state;
  }
};