import React from 'react';

const Description = ({ description }) => {
  if (!description) return null;

  return (
    <li className="medic-feature-profile-detail-list__item">
      <div className="medic-feature-profile-detail__title">Інформація про лікаря</div>
      <div className="medic-feature-profile-detail__description">
        <p>{description}</p>
      </div>
    </li>
  );
};

export default Description;
