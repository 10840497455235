import React from 'react';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';

import RatingBar from './RatingBar';

export default function IndicatorsModal({ isVisible, onClose, rating, full_name, indicators }) {
  return (
    <Modal show={Boolean(isVisible && indicators.length)} onHide={onClose} className="modal-indicator-list" centered>
      <Modal.Header closeButton>
        <Modal.Title>Показники</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="full_name">
          <span>{full_name}</span> <RatingBar rating={rating} />
        </div>
        <ul className="indicators">
          {indicators?.length &&
            indicators.map(indicator => (
              <li key={indicator.key}>
                <span>{indicator.name}</span>
                <span>
                  {indicator.value}&nbsp;{indicator.units}&nbsp;
                  <OverlayTrigger overlay={<Tooltip>{indicator.description}</Tooltip>}>
                    <span>
                      <i className="far fa-question-circle" />
                    </span>
                  </OverlayTrigger>
                </span>
              </li>
            ))}
        </ul>
      </Modal.Body>
    </Modal>
  );
}
