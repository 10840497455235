import React, { Fragment } from 'react';

export default function Specializations({ specializations, className }) {
  return (
    <div className={className ?? 'medic-feature-profile-information__position'}>
      {specializations.map(({ id, name }, index) => (
        <Fragment key={id}>
          {name}
          {specializations.length > index + 1 ? ', ' : ''}
        </Fragment>
      ))}
    </div>
  );
}
