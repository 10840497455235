import React from 'react';
import { Col } from 'react-bootstrap';

import IndicatorList from './indicatorList';
import Description from './description';
import Contacts from './contacts';

export default function Indicators({ indicators, description, phones, address }) {
  return (
    <Col xs={{ span: 12 }} lg={{ span: 6 }}>
      <div className="institution-info__indicator">
        <Contacts address={address} phones={phones} />
        <IndicatorList indicators={indicators ?? []} />
        <Description description={description} />
      </div>
    </Col>
  );
}
