import React from 'react';

import RatingList from './ratingList';

export default function RatingBar({ rating, full_name, indicators, showRatingPopup = true }) {
  if (!rating) return null;

  return (
    <div className="rating-wrapper">
      <RatingList rating={rating} indicators={indicators} full_name={full_name} showRatingPopup={showRatingPopup} />
    </div>
  );
}
