import { LOCATION_CHANGE } from 'react-router-redux';
import * as TYPES from '../../constants/types';

import { GOOGLE_MARKER } from '../../constants/config';

const initialState = {
  data: [],
  activeMarker: null,
  loading: false,
  loaded: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_DIVISIONS_MAP_DATA_REQUEST:
    case TYPES.GET_DIVISION_MAP_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case TYPES.GET_DIVISIONS_MAP_DATA_SUCCESS: {
      const markers = {};

      action.payload.response.data.forEach((division) => {
        const markerType = division.is_small_vaccination_center
          ? GOOGLE_MARKER.vaccinationPlace
          : GOOGLE_MARKER.division;

        if (!division.address.lat || !division.address.lon) return;

        const lanLngKey = `${division.address.lat}-${division.address.lon}`;

        const divisionMarkerData = {
          id: division.id,
          title: division.full_name,
          title_link: `/hospitals/${division.institution.id}?division_id=${division.id}`,
          vaccination_title_link: `/vaccinations/ambulatory?hospital_id=${division.institution.id}&division_id=${division.id}`,
          content: division.address.display_text,
          rating: division.rating,
          institutionId: division.institution.id,
        };

        if (markers[lanLngKey]) {
          markers[lanLngKey].icon = markerType.many.icon;
          markers[lanLngKey].iconsList = {
            icon: markerType.many.icon,
            activeIcon: markerType.many.active.icon,
          };
          markers[lanLngKey].data = [...markers[lanLngKey].data, divisionMarkerData];
          markers[lanLngKey].label.text = `${markers[lanLngKey].data.length}`;

          return;
        }

        markers[lanLngKey] = {
          id: lanLngKey,
          icon: markerType.one.icon,
          iconsList: {
            icon: markerType.one.icon,
            activeIcon: markerType.one.active.icon,
          },
          position: {
            lat: +division.address.lat,
            lng: +division.address.lon,
          },
          label: {
            text: '1',
            ...GOOGLE_MARKER.label,
          },
          labelsColor: {
            color: GOOGLE_MARKER.label.color,
            activeColor: GOOGLE_MARKER.activeLabel.color,
          },
          data: [divisionMarkerData],
        };
      });

      return {
        ...state,
        data: Object.values(markers),
        activeMarker: null,
        loading: false,
        loaded: true,
      };
    }

    case TYPES.GET_DIVISION_MAP_DATA_SUCCESS: {
      const division = action.payload.response.data;

      if (!division.address.lat || !division.address.lon)
        return {
          ...state,
          data: [],
          activeMarker: null,
          loading: false,
          loaded: true,
        };

      const divisionMarkerData = {
        id: division.id,
        title: division.full_name,
        title_link: `/hospitals/${division.institution.id}?division_id=${division.id}`,
        vaccination_title_link: `/vaccinations/ambulatory?hospital_id=${division.institution.id}&division_id=${division.id}`,
        content: division.address.display_text,
        rating: division.rating,
        institutionId: division.institution.id,
      };
      const marker = {
        id: `${division.address.lat}-${division.address.lon}`,
        icon: GOOGLE_MARKER.one.active.icon,
        position: {
          lat: +division.address.lat,
          lng: +division.address.lon,
        },
        label: null,
        data: [divisionMarkerData],
      };

      return {
        ...state,
        data: [marker],
        activeMarker: marker,
        loading: false,
        loaded: true,
      };
    }

    case TYPES.GET_DIVISIONS_MAP_DATA_FAILURE:
    case TYPES.GET_DIVISION_MAP_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
      };

    case LOCATION_CHANGE:
      return initialState;

    default:
      return state;
  }
}
