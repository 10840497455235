import { useEffect, useState } from 'react';

export function useCityChangeApi({ user, updateCity, history, disableRedirect }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentCity, setCurrentCity] = useState();

  useEffect(() => {
    if (user.initialCityLoaded && user.citiesLoaded) setIsOpen(true);
  }, [user.citiesLoaded, user.initialCityLoaded]);

  useEffect(() => {
    if (user.initialCityLoaded && user.initialCity) setCurrentCity(user.initialCity);
  }, [user.initialCity, user.initialCityLoaded]);

  const onSubmit = () => {
    if (currentCity) {
      updateCity(currentCity);
      setIsOpen(false);

      if (!disableRedirect) history.push('/');
    }
  };

  return {
    isOpen,
    onSubmit,
    currentCity,
    setCurrentCity,
  };
}
