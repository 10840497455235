import { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';

import { useUrlParser } from '../../../../utils/useUrlParser';

const groupDivisionsData = (array) =>
  array.reduce((acc, item) => {
    const currentKey = item?.closest_available_time ? moment(item.closest_available_time).format('YYYY-MM-DD') : null;

    (acc[currentKey] = acc[currentKey] || []).push(item);
    return acc;
  }, {});

export function useDivisionPageListApi({ divisions, getDivisions, city, district }) {
  const urlParser = useUrlParser();

  const fetchDivisions = useCallback(() => {
    const divisionsPage = +urlParser.urlData.params.divisions_page;
    const page = divisionsPage > 0 && Number.isInteger(divisionsPage) ? divisionsPage : 1;

    getDivisions({
      cityId: city.id,
      isVaccinationPlace: true,
      districtId: district?.id,
      page,
    });
  }, [city.id, district, getDivisions, urlParser.urlData.params.divisions_page]);

  useEffect(() => {
    if (city?.id && !divisions.loading && !divisions.loaded) fetchDivisions();
  }, [city, divisions.loaded, divisions.loading, fetchDivisions, getDivisions]);

  useEffect(() => {
    if (divisions.loaded) window.scrollTo(0, 0);
  }, [divisions.currentPage, divisions.loaded]);

  const divisionsData = useMemo(() => groupDivisionsData(divisions.data), [divisions.data]);

  return { divisionsData };
}
