import { useEffect, useCallback, useState } from 'react';

import BarCodeSmallSvg from '../../../../assets/img/next/code/bar-code-small.svg';
import QrCodeSmallSvg from '../../../../assets/img/next/code/qr-code-small.svg';

const codeTypes = {
  barCode: 'bar-code',
  qrCode: 'qr-code',
};

export default function usePersonalCodeModalApi({ isVisible, patient, getBarCode, getQrCode, handleClose }) {
  const [patientPhone, setPatientPhone] = useState('');
  const [codeType, setCodeType] = useState(codeTypes.qrCode);

  useEffect(() => {
    const getCode = () => {
      if (!isVisible || !patient.data?.id) return;

      if (codeType === codeTypes.barCode && patient.barCodePatientId !== patient.data.id) getBarCode(patient.data.id);

      if (codeType === codeTypes.qrCode && patient.qrCodePatientId !== patient.data.id) getQrCode(patient.data.id);
    };

    getCode();
  }, [isVisible, patient.data, patient.qrCodePatientId, patient.barCodePatientId, codeType, getBarCode, getQrCode]);

  useEffect(() => {
    if (isVisible && patient.data?.contacts) {
      const patientPhoneData = patient.data.contacts?.find((contact) => contact.type === 'phone');

      if (patientPhoneData) {
        setPatientPhone(patientPhoneData.value);
      }
    }
  }, [isVisible, patient.data]);

  const getCodeSvg = useCallback(() => {
    if (codeType === codeTypes.barCode) return QrCodeSmallSvg;

    return BarCodeSmallSvg;
  }, [codeType]);

  const onChangeCode = useCallback(() => {
    if (codeType === codeTypes.barCode) {
      setCodeType(codeTypes.qrCode);
      return;
    }

    setCodeType(codeTypes.barCode);
  }, [codeType]);

  const getHtmlCode = useCallback(() => {
    const loading = 'Завантаження ...';
    const error = 'Помилка завантаження';

    if (codeType === codeTypes.barCode && (patient.barCodeLoading || patient.barCodePatientId !== patient.data?.id))
      return loading;

    if (codeType === codeTypes.qrCode && (patient.qrCodeLoading || patient.qrCodePatientId !== patient.data?.id))
      return loading;

    if (codeType === codeTypes.barCode) {
      return patient.barCode ?? error;
    }

    return patient.qrCode ?? error;
  }, [
    codeType,
    patient.barCode,
    patient.barCodeLoading,
    patient.barCodePatientId,
    patient.data,
    patient.qrCode,
    patient.qrCodeLoading,
    patient.qrCodePatientId,
  ]);

  const getButtonText = useCallback(() => {
    const codeText = codeType === codeTypes.qrCode ? 'штрих код' : 'QR-код';

    return `Показати ${codeText}`;
  }, [codeType]);

  const onCloseModal = () => {
    setCodeType(codeTypes.qrCode);
    handleClose();
  };

  return {
    patientPhone,
    getCodeSvg,
    onChangeCode,
    getHtmlCode,
    onCloseModal,
    getButtonText,
  };
}
