import React from 'react';
import { Modal, Col, Row, Container } from 'react-bootstrap';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';

const ModalConfirmation = ({ onClose, onConfirm, isOpen, id }) => {
  const handleSubmit = () => {
    onConfirm(id);
  };

  return (
    <ButtonToolbar>
      <Modal show={isOpen} onHide={onClose} aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Скасувати запис</Modal.Title>
        </Modal.Header>
        <Modal.Body>Ви дійсно бажаєте відмінити цей запис на прийом?</Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col xs="6">
                <Button onClick={onClose} variant="secondary">
                  Закрити
                </Button>
              </Col>
              <Col xs="6">
                <Button onClick={handleSubmit} variant="primary">
                  Пiдтвердити
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </ButtonToolbar>
  );
};

export default ModalConfirmation;
