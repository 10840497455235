import React, { useEffect, useState } from 'react';

import Code from './code';
import Phone from './phone';

import { Modal } from '../components/global/modal';

const Auth = ({ isOpen, onClose, auth: { phone }, denyRedirect = false, connectToBotFn }) => {
  const [RenderComponent, setRenderComponent] = useState(Phone);

  useEffect(() => {
    if (phone) {
      setRenderComponent(Code);
    }
  }, [phone]);

  return (
    <Modal isOpen={isOpen}>
      <div className="modal-dialog auth" role="document">
        <div className="modal-content">
          <p className="modal_title">Авторизація</p>
          <button onClick={onClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

          <RenderComponent onClose={onClose} denyRedirect={denyRedirect} connectToBotFn={connectToBotFn} />
        </div>
      </div>
    </Modal>
  );
};

export default Auth;
