import { LOCATION_CHANGE } from 'react-router-redux';
import * as TYPES from '../../constants/types';

const initialState = {
  data: [],
  loading: false,
  loaded: false,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_DOCTORS_BY_DAYS_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case TYPES.GET_DOCTORS_BY_DAYS_SCHEDULE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        loaded: true,
      };

    case TYPES.GET_DOCTORS_BY_DAYS_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
      };

    case LOCATION_CHANGE:
      return initialState;

    default:
      return { ...state };
  }
}
