import * as TYPES from '../constants/types';

const initialState = {
  data: {
    doctors: [],
    divisions: [],
    specializations: [],
  },
  dataExists: false,
  loaded: false,
  loading: false,
  error: null,
  focused: false,
  term: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SEARCH_REQUEST: {
      return { ...state, loading: true, error: null };
    }

    case TYPES.SEARCH_SUCCESS: {
      if (action.payload.term === '') return initialState;

      const { doctors, divisions, specializations } = action.payload.data;
      const dataExists = !!(doctors.length || divisions.length || specializations.length);

      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data,
        dataExists,
        term: action.payload.term,
      };
    }

    case TYPES.SEARCH_FAILURE: {
      return { ...state, loading: false, loaded: true, error: action.payload };
    }

    case TYPES.SEARCH_FOCUSED_TOGGLE: {
      return { ...state, focused: action.payload.focused };
    }

    default:
      return state;
  }
};
