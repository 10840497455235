import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import TextLoader from '../../../components/global/Loader/text';
import Doctor from './doctor';

export default function CurrentAppointments({
  currentRef,
  appointments,
  cancelAppointment,
  goToPastAppointments,
  isVisible,
  city,
}) {
  const history = useHistory();

  if (!isVisible) return null;

  if (!appointments.current.data.length && appointments.currentLoading) return <TextLoader />;

  const onClickButton = (e) => {
    e.preventDefault();
    history.push('/');
  };

  return (
    <div className="streaming-records-current" ref={currentRef}>
      <div className="streaming-records-title">
        Майбутні візити
        <div className="streaming-records-title-records-wrapper">
          <div className="streaming-records-title-records-item" onClick={goToPastAppointments}>
            <i className="fas fa-history" />
            До попереднiх записiв
          </div>
        </div>
      </div>

      {appointments.current.data.length ? (
        appointments.current.data.map((props) => (
          <Doctor key={props.id} city={city} cancelAppointment={cancelAppointment} {...props} />
        ))
      ) : (
        <div className="cabinet-no-records">
          <div className="cabinet-no-records-title">Майбутні візити відсутні</div>
          <Button className="cabinet-no-records-btn" onClick={onClickButton}>
            Записатись на прийом
          </Button>
        </div>
      )}
    </div>
  );
}
