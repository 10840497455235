import { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { useUrlParser } from '../../utils/useUrlParser';

export function useMapApi({
  divisionsMap,
  doctorsMap,
  getDoctorsMapData,
  getDoctorMapData,
  getDivisionsMapData,
  getDivisionMapData,
  city,
  tabEventKey,
  tabEventKeys,
  isVaccinationPlace,
  district,
  districtsLoaded,
}) {
  const [mapData, setMapData] = useState([]);
  const [activeMarker, setActiveMarker] = useState();
  const location = useLocation();
  const params = useParams();
  const { urlData } = useUrlParser();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isDivisionsRoute = useMemo(() => {
    return tabEventKey && tabEventKeys && tabEventKey === tabEventKeys.DIVISIONS_MAP;
  }, [tabEventKey, tabEventKeys]);

  const isDivisionRoute = useMemo(() => {
    return params.id && location.pathname === `/divisions/${params.id}/map`;
  }, [location.pathname, params.id]);

  const isDoctorsRoute = useMemo(() => {
    return tabEventKey && tabEventKeys && tabEventKey === tabEventKeys.DOCTORS_MAP;
  }, [tabEventKey, tabEventKeys]);

  const isDoctorRoute = useMemo(() => {
    return params.id && location.pathname === `/doctors/${params.id}/map`;
  }, [location.pathname, params.id]);

  useEffect(() => {
    if ((isDivisionsRoute || isDivisionRoute) && divisionsMap.loaded) {
      setMapData(divisionsMap.data);

      if (divisionsMap.activeMarker) setActiveMarker(divisionsMap.activeMarker);
    }
  }, [divisionsMap, isDivisionRoute, isDivisionsRoute]);

  useEffect(() => {
    if ((isDoctorsRoute || isDoctorRoute) && doctorsMap.loaded) {
      setMapData(doctorsMap.data);

      if (doctorsMap.activeMarker) setActiveMarker(doctorsMap.activeMarker);
    }
  }, [isDoctorsRoute, doctorsMap, isDoctorRoute]);

  useEffect(() => {
    if (isDivisionsRoute && city?.id && (isVaccinationPlace ? districtsLoaded : true))
      getDivisionsMapData({
        cityId: city?.id,
        districtId: district?.id,
        specializationId: urlData.params.specialization_id,
        name: urlData.params.name,
        isVaccinationPlace,
      });
  }, [
    city,
    district,
    districtsLoaded,
    getDivisionsMapData,
    isDivisionsRoute,
    isVaccinationPlace,
    urlData.params.name,
    urlData.params.specialization_id,
  ]);

  useEffect(() => {
    if (isDoctorsRoute)
      getDoctorsMapData({
        cityId: city?.id,
        specializationId: urlData.params.specialization_id,
        name: urlData.params.name,
      });
  }, [city, getDoctorsMapData, isDoctorsRoute, urlData.params.name, urlData.params.specialization_id]);

  useEffect(() => {
    if (isDivisionRoute) getDivisionMapData({ divisionId: params.id });
  }, [getDivisionMapData, isDivisionRoute, params.id]);

  useEffect(() => {
    if (isDoctorRoute) getDoctorMapData({ doctorId: params.id, divisionId: urlData.params.division_id });
  }, [getDoctorMapData, isDoctorRoute, params.id, urlData.params.division_id]);

  return {
    mapData,
    activeMarker,
  };
}
